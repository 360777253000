import { computed, defineComponent, PropType, reactive, ref } from 'vue'
import ArchivePendingToBeArchivedAddOperation
    from '@/app/views/modules/components/archive/components/pending-to-be-archived/add-operation/archive-pending-to-be-archived-add-operation.vue'
import {
    ArchiveModuleSectionType
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'
import { EntitySelectorType } from '@/app/shared/components/selector/entity/entity-selector.model'
import { ArchiveEntityDatasource } from '@/app/views/modules/components/archive/datasources/entities/archive-entity.datasource'
import {
    ArchivePendingToBeArchivedActionsEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-archived/actions/archive-pending-to-be-archived-actions.model'
import {
    ArchivePendingToBeArchivedActionsClass
} from '@/app/views/modules/components/archive/components/pending-to-be-archived/actions/archive-pending-to-be-archived-actions.class'

export default defineComponent({
    name: 'ArchivePendingToBeArchivedActions',
    components: {
        ArchivePendingToBeArchivedAddOperation
    },
    props: {
        sectionType: { type: String as PropType<ArchiveModuleSectionType>, required: true },
        entityType: { type: String as PropType<EntitySelectorType>, required: true },
        datasource: { type: Object as PropType<ArchiveEntityDatasource>, required: true }
    },
    emits: [
        ...Object.values(ArchivePendingToBeArchivedActionsEmitEvent)
    ],
    setup(props, { emit }) {
        const _class = reactive(new ArchivePendingToBeArchivedActionsClass(emit))

        /// DATA
        const visibleModal = ref(false)

        return {
            /// DATA
            visibleModal,
            /// COMPUTED
            title: computed(() => _class.getTitle(props.sectionType, props.entityType)),
            /// METHODS
            showModal: () => { visibleModal.value = true },
            hideModal: () => { visibleModal.value = false },
            takeIn: () => _class.takeIn()
        }
    }
})
