import { EmitFn, store } from '@api/lib'
import { PendingUnarchivingReasonResponse } from '@api/services/archive/archive.dto'
import {
    ArchivePendingToBeUnArchivedFilterEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/filter/archive-pending-to-be-un-archived-filter.model'
import {
    ModulesArchivePendingToBeUnArchivedStoreState
} from '@/app/views/modules/store/modules/archive/modules/pending-to-be-un-archived/modules-archive-pending-to-be-un-archived-store-state.model'
import { CommonFilterModel } from '@/app/shared/models/common.model'
import { StrictNullable } from '@/app/core/types/app-types'
import { ModulesLinkUtil } from '@/app/views/modules/shared/utils/modules-link-util'
import { CombinedFilterModel } from '@/app/shared/models/ag-grid.model'

export class ArchivePendingToBeUnArchivedFilterClass {
    private readonly _emit: EmitFn<ArchivePendingToBeUnArchivedFilterEmitEvent>

    public get pendingUnArchivingReason(): ModulesArchivePendingToBeUnArchivedStoreState['pendingUnArchivingReason'] {
        return store.getters['appModules/archive/pendingToBeUnArchived/pendingUnArchivingReason']
    }
    public get locations(): ModulesArchivePendingToBeUnArchivedStoreState['locations'] {
        return store.getters['appModules/archive/pendingToBeUnArchived/locations']
    }

    private get _pendingUnArchivingReasonFilterModel(): CommonFilterModel {
        return ModulesLinkUtil.getPendingUnArchivingReasonFilterModel(this.pendingUnArchivingReason?.code)
    }
    private get _locationFilterModel(): CommonFilterModel | CombinedFilterModel {
        return ModulesLinkUtil.getLocationFilterModel(this.locations)
    }

    constructor(emit: EmitFn<ArchivePendingToBeUnArchivedFilterEmitEvent>) {
        this._emit = emit
    }

    private _onFiltered(): void {
        const filterModel: CommonFilterModel | CombinedFilterModel = {
            ...this._pendingUnArchivingReasonFilterModel,
            ...this._locationFilterModel,
        }
        this._emit(ArchivePendingToBeUnArchivedFilterEmitEvent.FILTERED, filterModel)
    }

    public async addPendingUnArchivingReasonFilter(reason: StrictNullable<PendingUnarchivingReasonResponse>): Promise<void> {
        await store.dispatch('appModules/archive/pendingToBeUnArchived/changePendingUnArchivingReason', reason)
        this._onFiltered()
    }
    public async clearPendingUnArchivingReasonFilter(): Promise<void> {
        await this.addPendingUnArchivingReasonFilter(null)
    }

    public async addLocationFilter(location: string): Promise<void> {
        const locations = [...this.locations]
        locations.push(location)
        await store.dispatch('appModules/archive/pendingToBeUnArchived/changeLocations', locations)
        this._onFiltered()
    }
    public async removeLocationFilter(location: string): Promise<void> {
        const locations = this.locations.filter(l => l !== location)
        await store.dispatch('appModules/archive/pendingToBeUnArchived/changeLocations', locations)
        this._onFiltered()
    }
    public async clearLocationFilters(): Promise<void> {
        await store.dispatch('appModules/archive/pendingToBeUnArchived/changeLocations', [])
        this._onFiltered()
    }
}
