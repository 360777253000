import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_archive_location_input = _resolveComponent("archive-location-input")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_loading_button = _resolveComponent("loading-button")!
  const _component_archive_add_operation = _resolveComponent("archive-add-operation")!
  const _component_confirmation_summary = _resolveComponent("confirmation-summary")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_archive_add_operation, {
      "entity-type": _ctx.entityType,
      "section-type": _ctx.sectionType,
      title: _ctx.title,
      "list-title": "anapathapp.label.elementsToAdd",
      "show-stepper": _ctx.hasCards,
      onGoBack: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goBack())),
      onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChange($event)))
    }, {
      operations: _withCtx(() => [
        _createVNode(_component_ion_row, { class: "ion-margin" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, null, {
              default: _withCtx(() => [
                (_ctx.askLocation)
                  ? (_openBlock(), _createBlock(_component_archive_location_input, {
                      key: 0,
                      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.locationChanged($event)))
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_loading_button, {
          expand: "block",
          disabled: _ctx.disabledTakeIn,
          promise: _ctx.takeIn
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.archiveNow')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "promise"])
      ]),
      _: 1
    }, 8, ["entity-type", "section-type", "title", "show-stepper"]),
    _createVNode(_component_ion_modal, {
      mode: "md",
      "is-open": _ctx.visibleConfirmationSummary,
      component: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_confirmation_summary, {
          icon: "inbox-in",
          title: _ctx.$t('anapathapp.archive.label.archived'),
          counter: _ctx.elementsWithoutError,
          onConfirm: _cache[3] || (_cache[3] = ($event: any) => (_ctx.confirm()))
        }, null, 8, ["title", "counter"])
      ]),
      _: 1
    }, 8, ["is-open"])
  ], 64))
}