import { PromiseUtil } from '@/app/core/utils/promise-util'
import { RootStoreState } from '@/app/plugins/vuex/store/root/root-store-state.model'
import { router } from '@/app/router'
import { auth } from '@api/auth'
import { authService } from '@vitro/anapath-frontend-library-api'
import { MutationPayload, Plugin, Store } from 'vuex'
import { LoginModuleRoute } from '@/app/views/login/login-module.router'

async function userLogoutAndClearSession(): Promise<void> {
    try {
        await authService.logout()
    } finally {
        await auth.clearSession()
        if (router.currentRoute.value.name !== LoginModuleRoute.LOGIN) {
            await router.replace({ name: LoginModuleRoute.LOGIN })
        }
    }
}

export const userLoggedResetState =
    async (store: Store<RootStoreState>): Promise<void> => {
        try {
            await PromiseUtil.toCallUnique(
                userLogoutAndClearSession(),
                error => PromiseUtil.handleAxiosError(error, [401]))
        } finally {
            await store.dispatch('reset')
        }
    }

export const userLoggedInChangedStorePlugin: Plugin<RootStoreState> =
    (store: Store<RootStoreState>) => {
        store.subscribe(async (mutation: MutationPayload) => {
            if (mutation.type === 'updateUserLoggedIn') {
                const userLoggedIn: boolean = mutation.payload
                if (!userLoggedIn) {
                    await userLoggedResetState(store)
                }
            }
        })
    }
