import {
    Overlay,
    OverlayEvent,
    OverlayLib,
    OverlayOptions
} from '@api/lib/overlay'
import { modalController as modalIonicController } from '@ionic/vue'
import { Optional } from '@/app/core/types/app-types'

export interface ModalOptions extends OverlayOptions {
    component: Function | HTMLElement | string
    componentProps?: Record<string, unknown>
    presentingElement?: HTMLElement
    showBackdrop?: boolean
    swipeToClose?: boolean
}

class Modal implements Overlay<ModalOptions> {
    private _element: HTMLIonModalElement
    public readonly options: ModalOptions

    constructor(options: ModalOptions, element: HTMLIonModalElement) {
        this.options = options
        this._element = element
    }

    dismiss<T>(data?: T, role?: string): Promise<boolean> {
        return this._element.dismiss(data, role)
    }

    async onDidDismiss<T>(): Promise<OverlayEvent<T>> {
        const event = await this._element.onDidDismiss()
        return {
            data: event.data,
            role: event.role
        }
    }

    async onWillDismiss<T>(): Promise<OverlayEvent<T>> {
        const event = await this._element.onWillDismiss()
        return {
            data: event.data,
            role: event.role
        }
    }

    present(): Promise<void> {
        return this._element.present()
    }
}

class ModalController implements OverlayLib<ModalOptions, Overlay<ModalOptions>> {
    private _overlay: Optional<Modal>

    async create(options: ModalOptions): Promise<Overlay<ModalOptions>> {
        const modal = await modalIonicController.create(options)
        this._overlay = new Modal(options, modal)
        return this._overlay
    }

    async dismiss<D>(data?: D): Promise<boolean> {
        return await this._overlay?.dismiss(data) ?? false
    }
}
export const modalController = new ModalController()
