import { axios } from '@vitro/anapath-frontend-library-api'

class CommonParameterService {
    public async getParameter(laboratoryId: number, key: string): Promise<string> {
        const response = await axios.get<string>(
            `/laboratories/${laboratoryId}/parameter`,
            { params: { key } })
        return response.data
    }
}
export const commonParameterService = new CommonParameterService()
