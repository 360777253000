import { store } from '@api/lib'
import { ModuleOperation } from '@api/operations/module-operation'
import { sessionStore } from '@api/session-store'
import { getModule } from '@api/module'
import { auth } from '@api/auth'
import { router } from '@/app/router'
import { WizardRoute } from '@/app/views/wizard/wizard.router'

export class ModuleUtil {
    public static hasModuleAccess(moduleCode: ModuleOperation): boolean {
        const userModules = store.state.modules.map(module => module.moduleCode)
        return userModules.includes(moduleCode)
    }

    public static get defaultModule(): ModuleOperation {
        const userModuleCodes = store.state.modules.map(module => module.moduleCode)
        if (store.state.defaultAccess) {
            if (sessionStore.isPersonnelUser) {
                const personnelDefaultModule =
                    store.state.defaultAccess.defaultLabModules?.find(
                        defaultLabModule => defaultLabModule.laboratory.labId === store.getters['session/labId'])
                if (
                    personnelDefaultModule &&
                    ModuleUtil.hasModuleAccess(personnelDefaultModule.moduleCode) &&
                    userModuleCodes.includes(personnelDefaultModule.moduleCode)
                ) {
                    return personnelDefaultModule.moduleCode
                }
            } else if (sessionStore.isPetitionerUser) {
                const petitionerDefaultModuleCode = store.state.defaultAccess.moduleCode
                if (
                    petitionerDefaultModuleCode &&
                    ModuleUtil.hasModuleAccess(petitionerDefaultModuleCode) &&
                    userModuleCodes.includes(petitionerDefaultModuleCode)
                ) {
                    return petitionerDefaultModuleCode
                }
            }
        }
        return userModuleCodes.length ? userModuleCodes[0] : ModuleOperation.PROFILE
    }

    public static async navigateToDefaultModule(): Promise<void> {
        const module = getModule(ModuleUtil.defaultModule)
        if (module !== null) {
            if (auth.visibleWizard) {
                auth.visibleWizard = false
                await router.push({ name: WizardRoute.WIZARD, params: { routeName: String(module.route.name) } })
            } else {
                await router.push(module.route)
            }
        }
    }
}
