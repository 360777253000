import { defineComponent, reactive } from 'vue'
import ArchiveLocationInput from '@/app/views/modules/components/archive/components/shared/location-input/archive-location-input.vue'
import CardsListFilter from '@/app/shared/components/cards/cards-list-filter/cards-list-filter.vue'
import {
    ArchivePendingToBeUnArchivedFilterActionLocationEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/filter/action/components/location/archive-pending-to-be-un-archived-filter-action-location.model'
import {
    ArchivePendingToBeUnArchivedFilterActionLocationClass
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/filter/action/components/location/archive-pending-to-be-un-archived-filter-action-location.class'

export default defineComponent({
    name: 'ArchivePendingToBeUnArchivedFilterActionLocation',
    components: {
        ArchiveLocationInput,
        CardsListFilter
    },
    emits: [ ...Object.values(ArchivePendingToBeUnArchivedFilterActionLocationEmitEvent) ],
    setup(props, { emit }) {
        const _class = reactive(new ArchivePendingToBeUnArchivedFilterActionLocationClass(emit))
        return {
            /// METHODS
            clearFilters: () => _class.clearFilters(),
            filter: () => _class.filter(),
            locationChanged: (location: string) => _class.locationChanged(location)
        }
    }
})
