import { ExceptionCodeType } from '@vitro/anapath-frontend-library-api'
import { StrictNullable } from '@/app/core/types/app-types'
import { ModuleUtil } from '@/app/core/utils/module-util'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { ToastUtil } from '@/app/core/utils/lib/overlay/toast-util'
import { LoginType } from '@/app/views/login/login-module.model'
import { auth } from '@api/auth'
import { i18n, store } from '@api/lib'
import { alertController } from '@api/lib/overlay'
import { AuthenticationExceptionResponse, axios } from '@vitro/anapath-frontend-library-api'
import { AxiosError } from 'axios'

export class LoginClass {
    private _loading: boolean

    public get loading(): boolean {
        return this._loading
    }

    constructor() {
        this._loading = false
    }

    public async showRecoverPassword(): Promise<void> {
        const globalExternalAuthentication = await auth.isGlobalExternalAuthentication()
        if (globalExternalAuthentication?.globalExternalAuthentication && typeof globalExternalAuthentication.message === 'string') {
            await ToastUtil.showError(globalExternalAuthentication.message)
        } else {
            await store.dispatch('login/changeLoginType', LoginType.RECOVER_PASSWORD)
        }
    }

    public async showChangePassword(): Promise<void> {
        await store.dispatch('login/changeLoginType', LoginType.NEW_PASSWORD)
    }

    private async _onLogInSuccess(): Promise<void> {
        if (auth.state?.passwordSingleUse) {
            await this.showChangePassword()
            return
        }

        // Validations
        let errorMessage: StrictNullable<string> = null
        if (!store.state.laboratoriesConfiguration.length) {
            // No operation configured to any laboratory
            errorMessage = i18n.t('anapathapp.modal.changePassword.message.noOperationsError')
        } else if (!store.state.modules.length) {
            // No access to any laboratory
            errorMessage = i18n.t('anapathapp.modal.changePassword.message.noModulesError')
        }
        if (errorMessage !== null) {
            await ToastUtil.showError(errorMessage)
            await auth.clearSession()
            return
        }

        await ModuleUtil.navigateToDefaultModule()
    }

    private static _getErrorMessageByApiExceptionCode(apiExceptionCode: ExceptionCodeType): StrictNullable<string> {
        switch (apiExceptionCode) {
        case ExceptionCodeType.USER_CREDENTIALS_EXPIRED:
            return i18n.t('login.message.credentialsExpired')
        case ExceptionCodeType.BAD_CREDENTIALS:
            return i18n.t('login.message.badCredentials')
        case ExceptionCodeType.USER_ACCOUNT_LOCKED:
            return i18n.t('login.message.userAccountLocked')
        }
        return null
    }

    private async _onLogInFailed(exception: AuthenticationExceptionResponse): Promise<void> {
        let errorMessage = LoginClass._getErrorMessageByApiExceptionCode(exception.code)
        if (errorMessage === null && exception.messageTranslated) {
            errorMessage = exception.message
        }

        if (errorMessage !== null) {
            await ToastUtil.showError(errorMessage)
        }
    }

    public async login(username: StrictNullable<string>, password: StrictNullable<string>): Promise<void> {
        if (username && password) {
            try {
                this._loading = true

                await auth.logIn(username, password)
                await this._onLogInSuccess()
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    const error = e as AxiosError<AuthenticationExceptionResponse>
                    const exception = error.response?.data
                    if (exception) {
                        await this._onLogInFailed(exception)
                    }
                } else {
                    await PromiseUtil.handleAxiosError(e)
                }
            } finally {
                this._loading = false
            }
        }
    }

    public async fingerPrintAlert(): Promise<void> {
        const alert =
            await alertController.create({
                mode: 'ios',
                cssClass: 'alert alert--info',
                header: i18n.t('anapathapp.alert.fingerPrintTitle'),
                message: i18n.t('anapathapp.alert.fingerPrintDescription'),
                buttons: [i18n.t('anapathapp.action.cancel')]
            })
        await alert.present()
    }

    public async faceIdAlert(): Promise<void> {
        const alert =
            await alertController.create({
                mode: 'ios',
                cssClass: 'alert alert--danger',
                header: i18n.t('anapathapp.alert.faceIdTitle'),
                message: i18n.t('anapathapp.alert.faceIdDescription'),
                buttons: [i18n.t('anapathapp.action.cancel')]
            })
        await alert.present()
    }

    public async onEnter(username: StrictNullable<string>, password: StrictNullable<string>): Promise<void> {
        if (username && password) {
            await this.login(username, password)
        }
    }
}
