import { computed, defineComponent, onMounted, reactive, toRef } from 'vue'
import { LanguageSelectorClass } from '@/app/shared/components/selector/language/language-selector.class'
import { LanguageSelectorEmitEvent } from '@/app/shared/components/selector/language/language-selector.model'
import { CommonLanguageResponse } from '@api/services/common/language/common-language.dto'

export default defineComponent({
    name: 'LanguageSelector',
    emits: [...Object.values(LanguageSelectorEmitEvent)],
    setup(props, { emit }) {
        const _class = reactive(new LanguageSelectorClass(emit))

        /// HOOKS
        onMounted(() => _class.initialize())

        return {
            /// DATA
            selectedLanguage: toRef(_class, 'language'),
            /// COMPUTED
            languages: computed(() => _class.languages),
            /// METHODS
            changeLanguage: (language: CommonLanguageResponse) => _class.changeLanguage(language)
        }
    }
})
