import { computed, defineComponent, PropType, reactive } from 'vue'
import CardsListFilter from '@/app/shared/components/cards/cards-list-filter/cards-list-filter.vue'
import {
    ArchivePendingToBeUnArchivedFilterActionPendingUnArchivingReasonEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/filter/action/components/pending-un-archiving-reason/archive-pending-to-be-un-archived-filter-action-pending-un-archiving-reason.model'
import {
    ArchivePendingToBeUnArchivedFilterActionPendingUnArchivingReasonClass
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/filter/action/components/pending-un-archiving-reason/archive-pending-to-be-un-archived-filter-action-pending-un-archiving-reason.class'
import { PendingUnarchivingReasonResponse } from '@api/services/archive/archive.dto'
import { ArchiveEntityDatasource } from '@/app/views/modules/components/archive/datasources/entities/archive-entity.datasource'
import { StrictNullable } from '@/app/core/types/app-types'

export default defineComponent({
    name: 'ArchivePendingToBeUnArchivedFilterActionPendingUnArchivingReason',
    components: {
        CardsListFilter
    },
    props: {
        reasonCode: { type: String as PropType<StrictNullable<PendingUnarchivingReasonResponse['code']>>, default: () => null },
        datasource: { type: Object as PropType<ArchiveEntityDatasource>, required: true }
    },
    emits: [ ...Object.values(ArchivePendingToBeUnArchivedFilterActionPendingUnArchivingReasonEmitEvent) ],
    setup(props, { emit }) {
        const _class = reactive(new ArchivePendingToBeUnArchivedFilterActionPendingUnArchivingReasonClass(emit))

        return {
            /// COMPUTED
            reasons: computed(() => _class.reasons),
            selectedReason: computed(() => _class.selectedReason),
            /// METHODS
            selectReason: (reason: PendingUnarchivingReasonResponse) => _class.selectReason(reason),
            clearFilters: () => _class.clearFilters(),
            onShow: () => _class.loadData(props.datasource, props.reasonCode),
            filter: () => _class.filter(),
            resetFilter: () => _class.resetFilter()
        }
    }
})
