import { PromiseUtil } from '@/app/core/utils/promise-util'
import { commonUserappProfileImageService } from '@api/services/common/userapp-profile-image/common-userapp-profile-image.service'
import { HelperUtil } from '@/app/core/utils/helper-util'
import { store } from '@api/lib'
import { StrictNullable } from '@/app/core/types/app-types'

export class UserProfileUtil {
    public static async updateUserLastProfileImage(): Promise<void> {
        const response = await PromiseUtil.toCallSecure(
            commonUserappProfileImageService.getUserProfileImage(),
            error => PromiseUtil.handleAxiosError(error, [404]))
        if (response) {
            const base64LastProfileImage = await HelperUtil.fileToBase64(response)
            await store.dispatch('changeUserLastProfileImage', base64LastProfileImage)
        }
    }

    public static async changeUserProfileImage(profileImage?: StrictNullable<File>): Promise<void> {
        let response: StrictNullable<Blob | void>
        if (profileImage) {
            response =
                await PromiseUtil.toCallSecure(
                    commonUserappProfileImageService.replaceUserProfileImage(profileImage))
        } else {
            response =
                await PromiseUtil.toCallSecure(
                    commonUserappProfileImageService.deleteUserProfileImage())
        }
        if (response !== null) {
            await UserProfileUtil.updateUserLastProfileImage()
        }
    }
}
