import { EmitFn, i18n } from '@api/lib'
import {
    TakeOutReturnOption,
    TakeOutReturnSelectorEmitEvent, TakeOutReturnSelectorParams
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/take-out-return-selector/take-out-return-selector.model'
import { StrictNullable } from '@/app/core/types/app-types'

export class TakeOutReturnSelectorClass {
    private readonly _emit: EmitFn<TakeOutReturnSelectorEmitEvent>
    private _selectedReturnOption: StrictNullable<TakeOutReturnOption>

    public get returnOptions(): TakeOutReturnOption[] {
        return [
            {
                code: 'should-not-be-returned',
                getLabel: () => i18n.t('archive.modal.takeOut.action.return'),
                value: false
            },
            {
                code: 'should-be-returned',
                getLabel: () => i18n.t('archive.modal.takeOut.action.noReturn'),
                value: true
            }
        ]
    }
    public get selectedReturnOption(): StrictNullable<TakeOutReturnOption> {
        return this._selectedReturnOption
    }

    constructor(params: TakeOutReturnSelectorParams) {
        this._emit = params.emit
        this._selectedReturnOption =
            this.returnOptions.find(returnOption => returnOption.value === params.shouldBeReturned) ?? null
    }

    public selectReturnOption(returnOptionCode: TakeOutReturnOption['code']): void {
        const returnOption = this.returnOptions.find(r => r.code === returnOptionCode)
        if (returnOption) {
            this._selectedReturnOption = returnOption
            this._emit(TakeOutReturnSelectorEmitEvent.CHANGE, returnOption.value)
        }
    }
}
