import { VuePlugin } from '@/app'
import { App } from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { vt } from '@/app/plugins/font-awesome/custom-icons-packs/vitro'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

class FontAwesomePlugin implements VuePlugin {
    public register(app: App<Element>): void {
        library.add(fal, far, fas, vt) // TODO: Load only necessary icons

        app.component('FaIcon', FontAwesomeIcon)
    }
}
export const fontAwesomePlugin = new FontAwesomePlugin()
