import { Module } from 'vuex'
import { RootStoreState } from '@/app/plugins/vuex/store/root/root-store-state.model'
import { vuexPlugin } from '@/app/plugins/vuex/vuex-plugin'

export class VuexStoreModule<S> {
    private readonly _moduleName: string
    private readonly _module: Module<S, RootStoreState>

    /**
     * workaround:
     * created hooks fired before destroyed in hot reload
     * see https://github.com/vuejs/vue/issues/6518
     */
    private _instanceCount: number

    constructor(moduleName: string, module: Module<S, RootStoreState>) {
        this._moduleName = moduleName
        this._module = module

        this._instanceCount = 0
    }

    public register() {
        if (++this._instanceCount > 1) return

        if (!vuexPlugin.store.hasModule(this._moduleName)) {
            vuexPlugin.store.registerModule<S>(this._moduleName, this._module)
        }
    }

    public unregister() {
        if (--this._instanceCount > 0) return

        if (vuexPlugin.store.hasModule(this._moduleName)) {
            vuexPlugin.store.unregisterModule(this._moduleName)
        }
    }
}
