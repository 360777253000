import { Card } from '@/app/shared/components/cards/card/card.model'
import { ArchiveEntityResponse } from '@/app/views/modules/components/archive/datasources/archive-datasource-factory'

export enum TakeOutConflictsEmitEvent {
    CHANGE = 'change'
}

export interface TakeOutCard<T> extends Card<T> {
    confirmed: boolean
}

export interface TakeOutConflictsParams {
    cards: TakeOutCard<ArchiveEntityResponse>[]
}
