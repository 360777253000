import { computed, defineComponent, reactive } from 'vue'
import { ModulesClass } from '@/app/views/modules/modules.class'

export default defineComponent({
    name: 'Modules',
    setup() {
        const _class = reactive(new ModulesClass())
        return {
            /// COMPUTED
            moduleTabs: computed(() => _class.moduleTabs),
            routePath: computed(() => _class.routePath)
        }
    }
})
