import { axios } from '@vitro/anapath-frontend-library-api'
import { CommonFilterModel, CommonParams, Page } from '@/app/shared/models/common.model'
import { PendingUnarchivingReasonResponse } from '@api/services/archive/archive.dto'
import { SubportionType } from '@api/services/common/subportion-type/common-subportion-type.dto'
import {
    ArchiveSubportionFilterPaginatorType,
    ArchiveSubportionGridResponse
} from '@api/services/archive/subportion-filter/archive-subportion-filter.dto'

class ArchiveSubportionFilterService {
    public async getSubportionPendingUnArchivingReasons(): Promise<PendingUnarchivingReasonResponse[]> {
        const response =
            await axios.get<PendingUnarchivingReasonResponse[]>(
                `/archive/laboratories/subportionPendingUnarchivingReasons`)
        return response.data
    }

    public async paginator(
        laboratoryId: number,
        entitySubtype: SubportionType,
        url: ArchiveSubportionFilterPaginatorType,
        filterModel?: CommonFilterModel,
        params?: CommonParams
    ): Promise<Page<ArchiveSubportionGridResponse>> {
        const response =
            await axios.put<Page<ArchiveSubportionGridResponse>>(
                `/archive/laboratories/${laboratoryId}/cases/samples/portions/subportions/${url}`,
                { ...filterModel },
                { params: { entitySubtype, ...params } })
        return response.data
    }
}
export const archiveSubportionFilterService = new ArchiveSubportionFilterService()
