import { Optional } from '@/app/core/types/app-types'
import {
    FrontGlobalParametersDetailResponse,
    LaboratoryConfigurationResponse,
    LaboratoryParameterResponse
} from '@api/services/common/initialization/common-initialization.dto'
import { store } from '@api/lib'
import { LaboratoryStoreState } from '@/app/plugins/vuex/store/root/root-store-state.model'
import { SessionStoreState } from '@/app/plugins/vuex/store/root/modules/session/session-store-state.model'

export class StoreUtil {
    public static get labId(): number {
        const labId: SessionStoreState['labId'] = store.getters['session/labId']
        if (!labId) {
            throw new Error('labId not found')
        }
        return labId
    }

    public static get laboratories(): LaboratoryStoreState[] {
        return store.getters.laboratories
    }

    public static get selectedLaboratoryConfiguration(): Optional<LaboratoryConfigurationResponse> {
        return store.getters.laboratory
    }

    public static get globalParameters(): FrontGlobalParametersDetailResponse {
        const globalParameters = store.state.parameters.global
        if (!globalParameters) {
            throw new Error('globalParameters not found')
        }
        return globalParameters
    }

    public static get localParameters(): LaboratoryParameterResponse[] {
        const localParameters = StoreUtil.selectedLaboratoryConfiguration?.frontLaboratoryParameters
        if (!localParameters) {
            throw new Error('globalParameters not found')
        }
        return localParameters
    }
}
