import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!

  return (_openBlock(), _createBlock(_component_ion_select, {
    class: "ion-padding",
    value: _ctx.moduleOperation,
    interface: "popover",
    onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeModule($event.detail.value)))
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modules, (module) => {
        return (_openBlock(), _createBlock(_component_ion_select_option, {
          key: module.moduleCode,
          value: module.moduleCode
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(module.getLabel()), 1)
          ]),
          _: 2
        }, 1032, ["value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["value"]))
}