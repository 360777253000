import { VuePlugin } from '@/app'
import { App, InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'
import { RootStoreState } from '@/app/plugins/vuex/store/root/root-store-state.model'
import { rootStore } from '@/app/plugins/vuex/store/root/root-store'

class VuexPlugin implements VuePlugin {
    public readonly store: Store<RootStoreState>
    public readonly rootStoreKey: InjectionKey<Store<RootStoreState>>

    constructor() {
        this.rootStoreKey = Symbol()
        this.store = createStore(rootStore)
    }

    public register(app: App<Element>): void {
        app.use(this.store, this.rootStoreKey)
    }
}
export const vuexPlugin = new VuexPlugin()
