import { computed, defineComponent, onMounted, reactive } from 'vue'
import { userDowntimeClass } from '@/app/core/components/user-downtime/user-downtime.class'

export default defineComponent({
    name: 'UserDowntime',
    setup() {
        const _class = reactive(userDowntimeClass)

        /// HOOKS
        onMounted(() => _class.initialize())

        return {
            /// COMPUTED
            countDownMessage: computed(() => _class.countDownMessage),
            /// METHODS
            continueLogged: () => _class.continueLogged(),
            logOut: () => _class.logOut()
        }
    }
})
