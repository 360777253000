export enum SubportionOperation {
    CREATE = 'operation.entity.subportion.create',
    DELETE = 'operation.entity.subportion.delete',
    CANCEL = 'operation.entity.subportion.cancel',
    EDIT = 'operation.entity.subportion.edit',
    PRINT = 'operation.entity.subportion.print',
    ARCHIVE = 'operation.entity.subportion.archive',
    UNARCHIVE_TO_SCAN = 'operation.entity.subportion.unarchive-to-scan',
    UNARCHIVE_TO_STAIN = 'operation.entity.subportion.unarchive-to-stain',
    UNARCHIVE_FOR_PATHOLOGY_REVIEW = 'operation.entity.subportion.unarchive-for-pathology-review',
    TAKE_OUT_FROM_LABORATORY = 'operation.entity.subportion.take-out-from-laboratory',
    COVER = 'operation.entity.subportion.cover',
    TO_COVERING = 'operation.entity.subportion.to-covering',
    TO_STAINER = 'operation.entity.subportion.to-stainer',
    STAIN_AND_COVER = 'operation.entity.subportion.stain-and-cover',
    STAIN = 'operation.entity.subportion.stain',
    CUT = 'operation.entity.subportion.cut',
    SCAN = 'operation.entity.subportion.scan',
    SLIDE_SCAN = 'operation.entity.subportion.slide.scan',
    TO_SCANNER = 'operation.entity.subportion.to-scanner',
    TO_PATHOLOGIST = 'operation.entity.subportion.to-pathologist',
    ATTACH = 'operation.entity.subportion.attach',
    UNDO = 'operation.entity.subportion.undo',
    REQUEST_ADDITIONAL_ASSAY = 'operation.entity.subportion.request-additional-assay',
    REQUEST_ADDITIONAL_SCAN = 'operation.entity.subportion.request-additional-scan',
    REQUEST_PATHOLOGIST_REVIEW = 'operation.entity.subportion.request-pathologist-review'
}
