import { EmitFn } from '@api/lib'
import { CardsListFilterEmitEvent } from '@/app/shared/components/cards/cards-list-filter/cards-list-filter.model'

export class CardsListFilterClass {
    private readonly _emit: EmitFn<CardsListFilterEmitEvent>
    private _visibleFilter: boolean

    public get visibleFilter(): boolean {
        return this._visibleFilter
    }

    constructor(emit: EmitFn<CardsListFilterEmitEvent>) {
        this._emit = emit
        this._visibleFilter = false
    }

    public showFilter(): void {
        this._emit(CardsListFilterEmitEvent.SHOW)
        this._visibleFilter = true
    }
    public hideFilter(): void {
        this._emit(CardsListFilterEmitEvent.HIDE)
        this._visibleFilter = false
    }

    public clearFilters(): void {
        this._emit(CardsListFilterEmitEvent.CLEAR)
    }

    public filter(): void {
        this._emit(CardsListFilterEmitEvent.FILTERED)
        this.hideFilter()
    }
}
