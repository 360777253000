import { Card } from '@/app/shared/components/cards/card/card.model'
import { WasteSampleGridResponse } from '@api/services/waste/sample-filter/waste-sample-filter.dto'
import { CommonSampleFamilyTreeRequest } from '@api/services/common.dto'
import { ColDef, CommonFilterModel, Page } from '@/app/shared/models/common.model'
import { i18n } from '@api/lib'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { wasteSampleFilterService } from '@api/services/waste/sample-filter/waste-sample-filter.service'
import { wasteSampleService } from '@api/services/waste/sample/waste-sample.service'
import { StoreUtil } from '@/app/core/utils/lib/store-util'
import { Formatter } from '@/app/core/formatter'
import { InfiniteCardDatasource } from '@/app/shared/resources/datasources/inifinite-card.datasource'
import { GridUtil } from '@/app/core/utils/grid-util'
import { EntitySelectorType } from '@/app/shared/components/selector/entity/entity-selector.model'

export class WasteModuleClass extends InfiniteCardDatasource<WasteSampleGridResponse, WasteSampleGridResponse> {
    private _entityType: EntitySelectorType
    private _elementsWithoutError: number
    private _visibleConfirmationSummary: boolean

    public get entityType(): EntitySelectorType {
        return this._entityType
    }
    public get elementsWithoutError(): number {
        return this._elementsWithoutError
    }
    public get visibleConfirmationSummary(): boolean {
        return this._visibleConfirmationSummary
    }

    public get totalElements(): number {
        return this._totalElements
    }

    constructor() {
        super()
        this._entityType = EntitySelectorType.SAMPLES
        this._elementsWithoutError = 0
        this._visibleConfirmationSummary = false
    }

    protected _getColDefs(): ColDef<WasteSampleGridResponse>[] {
        return [
            { field: 'samLabel', label: i18n.t('anapathapp.label.elementId') },
            { field: 'disposalTargetResponseDate', label: i18n.t('anapathapp.label.date') }
        ]
    }

    protected _createCard(element: WasteSampleGridResponse): Card<WasteSampleGridResponse> {
        return {
            data: element,
            selected: false,
            title: element.samLabel,
            subtitleHeader: i18n.t('anapathapp.label.expectedDate'),
            subtitle: Formatter.date(element.disposalTargetResponseDate)
        }
    }

    protected _createCardFromSearch(element: WasteSampleGridResponse): Card<WasteSampleGridResponse> {
        return this._createCard(element)
    }

    protected _fetchData(): Promise<Page<WasteSampleGridResponse>> {
        return wasteSampleFilterService.paginator(
            StoreUtil.labId,
            'toBeDiscarded',
            undefined,
            super.getFetchDataParams())
    }

    protected async _searcher(label: string): Promise<WasteSampleGridResponse[]> {
        const filterModel: CommonFilterModel = {
            samLabel: GridUtil.getTextFilterModel('equals', label)
        }
        const foundElements =
            await wasteSampleFilterService.paginator(
                StoreUtil.labId,
                'toBeDiscarded',
                filterModel)

        return foundElements?.content ?? []
    }

    public async discardSamples(cards: Card<WasteSampleGridResponse>[]): Promise<void> {
        const request: CommonSampleFamilyTreeRequest[] =
            cards.filter(card => card.selected).map(card => ({
                caseId: card.data.caseStudy.casId,
                sampleId: card.data.samId
            }))
        const errors =
            await PromiseUtil.toCallSecure(
                wasteSampleService.discard(StoreUtil.labId, request))
        this._elementsWithoutError = cards.length - (errors?.length ?? 0)
        this._visibleConfirmationSummary = true
    }

    public confirm(): void {
        this._visibleConfirmationSummary = false
    }

    public async changeEntity(entityType: EntitySelectorType): Promise<void> {
        this._entityType = entityType
        await this.loadData()
    }
}
