import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-be053e5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-change-password" }
const _hoisted_2 = { class: "login-module__title" }
const _hoisted_3 = { class: "login-module__subtitle" }
const _hoisted_4 = { class: "font-size--lg" }
const _hoisted_5 = { class: "flex-all-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_loading_button = _resolveComponent("loading-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_row, { class: "ion-justify-content-center ion-margin-vertical" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_col, {
          class: "ion-text-center ion-margin-top",
          size: "12"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('anapathapp.label.newPassword')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_col, {
          class: "ion-text-center ion-margin-bottom",
          size: "12"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('anapathapp.label.enterAndConfirmNewPassword')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_col, {
          class: "ion-text-center ion-margin-bottom",
          size: "8"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('anapathapp.label.passwordRuleDescription')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_col, { size: "10" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, { class: "login-module__input" }, {
              default: _withCtx(() => [
                _createVNode(_component_fa_icon, {
                  icon: ['fal', 'unlock'],
                  class: "ion-margin-end app-login__input-icon"
                }),
                _createVNode(_component_ion_input, {
                  modelValue: _ctx.password,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.password = $event)),
                  type: _ctx.passwordInputType,
                  placeholder: _ctx.$t('anapathapp.label.newPassword'),
                  required: "",
                  autocomplete: "new-password",
                  autofocus: ""
                }, null, 8, ["modelValue", "type", "placeholder"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_col, {
          size: "10",
          class: "ion-padding-top"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, { class: "login-module__input" }, {
              default: _withCtx(() => [
                _createVNode(_component_fa_icon, {
                  icon: ['fal', 'repeat'],
                  class: "ion-margin-end app-login__input-icon"
                }),
                _createVNode(_component_ion_input, {
                  modelValue: _ctx.passwordConfirmation,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.passwordConfirmation = $event)),
                  type: _ctx.passwordInputType,
                  placeholder: _ctx.$t('anapathapp.label.repeatPassword'),
                  required: "",
                  autocomplete: "new-password"
                }, null, 8, ["modelValue", "type", "placeholder"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_col, {
          size: "10",
          class: "toggle-password ion-padding-top"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_button, {
              fill: "clear",
              class: "toggle-password-button ion-no-margin",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.togglePassword()))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_5, [
                  (_ctx.showPassword)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('login.action.hidePasswords')) + " ", 1),
                        _createVNode(_component_fa_icon, {
                          icon: ['far', 'eye-slash'],
                          class: "ion-margin-start"
                        })
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('global.action.showPasswords')) + " ", 1),
                        _createVNode(_component_fa_icon, {
                          icon: ['far', 'eye'],
                          class: "ion-margin-start"
                        })
                      ], 64))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_col, {
          size: "10",
          class: "ion-padding-top"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_loading_button, {
              class: "login-module__submit",
              mode: "md",
              expand: "block",
              shape: "round",
              strong: "",
              promise: () => _ctx.changePassword(_ctx.password, _ctx.passwordConfirmation)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.apply')), 1)
              ]),
              _: 1
            }, 8, ["promise"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_col, { size: "10" }, {
          default: _withCtx(() => [
            _createVNode(_component_loading_button, {
              fill: "clear",
              color: "light",
              promise: _ctx.showLogin
            }, {
              default: _withCtx(() => [
                _createVNode(_component_fa_icon, {
                  icon: ['fal', 'arrow-left'],
                  class: "ion-margin-end"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('anapathapp.action.back')), 1)
              ]),
              _: 1
            }, 8, ["promise"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}