import { Module } from 'vuex'
import {
    ModulesArchivePendingToBeUnArchivedStoreState
} from '@/app/views/modules/store/modules/archive/modules/pending-to-be-un-archived/modules-archive-pending-to-be-un-archived-store-state.model'
import { RootStoreState } from '@/app/plugins/vuex/store/root/root-store-state.model'

const initialModulesArchivePendingToBeUnArchivedStoreState =
    (): ModulesArchivePendingToBeUnArchivedStoreState => ({
        pendingUnArchivingReason: null,
        locations: []
    })

export const modulesArchivePendingToBeUnArchivedStoreModule: Module<ModulesArchivePendingToBeUnArchivedStoreState, RootStoreState> = {
    namespaced: true,
    state: initialModulesArchivePendingToBeUnArchivedStoreState,
    getters: {
        pendingUnArchivingReason:
            (state): ModulesArchivePendingToBeUnArchivedStoreState['pendingUnArchivingReason'] => state.pendingUnArchivingReason,
        locations:
            (state): ModulesArchivePendingToBeUnArchivedStoreState['locations'] => state.locations
    },
    mutations: {
        resetState:
            (state): void => {
                Object.assign(state, initialModulesArchivePendingToBeUnArchivedStoreState())
            },

        updatePendingUnArchivingReason:
            (state, payload: ModulesArchivePendingToBeUnArchivedStoreState['pendingUnArchivingReason']): void => {
                state.pendingUnArchivingReason = payload
            },
        updateLocations:
            (state, payload: ModulesArchivePendingToBeUnArchivedStoreState['locations']): void => {
                state.locations = payload
            }
    },
    actions: {
        reset:
            ({ commit }): void => {
                commit('resetState')
            },

        changePendingUnArchivingReason:
            ({ commit, getters }, payload: ModulesArchivePendingToBeUnArchivedStoreState['pendingUnArchivingReason']): void => {
                if (getters.pendingUnArchivingReason !== payload) {
                    commit('updatePendingUnArchivingReason', payload)
                }
            },
        changeLocations:
            ({ commit, getters }, payload: ModulesArchivePendingToBeUnArchivedStoreState['locations']): void => {
                if (getters.locations !== payload) {
                    commit('updateLocations', payload)
                }
            }
    }
}
