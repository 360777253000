import { computed, defineComponent, reactive } from 'vue'
import FilterChip from '@/app/shared/components/filter-chip/filter-chip.vue'
import {
    ArchivePendingToBeUnArchivedFilterEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/filter/archive-pending-to-be-un-archived-filter.model'
import {
    ArchivePendingToBeUnArchivedFilterClass
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/filter/archive-pending-to-be-un-archived-filter.class'

export default defineComponent({
    name: 'ArchivePendingToBeUnArchivedFilterInfo',
    components: {
        FilterChip
    },
    emits: [ ...Object.values(ArchivePendingToBeUnArchivedFilterEmitEvent) ],
    setup(props, { emit }) {
        const _class = reactive(new ArchivePendingToBeUnArchivedFilterClass(emit))
        return {
            /// COMPUTED
            pendingUnArchivingReason: computed(() => _class.pendingUnArchivingReason),
            locations: computed(() => _class.locations),
            /// METHODS
            clearPendingUnArchivingReasonFilter: () => _class.clearPendingUnArchivingReasonFilter(),
            removeLocationFilter: (location: string) => _class.removeLocationFilter(location)
        }
    }
})
