import { computed, defineComponent, reactive } from 'vue'
import { ModuleSelectorEmitEvent } from '@/app/shared/components/selector/module/module-selector.model'
import { ModuleSelectorClass } from '@/app/shared/components/selector/module/module-selector.class'
import { ModuleOperation } from '@api/operations/module-operation'

export default defineComponent({
    name: 'ModuleSelector',
    emits: [...Object.values(ModuleSelectorEmitEvent)],
    setup(props, { emit }) {
        const _class = reactive(new ModuleSelectorClass(emit))
        return {
            /// COMPUTED
            moduleOperation: computed(() =>_class.moduleOperation),
            modules: computed(() => _class.modules),
            /// METHODS
            changeModule: (moduleOperation: ModuleOperation) => _class.changeModule(moduleOperation)
        }
    }
})
