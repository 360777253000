import { EmitFn } from '@api/lib'
import {
    ArchivePendingToBeArchivedSelectionToolbarEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-archived/selection-toolbar/archive-pending-to-be-archived-selection-toolbar.model'
import { ArchiveEntityDatasource } from '@/app/views/modules/components/archive/datasources/entities/archive-entity.datasource'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { StoreUtil } from '@/app/core/utils/lib/store-util'
import {
    ArchiveEntityArchiveRequest,
    ArchiveEntityResponse
} from '@/app/views/modules/components/archive/datasources/archive-datasource-factory'
import { Card } from '@/app/shared/components/cards/card/card.model'
import { ParameterUtil } from '@/app/core/utils/parameter-util'
import { EntitySelectorType } from '@/app/shared/components/selector/entity/entity-selector.model'

export class ArchivePendingToBeArchivedSelectionToolbarClass {
    private readonly _emit: EmitFn<ArchivePendingToBeArchivedSelectionToolbarEmitEvent>
    private _askLocation: boolean
    private _elementsWithoutError: number
    private _visibleConfirmationSummary: boolean

    public get askLocation(): boolean {
        return this._askLocation
    }
    public get elementsWithoutError(): number {
        return this._elementsWithoutError
    }
    public get visibleConfirmationSummary(): boolean {
        return this._visibleConfirmationSummary
    }

    constructor(emit: EmitFn<ArchivePendingToBeArchivedSelectionToolbarEmitEvent>) {
        this._emit = emit
        this._askLocation = false
        this._elementsWithoutError = 0
        this._visibleConfirmationSummary = false
    }

    public async archive(
        cards: Card<ArchiveEntityResponse>[],
        datasource: ArchiveEntityDatasource,
        locationLabel: string
    ): Promise<void> {
        const request: ArchiveEntityArchiveRequest[] =
            cards.map(({ data }) => ({
                idHierarchy: datasource.getEntityHierarchicalIds(data),
                locationLabel,
                id: data.id
            }))
        const errors =
            await PromiseUtil.toCallSecure(
                datasource.archive(StoreUtil.labId, request))
        this._elementsWithoutError = cards.length - (errors?.length ?? 0)
        this._askLocation = false
        this._visibleConfirmationSummary = true
    }

    public async takeIn(
        cards: Card<ArchiveEntityResponse>[],
        entityType: EntitySelectorType,
        datasource: ArchiveEntityDatasource
    ): Promise<void> {
        this._askLocation = false
        if (ParameterUtil.isLocationRequired(entityType)) {
            this._askLocation = true
        } else {
            await this.archive(cards, datasource, '')
        }
    }

    public confirm(): void {
        this._visibleConfirmationSummary = false
        this._emit(ArchivePendingToBeArchivedSelectionToolbarEmitEvent.REFRESH)
    }
}
