import { EmitFn } from '@api/lib'
import {
    TakeOutCard,
    TakeOutConflictsEmitEvent,
    TakeOutConflictsParams
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/take-out-conflicts/take-out-conflicts.model'
import { ArchiveEntityResponse } from '@/app/views/modules/components/archive/datasources/archive-datasource-factory'

export class TakeOutConflictsClass {
    private readonly _emit: EmitFn<TakeOutConflictsEmitEvent>

    public params: TakeOutConflictsParams

    public get groupedCards(): Record<ArchiveEntityResponse['pendingUnarchivingReason'], TakeOutCard<ArchiveEntityResponse>[]> {
        return this.params.cards.reduce<Record<string, TakeOutCard<ArchiveEntityResponse>[]>>((accumulator, currentValue) => {
            if (!accumulator[currentValue.data.pendingUnarchivingReason]) {
                accumulator[currentValue.data.pendingUnarchivingReason] = []
            }
            accumulator[currentValue.data.pendingUnarchivingReason].push(currentValue)
            return accumulator
        }, {})
    }
    public get pendingUnarchivingReasons(): string[] {
        return Object.keys(this.groupedCards)
    }

    constructor(emit: EmitFn<TakeOutConflictsEmitEvent>, params: TakeOutConflictsParams) {
        this._emit = emit
        this.params = params
    }

    public hasReasonAllConfirmed(reason: ArchiveEntityResponse['pendingUnarchivingReason']): boolean {
        return this.groupedCards[reason].every(card => card.confirmed)
    }

    public toggleReasonConfirmation(reason: ArchiveEntityResponse['pendingUnarchivingReason']): void {
        const selected = !this.hasReasonAllConfirmed(reason)
        for (const card of this.groupedCards[reason]) {
            card.confirmed = selected
        }
    }

    public toggleCardConfirmation(card: TakeOutCard<ArchiveEntityResponse>): void {
        card.confirmed = !card.confirmed
    }
}
