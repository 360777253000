import { CommonSortModelResource } from '@/app/shared/models/common.model'
import { Nullable } from '@/app/core/types/app-types'
import { DateUtil } from '@/app/core/utils/date-util'
import {
    CombinedFilterModel,
    DateFilterModel,
    NumberFilterModel,
    SetFilterModel,
    SetFilterModelValue,
    TextFilterModel
} from '@/app/shared/models/ag-grid.model'

export class GridUtil {
    public static getSortValue<T>(sortModel?: CommonSortModelResource<T>[]): string[] {
        if (sortModel?.length) {
            return sortModel.map(i => `${i.colDef.colId ?? String(i.colDef.field)},${i.sort}`)
        }
        return []
    }

    /// Filter model utilities

    public static getDateFilterModel(type: string, dateFrom?: Nullable<Date>, dateTo?: Nullable<Date>): DateFilterModel {
        return {
            dateFrom: dateFrom ? DateUtil.formatDate(dateFrom) : null,
            dateTo: dateTo ? DateUtil.formatDate(dateTo) : null,
            filterType: 'date',
            type
        }
    }
    public static getDateTimeFilterModel(type: string, dateFrom?: Nullable<Date>, dateTo?: Nullable<Date>): DateFilterModel {
        return {
            dateFrom: dateFrom ? DateUtil.formatDate(dateFrom, DateUtil.dateTimeWithSecondsPattern) : null,
            dateTo: dateTo ? DateUtil.formatDate(dateTo, DateUtil.dateTimeWithSecondsPattern) : null,
            filterType: 'date',
            type
        }
    }
    public static getNumberFilterModel(type: string, filter?: Nullable<number>, filterTo?: Nullable<number>): NumberFilterModel {
        return {
            filter,
            filterTo,
            filterType: 'number',
            type
        }
    }
    public static getSetFilterModel(values: SetFilterModelValue): SetFilterModel {
        return {
            filterType: 'set',
            values
        }
    }
    public static getTextFilterModel(type: string, filter?: Nullable<string>): TextFilterModel {
        return {
            filter,
            filterType: 'text',
            type
        }
    }

    public static getCombinedFilter(values: SetFilterModelValue, propertyKeyCondition: string, filterType: string): CombinedFilterModel {
        // TODO: Remove this temporally implementation when Ag-grid is upgraded to version 29.2.0, changes are needed in back
        // Check if filter is simple or combined
        const isSimpleFilter = !!(values[0] && !values[1])
        // Initialize first level combined filter object
        const filter: CombinedFilterModel = {
            combinedFilter: {
                filterType: 'combined',
                operator: 'OR',
                propertyKeyCondition1: propertyKeyCondition,
                condition1: {
                    filterType,
                    type: 'contains',
                    filter: values[0]
                },
                propertyKeyCondition2: propertyKeyCondition,
                condition2: {
                    filterType,
                    type: 'contains',
                    filter: values[1]
                }
            }
        }
        // Check if the current filter is simple
        if (isSimpleFilter) {
            // Assign a simple filter inside current initial filter
            filter.combinedFilter = {
                filterType,
                type: 'contains',
                filter: values[0]
            }
        }
        if (values.length > 2) {
            // Divide filter in two parts if it contains more than two values, and create two sub-filters recursively
            const firstPartValuesSubFilter = GridUtil.getCombinedFilter(
                values.slice(0, 2),
                propertyKeyCondition,
                filterType
            )
            const secondPartValuesSubFilter = GridUtil.getCombinedFilter(
                values.slice(2),
                propertyKeyCondition,
                filterType
            )
            // Check if the current filter is combined
            if (filter.combinedFilter) {
                // Combine the sub-filters to create the complete filter
                if (firstPartValuesSubFilter !== null) {
                    filter.combinedFilter.condition1 = {
                        ...firstPartValuesSubFilter.combinedFilter
                    };
                }
                if (secondPartValuesSubFilter !== null) {
                    filter.combinedFilter.condition2 = {
                        ...secondPartValuesSubFilter.combinedFilter
                    }
                }
            }
        }
        return filter
    }
}
