import Slides from '@/app/shared/components/slides/slides.vue'
import { modalController } from '@api/lib/overlay'

export class HelpModuleClass {
    public async showWizard(): Promise<void> {
        const modal =
            await modalController.create({
                mode: 'md',
                cssClass: 'slides__modal',
                component: Slides,
                componentProps: {
                    action: () => modalController.dismiss()
                }
            })
        await modal.present()
    }
}
