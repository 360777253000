import { computed, defineComponent, onMounted, PropType, reactive } from 'vue'
import {
    TakeOutReasonSelectorEmitEvent,
    TakeOutReasonSelectorParams
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/take-out-reason-selector/take-out-reason-selector.model'
import {
    TakeOutReasonSelectorClass
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/take-out-reason-selector/take-out-reason-selector.class'
import { StrictNullable } from '@/app/core/types/app-types'
import { CommonUnarchivingReasonResponse } from '@api/services/archive/common/archive-common.dto'

export default defineComponent({
    name: 'TakeOutReasonSelector',
    props: {
        reason: { type: Object as PropType<StrictNullable<CommonUnarchivingReasonResponse>>, default: () => null }
    },
    emits: [...Object.values(TakeOutReasonSelectorEmitEvent)],
    setup(props, { emit }) {
        const _getParams =
            (): TakeOutReasonSelectorParams => ({
                emit,
                reason: props.reason
            })
        const _class = reactive(new TakeOutReasonSelectorClass(_getParams()))

        /// HOOKS
        onMounted(() => _class.loadReasons())

        return {
            /// COMPUTED
            reasons: computed(() => _class.reasons),
            selectedReason: computed(() => _class.selectedReason?.id),
            /// METHODS
            selectReason: (reasonId: CommonUnarchivingReasonResponse['id']) => _class.selectReason(reasonId)
        }
    }
})
