import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b993e8d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card_checkbox" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    class: _normalizeClass(["card", { 'card-active': _ctx.card.selected, 'card-selectable': _ctx.selectable }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.select(_ctx.card)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_content, { class: "card__content" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              (_ctx.selectable)
                ? (_openBlock(), _createBlock(_component_ion_col, {
                    key: 0,
                    class: "card__check-container",
                    size: "auto"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_fa_icon, {
                          icon: ['fas', 'check'],
                          class: "card_checkbox-icon"
                        })
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_col, { class: "card__title" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.card.title), 1)
                ]),
                _: 1
              }),
              (_ctx.hasSubtitle)
                ? (_openBlock(), _createBlock(_component_ion_col, {
                    key: 1,
                    class: "card__subtitle ion-text-right",
                    size: "4"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, [
                        (_ctx.card.subtitleHeader)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString(_ctx.card.subtitleHeader), 1))
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(_ctx.card.subtitle ? _ctx.card.subtitle : '-'), 1)
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}