import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "entity-selection" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_loading_button = _resolveComponent("loading-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.selectedEntity)
      ? (_openBlock(), _createBlock(_component_ion_title, {
          key: 0,
          class: "font-size--xl primary-dark-color"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_fa_icon, {
              class: "ion-margin-end",
              icon: _ctx.selectedEntity.icon
            }, null, 8, ["icon"]),
            _createTextVNode(" " + _toDisplayString(_ctx.$t(_ctx.selectedEntity.label)), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.disabled)
      ? (_openBlock(), _createBlock(_component_ion_buttons, {
          key: 1,
          slot: "end",
          class: "ion-margin-horizontal"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_loading_button, {
              fill: "clear",
              class: "ion-margin-horizontal",
              color: "primary",
              promise: _ctx.changeEntity
            }, {
              default: _withCtx(() => [
                _createVNode(_component_fa_icon, { icon: ['far', 'edit'] })
              ]),
              _: 1
            }, 8, ["promise"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}