import { LoginType } from '@/app/views/login/login-module.model'
import { store } from '@api/lib'

export class LoginRecoverPasswordEmailSentClass {
    public async showRecoverPassword(): Promise<void> {
        await store.dispatch('login/changeLoginType', LoginType.RECOVER_PASSWORD)
    }

    public async showLogin(): Promise<void> {
        await store.dispatch('login/changeLoginType', LoginType.LOGIN)
    }
}
