import { axios } from '@vitro/anapath-frontend-library-api'
import { CommonEntityIdentifiersListRequestCommonPortionFamilyTreeRequest } from '@api/services/common.dto'
import { EntityIdentifierWithErrorResponse } from '@/app/core/models/http.model'
import {
    ArchiveEntitySearchResponseArchivePortionGridResponseSearchPortionResponse,
    ArchiveRequestCommonPortionFamilyTreeRequest,
    UnarchiveRequestCommonPortionFamilyTreeRequest
} from '@api/services/archive/portion/archive-portion.dto'
import { ArchivePortionGridResponse } from '@api/services/archive/portion-filter/archive-portion-filter.dto'

class ArchivePortionService {
    public async searchByLabel(
        laboratoryId: number,
        portionLabel: string
    ): Promise<ArchiveEntitySearchResponseArchivePortionGridResponseSearchPortionResponse[]> {
        const response =
            await axios.get<ArchiveEntitySearchResponseArchivePortionGridResponseSearchPortionResponse[]>(
                `/archive/laboratories/${laboratoryId}/cases/samples/portions/searchByLabel`,
                { params: { portionLabel }})
        return response.data
    }

    public async updateLocation(
        laboratoryId: number,
        caseId: number,
        sampleId: number,
        portionId: number,
        archivingLocation: string
    ): Promise<ArchivePortionGridResponse> {
        const response =
            await axios.patch<ArchivePortionGridResponse>(
                `/archive/laboratories/${laboratoryId}/cases/${caseId}/samples/${sampleId}/portions/${portionId}/updateLocation`,
                undefined,
                { params: { archivingLocation }})
        return response.data
    }

    public async unarchivePendingToBeUnarchived(
        laboratoryId: number,
        request: CommonEntityIdentifiersListRequestCommonPortionFamilyTreeRequest
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        const response =
            await axios.put<EntityIdentifierWithErrorResponse[]>(
                `/archive/laboratories/${laboratoryId}/cases/samples/portions/unarchivePendingToBeUnarchived`,
                request)
        return response.data
    }

    public async takeOutFromLaboratory(
        laboratoryId: number,
        request: UnarchiveRequestCommonPortionFamilyTreeRequest[]
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        const response =
            await axios.put<EntityIdentifierWithErrorResponse[]>(
                `/archive/laboratories/${laboratoryId}/cases/samples/portions/takeOutFromLaboratory`,
                request)
        return response.data
    }

    public async archive(
        laboratoryId: number,
        request: ArchiveRequestCommonPortionFamilyTreeRequest[]
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        const response =
            await axios.put<EntityIdentifierWithErrorResponse[]>(
                `/archive/laboratories/${laboratoryId}/cases/samples/portions/archive`,
                request)
        return response.data
    }
}
export const archivePortionService = new ArchivePortionService()
