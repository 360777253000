export enum PortionOperation {
    CREATE = 'operation.entity.portion.create',
    EDIT = 'operation.entity.portion.edit',
    DELETE = 'operation.entity.portion.delete',
    PRINT_CASSETTE = 'operation.entity.portion.printcassette',
    ATTACH = 'operation.entity.portion.attach',
    ARCHIVE = 'operation.entity.portion.archive',
    UNARCHIVE_TO_CUT = 'operation.entity.portion.unarchive-to-cut',
    UNARCHIVE_TO_REMAKE = 'operation.entity.portion.unarchive-to-remake',
    TAKE_OUT_FROM_LABORATORY = 'operation.entity.portion.take-out-from-laboratory',
    TO_TISSUE_PROCESSOR = 'operation.entity.portion.to-tissue-processor',
    MAKE = 'operation.entity.portion.make',
    PROCESS = 'operation.entity.portion.process',
    CUT = 'operation.entity.portion.cut',
    UNDO = 'operation.entity.portion.undo',
    REQUEST_REMAKE = 'operation.entity.portion.request-remake',
    CANCEL = 'operation.entity.portion.cancel'
}
