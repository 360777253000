import { EmitFn, store } from '@api/lib'
import {
    ArchivePendingToBeUnArchivedFilterActionPendingUnArchivingReasonEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/filter/action/components/pending-un-archiving-reason/archive-pending-to-be-un-archived-filter-action-pending-un-archiving-reason.model'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { ArchiveEntityDatasource } from '@/app/views/modules/components/archive/datasources/entities/archive-entity.datasource'
import { PendingUnarchivingReasonResponse } from '@api/services/archive/archive.dto'
import { StrictNullable } from '@/app/core/types/app-types'

export class ArchivePendingToBeUnArchivedFilterActionPendingUnArchivingReasonClass {
    private readonly _emit: EmitFn<ArchivePendingToBeUnArchivedFilterActionPendingUnArchivingReasonEmitEvent>
    private _reasons: PendingUnarchivingReasonResponse[]
    private _reason: StrictNullable<PendingUnarchivingReasonResponse>
    private _selectedReason: StrictNullable<PendingUnarchivingReasonResponse>

    public get reasons(): PendingUnarchivingReasonResponse[] {
        return this._reasons
    }
    public get selectedReason(): StrictNullable<PendingUnarchivingReasonResponse> {
        return this._selectedReason
    }

    constructor(emit: EmitFn<ArchivePendingToBeUnArchivedFilterActionPendingUnArchivingReasonEmitEvent>) {
        this._emit = emit
        this._reasons = []
        this._reason = null
        this._selectedReason = null
    }

    public selectReason(reason: PendingUnarchivingReasonResponse): void {
        this._selectedReason = reason
    }

    private static async _loadReasons(datasource: ArchiveEntityDatasource): Promise<PendingUnarchivingReasonResponse[]> {
        const response =
            await PromiseUtil.toCallSecure(
                datasource.getPendingUnArchivingReasons())
        return response ?? []
    }
    public async loadData(
        datasource: ArchiveEntityDatasource,
        reasonCode: StrictNullable<PendingUnarchivingReasonResponse['code']>
    ): Promise<void> {
        this._reasons =
            await ArchivePendingToBeUnArchivedFilterActionPendingUnArchivingReasonClass._loadReasons(datasource)
        if (reasonCode) {
            const reason = this._reasons.find(r => r.code === reasonCode)
            if (reason) {
                this._reason = reason
                this.selectReason(reason)
                await store.dispatch('appModules/archive/pendingToBeUnArchived/changePendingUnArchivingReason', reason)
            }
        }
    }

    public clearFilters(): void {
        this._emit(ArchivePendingToBeUnArchivedFilterActionPendingUnArchivingReasonEmitEvent.CLEAR)
        this.resetFilter()
    }

    public filter(): void {
        this._reason = this._selectedReason
        this._emit(ArchivePendingToBeUnArchivedFilterActionPendingUnArchivingReasonEmitEvent.FILTERED, this._selectedReason)
    }

    public resetFilter(): void {
        this._selectedReason = null
        this._reason = null
    }
}
