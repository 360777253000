import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_archive_location_input = _resolveComponent("archive-location-input")!
  const _component_cards_list_filter = _resolveComponent("cards-list-filter")!

  return (_openBlock(), _createBlock(_component_cards_list_filter, {
    icon: ['far', 'location'],
    title: _ctx.$t('anapathapp.label.filterByArchiveLocation'),
    onClear: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearFilters())),
    onFiltered: _cache[2] || (_cache[2] = ($event: any) => (_ctx.filter()))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_archive_location_input, {
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.locationChanged($event)))
      })
    ]),
    _: 1
  }, 8, ["title"]))
}