import { EmitFn } from '@api/lib'
import { ColDef, CommonSortModelResource, SortType } from '@/app/shared/models/common.model'

export interface SortTypeModel {
    code: SortType
    label: string
}

export enum CardsListSortEmitEvent {
    SORTED = 'sorted'
}

export interface CardsListSortParams {
    emit: EmitFn<CardsListSortEmitEvent>

    colDefs: ColDef[]
    sortModel: CommonSortModelResource
}
