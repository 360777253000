import { axios } from '@vitro/anapath-frontend-library-api'
import { CommonEntityIdentifiersListRequestCommonSubportionFamilyTreeRequest } from '@api/services/common.dto'
import { EntityIdentifierWithErrorResponse } from '@/app/core/models/http.model'
import { ArchiveSubportionGridResponse } from '@api/services/archive/subportion-filter/archive-subportion-filter.dto'
import {
    ArchiveEntitySearchResponseArchiveSubportionGridResponseSearchSubportionResponse,
    ArchiveRequestCommonSubportionFamilyTreeRequest,
    UnarchiveRequestCommonSubportionFamilyTreeRequest
} from '@api/services/archive/subportion/archive-subportion.dto'

class ArchiveSubportionService {
    public async searchByLabel(
        laboratoryId: number,
        subportionLabel: string
    ): Promise<ArchiveEntitySearchResponseArchiveSubportionGridResponseSearchSubportionResponse[]> {
        const response =
            await axios.get<ArchiveEntitySearchResponseArchiveSubportionGridResponseSearchSubportionResponse[]>(
                `/archive/laboratories/${laboratoryId}/cases/samples/portions/subportions/searchByLabel`,
                { params: { subportionLabel }})
        return response.data
    }

    public async updateLocation(
        laboratoryId: number,
        caseId: number,
        sampleId: number,
        portionId: number,
        subportionId: number,
        archivingLocation: string
    ): Promise<ArchiveSubportionGridResponse> {
        const response =
            await axios.patch<ArchiveSubportionGridResponse>(
                `/archive/laboratories/${laboratoryId}/cases/${caseId}/samples/${sampleId}/portions/${portionId}/subportions/${subportionId}/updateLocation`,
                undefined,
                { params: { archivingLocation }})
        return response.data
    }

    public async unarchivePendingToBeUnarchived(
        laboratoryId: number,
        request: CommonEntityIdentifiersListRequestCommonSubportionFamilyTreeRequest
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        const response =
            await axios.put<EntityIdentifierWithErrorResponse[]>(
                `/archive/laboratories/${laboratoryId}/cases/samples/portions/subportions/unarchivePendingToBeUnarchived`,
                request)
        return response.data
    }

    public async takeOutFromLaboratory(
        laboratoryId: number,
        request: UnarchiveRequestCommonSubportionFamilyTreeRequest[]
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        const response =
            await axios.put<EntityIdentifierWithErrorResponse[]>(
                `/archive/laboratories/${laboratoryId}/cases/samples/portions/subportions/takeOutFromLaboratory`,
                request)
        return response.data
    }

    public async archive(
        laboratoryId: number,
        request: ArchiveRequestCommonSubportionFamilyTreeRequest[]
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        const response =
            await axios.put<EntityIdentifierWithErrorResponse[]>(
                `/archive/laboratories/${laboratoryId}/cases/samples/portions/subportions/archive`,
                request)
        return response.data
    }
}
export const archiveSubportionService = new ArchiveSubportionService()
