import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-96463f44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pending-un-archiving-reason-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_cards_list_filter = _resolveComponent("cards-list-filter")!

  return (_openBlock(), _createBlock(_component_cards_list_filter, {
    icon: ['far', 'comment-dots'],
    title: _ctx.$t('anapathapp.label.filterByRemoveReason'),
    onClear: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearFilters())),
    onFiltered: _cache[2] || (_cache[2] = ($event: any) => (_ctx.filter())),
    onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.resetFilter())),
    onShow: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onShow()))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_list, { lines: "full" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_radio_group, {
              value: _ctx.selectedReason,
              onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectReason($event.detail.value)))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reasons, (reason) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: reason.code
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(reason.name), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_radio, {
                        slot: "end",
                        value: reason
                      }, null, 8, ["value"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}