import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ba083aac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-margin-vertical ion-padding-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_searcher = _resolveComponent("searcher")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_cards_empty_state = _resolveComponent("cards-empty-state")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { mode: "md" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, { class: "font-size--xl primary-dark-color" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                slot: "end",
                class: "ion-margin-horizontal",
                fill: "clear",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goBack()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_fa_icon, { icon: ['far', 'times'] })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.visibleList)
        ? (_openBlock(), _createBlock(_component_ion_content, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-margin" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_searcher, {
                        id: "archive-add",
                        "error-message": _ctx.$t('anapathapp.archive.message.validIdentifier'),
                        placeholder: _ctx.$t('global.label.identifier'),
                        "sample-searching": _ctx.isSampleEntity,
                        "portion-searching": _ctx.isPortionEntity,
                        "subportion-searching": _ctx.isSubportionEntity,
                        onSampleSearch: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSampleSearch($event))),
                        onPortionSearch: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onPortionSearch($event))),
                        onSubportionSearch: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSubportionSearch($event)))
                      }, null, 8, ["error-message", "placeholder", "sample-searching", "portion-searching", "subportion-searching"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createElementVNode("div", null, [
                (!_ctx.cards.length)
                  ? (_openBlock(), _createBlock(_component_cards_empty_state, {
                      key: 0,
                      title: _ctx.$t('anapathapp.label.withoutElements'),
                      subtitle: _ctx.$t('anapathapp.label.enterIdentifierOrUseScanner')
                    }, null, 8, ["title", "subtitle"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createVNode(_component_ion_title, { class: "font-size--xl primary-dark-color ion-padding-top ion-margin-start" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.cards.length) + " " + _toDisplayString(_ctx.$t(_ctx.listTitle, _ctx.cards.length)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_list, { lines: "full" }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card) => {
                            return (_openBlock(), _createBlock(_component_ion_item, {
                              key: card.title
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { class: "ion-margin-start" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(card.title), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_button, {
                                  slot: "end",
                                  fill: "clear",
                                  color: "danger",
                                  onClick: ($event: any) => (_ctx.deselectCard(card))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_fa_icon, { icon: ['far', 'minus-circle'] })
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ], 64))
              ])
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_ion_content, { key: 1 }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "operations", {}, undefined, true)
            ]),
            _: 3
          })),
      _createVNode(_component_ion_footer, { class: "ion-no-border" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "ion-margin" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    (_ctx.visibleStepper)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 0,
                          color: "medium",
                          class: "ion-margin-bottom",
                          expand: "block",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleVisibleList()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.toggleVisibleLabel), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.visibleActions)
                      ? _renderSlot(_ctx.$slots, "actions", { key: 1 }, undefined, true)
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 3
              })
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}