import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_searcher = _resolveComponent("searcher")!

  return (_openBlock(), _createBlock(_component_searcher, {
    id: "location-input",
    "error-message": _ctx.$t('anapathapp.searcher.message.validIdentifier'),
    placeholder: _ctx.$t('anapathapp.label.withoutArchiveLocation'),
    "close-after-search": "",
    "location-searching": "",
    "search-on-blur": "",
    onClearSearch: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onLocationClear())),
    onLocationSearch: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onLocationSearch($event)))
  }, null, 8, ["error-message", "placeholder"]))
}