import { computed, defineComponent, PropType, reactive } from 'vue'
import { Card } from '@/app/shared/components/cards/card/card.model'
import { CardClass } from '@/app/shared/components/cards/card/card-class'

export default defineComponent({
    name: 'Card',
    props: {
        card: { type: Object as PropType<Card>, required: true },
        selectable: { type: Boolean, default: true }
    },
    setup(props) {
        const _class = reactive(new CardClass(props.selectable))
        return {
            /// COMPUTED
            hasSubtitle: computed(() => !!props.card.subtitleHeader || props.card.subtitle),
            /// METHODS
            select: () => _class.select(props.card)
        }
    }
})
