import { VuePlugin } from '@/app'
import { App } from 'vue'
import {
    IonAvatar,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonChip,
    IonCol,
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonModal,
    IonPage,
    IonPopover,
    IonRadio,
    IonRadioGroup,
    IonRefresher,
    IonRefresherContent,
    IonRippleEffect,
    IonRouterOutlet,
    IonRow,
    IonSearchbar,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonSlide,
    IonSlides,
    IonSpinner,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonTitle,
    IonToolbar
} from '@ionic/vue'
import LoadingButton from '@/app/core/components/loading-button/loading-button.vue'
import LoadingView from '@/app/core/components/loading-view/loading-view.vue'

class IonicPlugin implements VuePlugin {
    /** Global Ionic Component Registration */
    private static _registerIonicComponents(app: App<Element>): void {
        app.component('IonAvatar', IonAvatar)
        app.component('IonButton', IonButton)
        app.component('IonButtons', IonButtons)
        app.component('IonCard', IonCard)
        app.component('IonCardContent', IonCardContent)
        app.component('IonCheckbox', IonCheckbox)
        app.component('IonChip', IonChip)
        app.component('IonCol', IonCol)
        app.component('IonContent', IonContent)
        app.component('IonFab', IonFab)
        app.component('IonFabButton', IonFabButton)
        app.component('IonGrid', IonGrid)
        app.component('IonHeader', IonHeader)
        app.component('IonIcon', IonIcon)
        app.component('IonInfiniteScroll', IonInfiniteScroll)
        app.component('IonInfiniteScrollContent', IonInfiniteScrollContent)
        app.component('IonInput', IonInput)
        app.component('IonItem', IonItem)
        app.component('IonLabel', IonLabel)
        app.component('IonList', IonList)
        app.component('IonListHeader', IonListHeader)
        app.component('IonMenu', IonMenu)
        app.component('IonMenuToggle', IonMenuToggle)
        app.component('IonModal', IonModal)
        app.component('IonPage', IonPage)
        app.component('IonPopover', IonPopover)
        app.component('IonRadio', IonRadio)
        app.component('IonRadioGroup', IonRadioGroup)
        app.component('IonRefresher', IonRefresher)
        app.component('IonRefresherContent', IonRefresherContent)
        app.component('IonRippleEffect', IonRippleEffect)
        app.component('IonRow', IonRow)
        app.component('IonRouterOutlet', IonRouterOutlet)
        app.component('IonSearchbar', IonSearchbar)
        app.component('IonSegment', IonSegment)
        app.component('IonSegmentButton', IonSegmentButton)
        app.component('IonSelect', IonSelect)
        app.component('IonSelectOption', IonSelectOption)
        app.component('IonSlide', IonSlide)
        app.component('IonSlides', IonSlides)
        app.component('IonSpinner', IonSpinner)
        app.component('IonTabBar', IonTabBar)
        app.component('IonTabButton', IonTabButton)
        app.component('IonTabs', IonTabs)
        app.component('IonTitle', IonTitle)
        app.component('IonToolbar', IonToolbar)
    }

    /** Global App Component Registration */
    private static _registerAppComponents(app: App<Element>): void {
        app.component('LoadingButton', LoadingButton)
        app.component('LoadingView', LoadingView)
    }

    public register(app: App<Element>): void {
        IonicPlugin._registerIonicComponents(app)
        IonicPlugin._registerAppComponents(app)
    }
}
export const ionicPlugin = new IonicPlugin()

/**
 * WORKAROUND: added some Interfaces until ionic v6 update
 * https://github.com/ionic-team/ionic-framework/issues/22925
 * https://forum.ionicframework.com/t/how-can-i-use-infinite-scroll-with-typescript/213900/3
 */

export interface InfiniteScrollCustomEvent extends CustomEvent {
    target: HTMLIonInfiniteScrollElement
}

export interface RefresherEventDetail {
    complete(): void
}
export interface RefresherCustomEvent extends CustomEvent {
    detail: RefresherEventDetail
    target: HTMLIonRefresherElement
}
