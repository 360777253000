import { StrictNullable } from '@/app/core/types/app-types'
import moment from 'moment'
import { modalController } from '@api/lib/overlay'
import { auth } from '@api/auth'
import { store } from '@api/lib'
import UserDowntime from '@/app/core/components/user-downtime/user-downtime.vue'

class UserDowntimeClass {
    private static readonly _INTERVAL = 1000

    private _countDownInterval: StrictNullable<number>
    private _duration: StrictNullable<moment.Duration>

    public get countDownMessage(): string {
        if (this._duration) {
            return `${this._duration.hours()}h:${this._duration.minutes()}m:${this._duration.seconds()}s`
        }
        return ''
    }

    constructor() {
        this._countDownInterval = null
        this._duration = null
    }

    private static async _hide(): Promise<void> {
        await modalController.dismiss()
    }

    public cancelCountDownInterval(): void {
        if (this._countDownInterval) {
            clearInterval(this._countDownInterval)
        }
    }

    public async continueLogged(): Promise<void> {
        await auth.resetUserDowntimeTimeout()
        this.cancelCountDownInterval()
        await UserDowntimeClass._hide()
    }

    public async logOut(): Promise<void> {
        await UserDowntimeClass._hide()
        await auth.logOut()
    }

    private async _countDown(): Promise<void> {
        if (this._duration) {
            this._duration = moment.duration(this._duration.asMilliseconds() - UserDowntimeClass._INTERVAL, 'milliseconds')

            if (!store.state.userLoggedIn || this._duration.asSeconds() <= 0) {
                this._duration = null
                if (this._countDownInterval) {
                    clearInterval(this._countDownInterval)
                    this._countDownInterval = null
                }
                await this.logOut()
            }
        }
    }

    public async show(timeToDowntimeInSeconds: number): Promise<void> {
        this._duration = moment.duration(timeToDowntimeInSeconds * 1000)

        const modal = await modalController.create({
            mode: 'md',
            cssClass: 'user-downtime__modal',
            component: UserDowntime,
            backdropDismiss: false,
            swipeToClose: false,
            keyboardClose: false
        })
        await modal.present()
    }

    public initialize(): void {
        this._countDownInterval = setInterval(() => this._countDown(), UserDowntimeClass._INTERVAL)
    }
}
export const userDowntimeClass = new UserDowntimeClass()
