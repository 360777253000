import { Card } from '@/app/shared/components/cards/card/card.model'
import { InfiniteCardDatasource } from '@/app/shared/resources/datasources/inifinite-card.datasource'
import {
    ArchiveModuleSectionType
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'
import {
    ArchiveEntityArchiveRequest,
    ArchiveEntityFamilyTreeRequest,
    ArchiveEntityIdentifiersListRequest,
    ArchiveEntityResponse,
    ArchiveEntitySearchResponse,
    ArchiveEntityUnarchiveRequest
} from '@/app/views/modules/components/archive/datasources/archive-datasource-factory'
import { i18n, store } from '@api/lib'
import { Optional, StrictNullable } from '@/app/core/types/app-types'
import { PendingUnarchivingReasonResponse } from '@api/services/archive/archive.dto'
import { EntityIdentifierWithErrorResponse } from '@/app/core/models/http.model'
import { CommonFilterModel } from '@/app/shared/models/common.model'
import {
    ModulesArchivePendingToBeUnArchivedStoreState
} from '@/app/views/modules/store/modules/archive/modules/pending-to-be-un-archived/modules-archive-pending-to-be-un-archived-store-state.model'
import { ModulesLinkUtil } from '@/app/views/modules/shared/utils/modules-link-util'
import { CombinedFilterModel } from '@/app/shared/models/ag-grid.model'

export abstract class ArchiveEntityDatasource<
    AER extends ArchiveEntityResponse = ArchiveEntityResponse,
    AEILR extends ArchiveEntityIdentifiersListRequest = ArchiveEntityIdentifiersListRequest,
    AEAR extends ArchiveEntityArchiveRequest = ArchiveEntityArchiveRequest,
    AEUR extends ArchiveEntityUnarchiveRequest = ArchiveEntityUnarchiveRequest
> extends InfiniteCardDatasource<AER, ArchiveEntitySearchResponse> {
    protected _sectionType: ArchiveModuleSectionType

    private get _pendingUnArchivingReason(): ModulesArchivePendingToBeUnArchivedStoreState['pendingUnArchivingReason'] {
        return store.getters['appModules/archive/pendingToBeUnArchived/pendingUnArchivingReason']
    }
    private get _locations(): ModulesArchivePendingToBeUnArchivedStoreState['locations'] {
        return store.getters['appModules/archive/pendingToBeUnArchived/locations']
    }

    private _pendingUnArchivingReasonFilterModel(): CommonFilterModel {
        return ModulesLinkUtil.getPendingUnArchivingReasonFilterModel(this._pendingUnArchivingReason?.code)
    }
    private get _locationFilterModel(): CommonFilterModel | CombinedFilterModel {
        return ModulesLinkUtil.getLocationFilterModel(this._locations)
    }

    public get filterModel(): StrictNullable<CommonFilterModel | CombinedFilterModel> {
        return {
            ...this._filterModel,
            ...this._pendingUnArchivingReasonFilterModel,
            ...this._locationFilterModel
        }
    }

    public get totalElements(): number {
        return this._totalElements
    }

    protected constructor(sectionType: ArchiveModuleSectionType) {
        super()
        this._sectionType = sectionType
    }

    public async changeSectionType(sectionType: ArchiveModuleSectionType): Promise<void> {
        this._sectionType = sectionType
        this._filterModel = null
        await store.dispatch('appModules/archive/pendingToBeUnArchived/reset')
        await this.reloadData()
    }

    protected _createCard(element: AER): Card<AER> {
        let subtitleHeader: Optional<string> = undefined
        if (this._sectionType === ArchiveModuleSectionType.PENDING_TO_BE_ARCHIVED) {
            subtitleHeader = i18n.t('anapathapp.label.archiveLocation')
        } else if (this._sectionType === ArchiveModuleSectionType.PENDING_TO_BE_UN_ARCHIVED) {
            subtitleHeader = i18n.t('anapathapp.label.archiveLocation')
        }
        return {
            data: element,
            selected: false,
            title: element.label,
            subtitleHeader,
            subtitle: element.archivingLocation
        }
    }

    protected _createCardFromSearch(element: ArchiveEntitySearchResponse): Card<AER> {
        return this._createCard(element.foundEntity as AER)
    }

    public abstract getPendingUnArchivingReasons(): Promise<PendingUnarchivingReasonResponse[]>
    public abstract updateLocation(
        archivingLocation: string,
        laboratoryId: number,
        caseId: number,
        sampleId: number,
        portionId?: number,
        subportionId?: number
    ): Promise<AER>

    public abstract getEntityHierarchicalIds(entity: AER): ArchiveEntityFamilyTreeRequest
    public abstract unarchivePendingToBeUnarchived(
        laboratoryId: number,
        request: AEILR
    ): Promise<EntityIdentifierWithErrorResponse[]>
    public abstract archive(
        laboratoryId: number,
        request: AEAR[]
    ): Promise<EntityIdentifierWithErrorResponse[]>
    public abstract takeOutFromLaboratory(
        laboratoryId: number,
        request: AEUR[]
    ): Promise<EntityIdentifierWithErrorResponse[]>
}
