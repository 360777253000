import { RouteRecordRaw } from 'vue-router'

export enum WizardRoute {
    WIZARD = 'Wizard'
}

export const wizardRouter =
    (): Record<WizardRoute, RouteRecordRaw> => ({
        [WizardRoute.WIZARD]: {
            path: '/wizard',
            name: WizardRoute.WIZARD,
            meta: { requiresAuth: true },
            component: () => import('@/app/views/wizard/wizard.vue'),
            props: true
        }
    })
