export enum SearcherEvent {
    CLEAR = 'clearSearch'
}

export enum SearchInformationEvent {
    STUDY = 'studySearch',
    PATIENT_NIF = 'patientNIFSearch',
    PATIENT_NHC = 'patientNHCSearch',
    REQUEST = 'requestSearch',
    SUBREQUEST = 'subRequestSearch',
    SAMPLE = 'sampleSearch',
    PORTION = 'portionSearch',
    SUBPORTION = 'subportionSearch',
    INCIDENT = 'incidentSearch',
    SHIPMENT = 'shipmentSearch',
    LOCATION = 'locationSearch'
}

export type SearcherEmitEvent = SearcherEvent | SearchInformationEvent

export interface SearcherParams {
    errorMessage: string
    searchOnBlur: boolean
    resetAfterSearch: boolean
    closeAfterSearch: boolean

    /// searching types
    patientSearching: boolean
    requestSearching: boolean
    subrequestSearching: boolean
    caseSearching: boolean
    sampleSearching: boolean
    portionSearching: boolean
    subportionSearching: boolean
    locationSearching: boolean
}

export interface SearchInformation {
    identifierType: string
    search: string
}

export interface SearchData {
    searchEvent: SearchInformationEvent
    searchInformation: SearchInformation
    timestamp: Date
}
