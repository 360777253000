import { EntitySelectorType } from '@/app/shared/components/selector/entity/entity-selector.model'

export enum ArchivePendingToBeArchivedAddOperationEmitEvent {
    GO_BACK = 'goBack',
    TAKE_IN = 'takeIn'
}

export interface ArchivePendingToBeArchivedAddOperationParams {
    entityType: EntitySelectorType
}
