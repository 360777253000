import { axios } from '@vitro/anapath-frontend-library-api'
import { CommonFilterModel, CommonParams, Page } from '@/app/shared/models/common.model'
import { PendingUnarchivingReasonResponse } from '@api/services/archive/archive.dto'
import {
    ArchiveSampleFilterPaginatorType,
    ArchiveSampleGridResponse
} from '@api/services/archive/sample-filter/archive-sample-filter.dto'

class ArchiveSampleFilterService {
    public async getSamplePendingUnArchivingReasons(): Promise<PendingUnarchivingReasonResponse[]> {
        const response =
            await axios.get<PendingUnarchivingReasonResponse[]>(
                `/archive/laboratories/samplePendingUnarchivingReasons`)
        return response.data
    }

    public async paginator(
        laboratoryId: number,
        url: ArchiveSampleFilterPaginatorType,
        filterModel?: CommonFilterModel,
        params?: CommonParams
    ): Promise<Page<ArchiveSampleGridResponse>> {
        const response =
            await axios.put<Page<ArchiveSampleGridResponse>>(
                `/archive/laboratories/${laboratoryId}/cases/samples/${url}`,
                { ...filterModel },
                { params })
        return response.data
    }
}
export const archiveSampleFilterService = new ArchiveSampleFilterService()
