import { computed, defineComponent, reactive } from 'vue'
// import AvatarCropper from 'vue-avatar-cropper'
import { ProfileUserInfoClass } from '@/app/views/modules/components/profile/components/user-info/profile-user-info.class'

export default defineComponent({
    name: 'ProfileUserInfo',
    // components: { AvatarCropper },
    setup() {
        const _class = reactive(new ProfileUserInfoClass())
        return {
            /// COMPUTED
            userLastProfileImage: computed(() => _class.userLastProfileImage),
            fullName: computed(() => _class.fullName),
            job: computed(() => _class.job),
            penultimateAccess: computed(() => _class.penultimateAccess),
            /// METHODS
            // changeUserProfileImage: () => _class.changeUserProfileImage(),
            deleteUserProfileImage: () => _class.deleteUserProfileImage()
        }
    }
})
