import { EmitFn } from '@api/lib'
import {
    TakeOutReasonSelectorEmitEvent,
    TakeOutReasonSelectorParams
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/take-out-reason-selector/take-out-reason-selector.model'
import { CommonUnarchivingReasonResponse } from '@api/services/archive/common/archive-common.dto'
import { archiveCommonService } from '@api/services/archive/common/archive-common.service'
import { StrictNullable } from '@/app/core/types/app-types'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { StoreUtil } from '@/app/core/utils/lib/store-util'

export class TakeOutReasonSelectorClass {
    private readonly _emit: EmitFn<TakeOutReasonSelectorEmitEvent>
    private _reasons: CommonUnarchivingReasonResponse[]
    private _selectedReason: StrictNullable<CommonUnarchivingReasonResponse>

    public get reasons(): CommonUnarchivingReasonResponse[] {
        return this._reasons
    }
    public get selectedReason(): StrictNullable<CommonUnarchivingReasonResponse> {
        return this._selectedReason
    }

    constructor(params: TakeOutReasonSelectorParams) {
        this._emit = params.emit
        this._selectedReason = params.reason
        this._reasons = []
    }

    public async loadReasons(): Promise<void> {
        const response =
            await PromiseUtil.toCallSecure(
                archiveCommonService.getUnArchivingReasons(StoreUtil.labId))
        this._reasons = response ?? []
    }

    public selectReason(reasonId: CommonUnarchivingReasonResponse['id']): void {
        const reason = this._reasons.find(r => r.id === reasonId) ?? null
        this._selectedReason = reason
        this._emit(TakeOutReasonSelectorEmitEvent.CHANGE, reason)
    }
}
