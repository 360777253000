import { EmitFn, i18n } from '@api/lib'
import {
    ArchivePendingToBeArchivedActionsEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-archived/actions/archive-pending-to-be-archived-actions.model'
import {
    ArchiveModuleSectionType,
    getArchiveSectionTypes
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'
import {
    EntitySelectorType,
    getEntitySelectorTypes
} from '@/app/shared/components/selector/entity/entity-selector.model'

export class ArchivePendingToBeArchivedActionsClass {
    private readonly _emit: EmitFn<ArchivePendingToBeArchivedActionsEmitEvent>

    private _getSectionTypeTitle(sectionType: ArchiveModuleSectionType): string {
        const _sectionType =
            getArchiveSectionTypes().find(
                archiveSectionType => archiveSectionType.code === sectionType)
        return _sectionType ? i18n.t(_sectionType.label) : ''
    }
    private _getEntityTypeTitle(entityType: EntitySelectorType): string {
        const _entityType =
            getEntitySelectorTypes().find(
                entitySelectorType => entitySelectorType.code === entityType)
        return _entityType ? i18n.t(_entityType.label) : ''
    }
    public getTitle(sectionType: ArchiveModuleSectionType, entityType: EntitySelectorType): string {
        return `${this._getSectionTypeTitle(sectionType)} ${this._getEntityTypeTitle(entityType)}`
    }

    constructor(emit: EmitFn<ArchivePendingToBeArchivedActionsEmitEvent>) {
        this._emit = emit
    }

    public takeIn(): void {
        this._emit(ArchivePendingToBeArchivedActionsEmitEvent.REFRESH)
    }
}
