import { StrictNullable } from '@/app/core/types/app-types'
import { ToastUtil } from '@/app/core/utils/lib/overlay/toast-util'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { i18n } from '@api/lib'
import { PasswordClass } from '@api/password.class'
import { authService } from '@vitro/anapath-frontend-library-api'

export class ProfileChangePasswordClass extends PasswordClass {
    private _validatePassword(currentPassword: StrictNullable<string>, password1: StrictNullable<string>, password2: StrictNullable<string>): boolean {
        if (!currentPassword) {
            this._errorMessage = i18n.t('anapathapp.modal.changePassword.label.enterCurrentPassword')
            return false
        }
        return super.validatePassword(password1, password2)
    }

    public async changePassword(currentPassword: StrictNullable<string>, password1: StrictNullable<string>, password2: StrictNullable<string>): Promise<void> {
        this._invalid = false
        this._errorMessage = ''

        if (this._validatePassword(currentPassword, password1, password2)) {
            if (currentPassword && password1) {
                try {
                    this._loading = true

                    const response =
                        await PromiseUtil.toCallSecure(
                            authService.changePassword({
                                oldPassword: currentPassword,
                                newPassword: password1
                            }),
                            error => super.errorCallback(error))
                    if (response !== null) {
                        await ToastUtil.showSuccess(i18n.t('anapathapp.modal.changePassword.message.successfulPasswordChange'))
                    }
                } finally {
                    this._loading = false
                }
            }
        } else {
            await ToastUtil.showError(this._errorMessage)
        }
    }
}
