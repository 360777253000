import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e788808"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "searcher searcher-searchbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_code_reader = _resolveComponent("code-reader")!
  const _component_ion_page = _resolveComponent("ion-page")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_item, {
      class: "searcher-item",
      mode: "md",
      lines: "none"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_searchbar, {
          id: _ctx.id,
          modelValue: _ctx.search,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.search = $event)),
          class: "searcher-input_searchbar",
          placeholder: _ctx.placeholder,
          debounce: 0,
          onIonClear: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearSearch())),
          onIonFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onFocus())),
          onIonBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onBlur())),
          onSearch: _cache[4] || (_cache[4] = ($event: any) => (_ctx.doSearch()))
        }, null, 8, ["id", "modelValue", "placeholder"]),
        _createVNode(_component_ion_button, {
          slot: "end",
          fill: "clear",
          class: "scan-button",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showCodeReader()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_fa_icon, { icon: ['far', 'barcode-alt'] })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_modal, {
      mode: "md",
      "is-open": _ctx.visibleCodeReader,
      "css-class": "searcher-modal",
      component: "",
      onDidDismiss: _cache[8] || (_cache[8] = ($event: any) => (_ctx.hideCodeReader()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_page, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  slot: "start",
                  fill: "clear",
                  color: "primary",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.hideCodeReader()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_fa_icon, { icon: ['far', 'arrow-left'] })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_title, { class: "font-size--xl primary-dark-color ion-no-padding" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.label.scanElement')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_code_reader, {
              onDecoded: _cache[7] || (_cache[7] = ($event: any) => (_ctx.doSearch($event)))
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-open"])
  ]))
}