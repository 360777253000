import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!

  return (_openBlock(), _createBlock(_component_ion_chip, { class: "filter-chip" }, {
    default: _withCtx(() => [
      _createVNode(_component_fa_icon, {
        class: "filter-chip-icon",
        icon: ['far', _ctx.filterIcon]
      }, null, 8, ["icon"]),
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.filterLabel), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_fa_icon, {
        class: "ion-margin-start",
        icon: ['far', 'times'],
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeFilter()))
      })
    ]),
    _: 1
  }))
}