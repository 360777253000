import { Module } from 'vuex'
import { RootStoreState } from '@/app/plugins/vuex/store/root/root-store-state.model'
import { SessionStoreState } from '@/app/plugins/vuex/store/root/modules/session/session-store-state.model'

const initialSessionStoreState =
    (): SessionStoreState => ({
        locale: null,
        auth: null,
        labId: null
    })

export const sessionStoreModule: Module<SessionStoreState, RootStoreState> = {
    namespaced: true,
    state: initialSessionStoreState,
    getters: {
        locale:
            (state): SessionStoreState['locale'] => state.locale,
        auth:
            (state): SessionStoreState['auth'] => state.auth,
        labId:
            (state): SessionStoreState['labId'] => state.labId
    },
    mutations: {
        resetState:
            (state): void => {
                Object.assign(state, initialSessionStoreState())
            },

        updateLocale:
            (state, payload: SessionStoreState['locale']): void => {
                state.locale = payload
            },
        updateAuth:
            (state, payload: SessionStoreState['auth']): void => {
                state.auth = payload
            },
        updateLabId:
            (state, payload: SessionStoreState['labId']): void => {
                state.labId = payload
            }
    },
    actions: {
        reset:
            ({ commit }): void => {
                commit('resetState')
            },

        changeLocale:
            ({ commit, getters }, payload: SessionStoreState['locale']): void => {
                if (getters.locale !== payload) {
                    commit('updateLocale', payload)
                }
            },
        changeAuth:
            ({ commit, getters }, payload: SessionStoreState['auth']): void => {
                if (getters.auth !== payload) {
                    commit('updateAuth', payload)
                }
            },
        changeLabId:
            ({ commit }, payload: SessionStoreState['labId']): void => {
                commit('updateLabId', payload)
            }
    }
}
