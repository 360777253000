import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_button = _resolveComponent("loading-button")!
  const _component_cards_selection_toolbar = _resolveComponent("cards-selection-toolbar")!
  const _component_confirmation_summary = _resolveComponent("confirmation-summary")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_cards_selection_toolbar, { cards: _ctx.cards }, {
      default: _withCtx(() => [
        _createVNode(_component_loading_button, {
          fill: "solid",
          class: "ion-margin-horizontal",
          color: "primary",
          promise: _ctx.takeOut
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.takeOut')), 1)
          ]),
          _: 1
        }, 8, ["promise"])
      ]),
      _: 1
    }, 8, ["cards"]),
    _createVNode(_component_ion_modal, {
      mode: "md",
      "is-open": _ctx.visibleConfirmationSummary,
      component: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_confirmation_summary, {
          icon: "inbox-out",
          title: _ctx.$t('anapathapp.archive.label.takenOut'),
          counter: _ctx.elementsWithoutError,
          onConfirm: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirm()))
        }, null, 8, ["title", "counter"])
      ]),
      _: 1
    }, 8, ["is-open"])
  ], 64))
}