import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b33c3a9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "confirmation-summary" }
const _hoisted_2 = { class: "confirmation-summary__container ion-text-center ion-padding" }
const _hoisted_3 = { class: "confirmation-summary__icon" }
const _hoisted_4 = { class: "confirmation-summary__check" }
const _hoisted_5 = { class: "confirmation-summary__message ion-padding ion-margin" }
const _hoisted_6 = { class: "confirmation-summary__title ion-padding" }
const _hoisted_7 = { class: "confirmation-summary__counter" }
const _hoisted_8 = { class: "confirmation-summary__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_fa_icon, {
          icon: ['fal', _ctx.icon]
        }, null, 8, ["icon"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_fa_icon, { icon: ['fas', 'check'] })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.title), 1),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.counter) + " " + _toDisplayString(_ctx.$t('anapathapp.label.element', _ctx.counter)), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_ion_button, {
          fill: "clear",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirm()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.continue')), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}