import { computed, defineComponent, onMounted, reactive } from 'vue'
import Card from '@/app/shared/components/cards/card/card.vue'
import CardsEmptyState from '@/app/shared/components/cards/cards-empty-state/cards-empty-state.vue'
import CardsListHeader from '@/app/shared/components/cards/cards-list-header/cards-list-header.vue'
import CardsListSort from '@/app/shared/components/cards/cards-list-sort/cards-list-sort.vue'
import CardsSelectionToolbar from '@/app/shared/components/cards/cards-selection-toolbar/cards-selection-toolbar.vue'
import CardsSkeleton from '@/app/shared/components/cards/cards-skeleton/cards-skeleton.vue'
import ConfirmationSummary from '@/app/shared/components/confirmation-summary/confirmation-summary.vue'
import EntitySelector from '@/app/shared/components/selector/entity/entity-selector.vue'
import Searcher from '@/app/shared/components/searcher/searcher.vue'
import WasteBanner from '@/app/views/modules/components/waste/components/banner/waste-banner.vue'
import { WasteModuleClass } from '@/app/views/modules/components/waste/waste-module.class'
import { CommonSortModelResource } from '@/app/shared/models/common.model'
import { SearchInformation } from '@/app/shared/components/searcher/searcher-model'
import { WasteSampleGridResponse } from '@api/services/waste/sample-filter/waste-sample-filter.dto'
import { Card as CardModel } from '@/app/shared/components/cards/card/card.model'
import { EntitySelectorType } from '@/app/shared/components/selector/entity/entity-selector.model'
import { InfiniteScrollCustomEvent, RefresherCustomEvent } from '@/app/plugins/ionic/ionic-plugin'

export default defineComponent({
    name: 'WasteModule',
    components: {
        Card,
        CardsEmptyState,
        CardsListHeader,
        CardsListSort,
        CardsSelectionToolbar,
        CardsSkeleton,
        ConfirmationSummary,
        EntitySelector,
        Searcher,
        WasteBanner
    },
    setup() {
        const _class = reactive(new WasteModuleClass())

        /// HOOKS
        onMounted(() => _class.loadData())

        return {
            /// COMPUTED
            entityType: computed(() => _class.entityType),
            colDefs: computed(() => _class.colDefs),
            totalElements: computed(() => _class.totalElements),
            cards: computed(() => _class.cards),
            selectedCards: computed(() => _class.selectedCards),
            sortModel: computed(() => _class.sortModel),
            loading: computed(() => _class.loading),
            infiniteScrollDisabled: computed(() => _class.infiniteScrollDisabled),
            elementsWithoutError: computed(() => _class.elementsWithoutError),
            visibleConfirmationSummary: computed(() => _class.visibleConfirmationSummary),
            /// METHODS
            changeEntity: (entity: EntitySelectorType) => _class.changeEntity(entity),
            loadInfiniteScrollData: (event: InfiniteScrollCustomEvent) => _class.loadInfiniteScrollData(event),
            refreshData: (event: RefresherCustomEvent) => _class.refreshData(event),
            onSorted: (sortModel: CommonSortModelResource) => _class.onSorted(sortModel),
            discardSamples: (cards: CardModel<WasteSampleGridResponse>[]) => _class.discardSamples(cards),
            confirm: () => _class.confirm(),
            onSampleSearch: (searchInformation: SearchInformation) => _class.onSearch(searchInformation)
        }
    }
})
