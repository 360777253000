import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!

  return (_ctx.languages.length)
    ? (_openBlock(), _createBlock(_component_ion_select, {
        key: 0,
        modelValue: _ctx.selectedLanguage,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedLanguage = $event)),
        interface: "popover",
        onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeLanguage(_ctx.selectedLanguage)))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
            return (_openBlock(), _createBlock(_component_ion_select_option, {
              key: language.id,
              value: language
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(language.name), 1)
              ]),
              _: 2
            }, 1032, ["value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}