import { defineComponent, reactive, ref } from 'vue'
import { LoginRecoverPasswordClass } from '@/app/views/login/components/recover-password/login-recover-password.class'
import { StrictNullable } from '@/app/core/types/app-types'

export default defineComponent({
    name: 'LoginRecoverPassword',
    setup() {
        const _class = reactive(new LoginRecoverPasswordClass())
        return {
            /// DATA
            username: ref(null),
            /// METHODS
            showLogin: () => _class.showLogin(),
            disabledRecoverPassword: (username: StrictNullable<string>) => _class.disabledRecoverPassword(username),
            recoverPassword: (username: StrictNullable<string>) => _class.recoverPassword(username)
        }
    }
})
