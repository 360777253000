import { IdentifierRegexResponse } from '@api/services/common/initialization/common-initialization.dto'
import { axios } from '@vitro/anapath-frontend-library-api'

class ClinicalInitializationService {
    // Returns the list of all identifier regular expressions that are relevant in the clinical module
    public async getIdentifierRegexes(): Promise<IdentifierRegexResponse[]> {
        const response = await axios.get<IdentifierRegexResponse[]>('clinical/identifierRegexes')
        return response.data
    }
}
export const clinicalInitializationService = new ClinicalInitializationService()
