import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-679b58dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cards-list-filter" }
const _hoisted_2 = { class: "ion-padding" }
const _hoisted_3 = { class: "ion-padding" }
const _hoisted_4 = { class: "ion-padding-bottom" }
const _hoisted_5 = { class: "ion-text-end ion-margin-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_popover = _resolveComponent("ion-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_button, {
      slot: "end",
      fill: "clear",
      class: "filter-button ion-no-margin",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFilter()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_fa_icon, { icon: _ctx.icon }, null, 8, ["icon"])
      ]),
      _: 1
    }),
    (_ctx.visibleFilter)
      ? (_openBlock(), _createBlock(_component_ion_popover, {
          key: 0,
          "css-class": "popover",
          "is-open": _ctx.visibleFilter,
          animated: false,
          component: "",
          onDidDismiss: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hideFilter()))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_ion_label, { class: "popover__title" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.title), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_ion_button, {
                  fill: "outline",
                  class: "ion-margin-end",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearFilters()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.removeFilters')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_button, {
                  class: "btn--primary",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.filter()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_fa_icon, {
                      icon: ['fal', 'check'],
                      class: "ion-margin-end"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('anapathapp.action.apply')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 3
        }, 8, ["is-open"]))
      : _createCommentVNode("", true)
  ]))
}