import { EmitFn } from '@api/lib'
import { StrictNullable } from '@/app/core/types/app-types'

export enum TakeOutReturnSelectorEmitEvent {
    CHANGE = 'change'
}

export interface TakeOutReturnSelectorParams {
    emit: EmitFn<TakeOutReturnSelectorEmitEvent>

    shouldBeReturned: StrictNullable<boolean>
}

export interface TakeOutReturnOption {
    code: string
    getLabel: () => string
    value: boolean
}
