import { ModuleOperation } from '@api/operations/module-operation'
import { i18n } from '@api/lib'
import { StrictNullable } from '@/app/core/types/app-types'
import { sessionStore } from '@api/session-store'
import { LaboratoryConfigurationResponse } from '@api/services/common/initialization/common-initialization.dto'
import { RouteRecordRaw } from 'vue-router'
import { ArchiveModuleRoute, archiveModuleRouter } from '@/app/views/modules/components/archive/archive-module.router'
import { HelpModuleRoute, helpModuleRouter } from '@/app/views/modules/components/help/help-module.router'
import { ProfileModuleRoute, profileModuleRouter } from '@/app/views/modules/components/profile/profile-module.router'
import { WasteModuleRoute, wasteModuleRouter } from '@/app/views/modules/components/waste/waste-module.router'

export interface Module {
    moduleCode: ModuleOperation
    route: RouteRecordRaw
    getLabel: () => string
    icon: string[]
}

const _getModules =
    (): Module[] => [
        {
            moduleCode: ModuleOperation.ARCHIVE,
            route: archiveModuleRouter()[ArchiveModuleRoute.ARCHIVE],
            getLabel: () => i18n.t('anapathapp.label.archive'),
            icon: ['far', 'archive']
        },
        {
            moduleCode: ModuleOperation.HELP,
            route: helpModuleRouter()[HelpModuleRoute.HELP],
            getLabel: () => i18n.t('anapathapp.label.help'),
            icon: ['far', 'question-circle']
        },
        {
            moduleCode: ModuleOperation.PROFILE,
            route: profileModuleRouter()[ProfileModuleRoute.PROFILE],
            getLabel: () => i18n.t('anapathapp.label.profile'),
            icon: ['far', 'user']
        },
        {
            moduleCode: ModuleOperation.WASTE,
            route: wasteModuleRouter()[WasteModuleRoute.WASTE],
            getLabel: () => i18n.t('anapathapp.label.waste'),
            icon: ['far', 'trash']
        }
    ]

export function getModule(moduleCode: ModuleOperation): StrictNullable<Module> {
    return _getModules().find(module => module.moduleCode === moduleCode) ?? null
}

export function getLaboratoryModules(
    laboratoriesConfiguration: LaboratoryConfigurationResponse[],
    laboratoryId?: number
): Module[] {
    if (laboratoriesConfiguration.length) {
        const laboratoryModules =
            laboratoriesConfiguration.find(configuration => {
                if (sessionStore.isPetitionerUser) {
                    return configuration.petitionerModuleActive
                }
                return configuration.laboratory.labId === laboratoryId
            })
        if (laboratoryModules) {
            return laboratoryModules.modules
                .filter(module => getModule(module.moduleCode as ModuleOperation) !== null)
                .map(module => getModule(module.moduleCode as ModuleOperation) as Module)
        }
    }
    return []
}
