import { defineComponent, PropType, reactive, ref } from 'vue'
import { ProfileChangePasswordClass } from '@/app/views/modules/components/profile/components/change-password/profile-change-password.class'
import { StrictNullable } from '@/app/core/types/app-types'

export default defineComponent({
    name: 'ProfileChangePassword',
    props: {
        onBack: { type: Function as PropType<() => void>, required: true }
    },
    setup(props) {
        const _class = reactive(new ProfileChangePasswordClass())
        return {
            /// DATA
            currentPassword: ref(null),
            password: ref(null),
            passwordConfirmation: ref(null),
            /// METHODS
            goBack: () => props.onBack(),
            changePassword: async (currentPassword: StrictNullable<string>, password1: StrictNullable<string>, password2: StrictNullable<string>) => {
                await _class.changePassword(currentPassword, password1, password2)
                if (!_class.hasError && !_class.invalid) {
                    props.onBack()
                }
            }
        }
    }
})
