import { ArchiveEntityDatasource } from '@/app/views/modules/components/archive/datasources/entities/archive-entity.datasource'
import { ArchiveSubportionGridResponse } from '@api/services/archive/subportion-filter/archive-subportion-filter.dto'
import { SubportionType } from '@api/services/common/subportion-type/common-subportion-type.dto'
import {
    ArchiveModuleSectionType
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'
import { ColDef, Page } from '@/app/shared/models/common.model'
import { i18n } from '@api/lib'
import { StoreUtil } from '@/app/core/utils/lib/store-util'
import {
    archiveSubportionFilterService
} from '@api/services/archive/subportion-filter/archive-subportion-filter.service'
import { PendingUnarchivingReasonResponse } from '@api/services/archive/archive.dto'
import {
    CommonEntityIdentifiersListRequestCommonSubportionFamilyTreeRequest,
    CommonSubportionFamilyTreeRequest
} from '@api/services/common.dto'
import { EntityIdentifierWithErrorResponse } from '@/app/core/models/http.model'
import {
    ArchiveRequestCommonSubportionFamilyTreeRequest,
    UnarchiveRequestCommonSubportionFamilyTreeRequest
} from '@api/services/archive/subportion/archive-subportion.dto'
import { archiveSubportionService } from '@api/services/archive/subportion/archive-subportion.service'

export class ArchiveEntitySubportionDatasource extends ArchiveEntityDatasource<
    ArchiveSubportionGridResponse,
    CommonEntityIdentifiersListRequestCommonSubportionFamilyTreeRequest,
    ArchiveRequestCommonSubportionFamilyTreeRequest,
    UnarchiveRequestCommonSubportionFamilyTreeRequest
> {
    private readonly _subportionType: SubportionType

    constructor(sectionType: ArchiveModuleSectionType, subportionType: SubportionType) {
        super(sectionType)
        this._subportionType = subportionType
    }

    protected _getColDefs(): ColDef<ArchiveSubportionGridResponse>[] {
        return [
            { colId: 'supLabel', field: 'label', label: i18n.t('anapathapp.label.elementId') },
            { colId: 'locationLabel', field: 'archivingLocation', label: i18n.t('anapathapp.label.archiveLocation') },
            { field: 'pendingUnarchivingReason', sortable: false }
        ]
    }

    protected _fetchData(): Promise<Page<ArchiveSubportionGridResponse>> {
        return archiveSubportionFilterService.paginator(
            StoreUtil.labId,
            this._subportionType,
            this._sectionType === ArchiveModuleSectionType.PENDING_TO_BE_ARCHIVED
                ? 'pendingToBeArchived'
                : 'pendingToBeUnarchived',
            this._filterModel ?? undefined,
            super.getFetchDataParams())
    }

    public getPendingUnArchivingReasons(): Promise<PendingUnarchivingReasonResponse[]> {
        return archiveSubportionFilterService.getSubportionPendingUnArchivingReasons()
    }

    ///

    public updateLocation(
        archivingLocation: string,
        laboratoryId: number,
        caseId: number,
        sampleId: number,
        portionId: number,
        subportionId: number
    ): Promise<ArchiveSubportionGridResponse> {
        return archiveSubportionService.updateLocation(
            laboratoryId,
            caseId,
            sampleId,
            portionId,
            subportionId,
            archivingLocation)
    }

    public getEntityHierarchicalIds(entity: ArchiveSubportionGridResponse): CommonSubportionFamilyTreeRequest {
        return {
            caseId: entity.caseId,
            sampleId: entity.sampleId,
            portionId: entity.portionId,
            subportionId: entity.id
        }
    }
    public unarchivePendingToBeUnarchived(
        laboratoryId: number,
        request: CommonEntityIdentifiersListRequestCommonSubportionFamilyTreeRequest
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        return archiveSubportionService.unarchivePendingToBeUnarchived(laboratoryId, request)
    }
    public archive(
        laboratoryId: number,
        request: ArchiveRequestCommonSubportionFamilyTreeRequest[]
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        return archiveSubportionService.archive(laboratoryId, request)
    }
    public takeOutFromLaboratory(
        laboratoryId: number,
        request: UnarchiveRequestCommonSubportionFamilyTreeRequest[]
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        return archiveSubportionService.takeOutFromLaboratory(laboratoryId, request)
    }
}
