import { computed, defineComponent, PropType, reactive, watch } from 'vue'
import { LoadingButtonClass } from '@/app/core/components/loading-button/loading-button.class'
import { LoadingButtonEmitEvent, LoadingButtonParams } from '@/app/core/components/loading-button/loading-button.model'

export default defineComponent({
    name: 'LoadingButton',
    props: {
        promise: { type: Function as PropType<() => Promise<unknown>>, required: true },
        rippleEffect: { type: Boolean, default: false },
    },
    emits: [...Object.values(LoadingButtonEmitEvent)],
    setup(props, { attrs, emit }) {
        const _getParams =
            (): LoadingButtonParams => ({
                promise: props.promise
            })
        const _class = reactive(new LoadingButtonClass(emit, _getParams()))
        watch(props, () => _class.params = _getParams())

        return {
            /// COMPUTED
            loading: computed(() => _class.loading),
            disabled: computed(() => attrs.disabled || _class.loading),
            /// METHODS
            onClick: () => _class.onClick(),
        }
    }
})
