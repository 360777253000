import { computed, defineComponent, onMounted, PropType, reactive, toRef, watch } from 'vue'
import { WasteBannerClass } from '@/app/views/modules/components/waste/components/banner/waste-banner.class'
import { EntitySelectorType } from '@/app/shared/components/selector/entity/entity-selector.model'
import { WasteBannerParams } from '@/app/views/modules/components/waste/components/banner/waste-banner.model'

export default defineComponent({
    name: 'WasteBanner',
    props: {
        entityType: { type: String as PropType<EntitySelectorType>, required: true }
    },
    setup(props) {
        const _getParams =
            (): WasteBannerParams => ({
                entityType: props.entityType
            })
        const _class = reactive(new WasteBannerClass(_getParams()))
        watch(props, () => _class.params = _getParams())

        /// HOOKS
        onMounted(() => _class.checkPendingToTakeOutFromArchiveByDisposal())

        return {
            /// DATA
            visible: toRef(_class, 'visible'),
            /// COMPUTED
            hasArchiveAccess: computed(() => _class.hasArchiveAccess),
            /// METHODS
            goToTakeOutByDisposal: () => _class.goToTakeOutByDisposal(),
            hide: () => _class.hide()
        }
    }
})
