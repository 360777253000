import { EntitySelectorType } from '@/app/shared/components/selector/entity/entity-selector.model'
import { StoreUtil } from '@/app/core/utils/lib/store-util'
import { Parameter } from '@vitro/anapath-frontend-library-api'

export class ParameterUtil {
    private static _getLocationConfigurationParameter(entityType: EntitySelectorType): string {
        switch (entityType) {
        case EntitySelectorType.SAMPLES:
            return Parameter.laboratory.ARCHIVE_MANDATORY_SAMPLE_LOCATION

        case EntitySelectorType.PARAFFIN_BLOCKS:
            return Parameter.laboratory.ARCHIVE_MANDATORY_PARAFFIN_BLOCK_LOCATION
        case EntitySelectorType.FROZEN_BLOCKS:
            return Parameter.laboratory.ARCHIVE_MANDATORY_FROZEN_BLOCK_LOCATION

        case EntitySelectorType.SLIDES:
            return Parameter.laboratory.ARCHIVE_MANDATORY_SLIDE_LOCATION
        case EntitySelectorType.MICRO_CENTRIFUGE_TUBES:
            return ''

        default:
            throw new Error(`Unexpected entity type ${entityType}`)
        }
    }
    public static isLocationRequired(entityType: EntitySelectorType): boolean {
        const locationParameterCode = ParameterUtil._getLocationConfigurationParameter(entityType)
        return StoreUtil.localParameters.some(laboratoryParameter =>
            laboratoryParameter.parameter.code === locationParameterCode &&
            laboratoryParameter.value === 'true')
    }
}
