import { axios } from '@vitro/anapath-frontend-library-api'
import {
    ArchiveSummaryResponse,
    CommonUnarchivingReasonResponse
} from '@api/services/archive/common/archive-common.dto'

class ArchiveCommonService {
    public async getUnArchivingReasons(laboratoryId: number): Promise<CommonUnarchivingReasonResponse[]> {
        const response =
            await axios.get<CommonUnarchivingReasonResponse[]>(
                `/archive/laboratories/${laboratoryId}/unarchivingReasons`)
        return response.data
    }

    public async getSummary(laboratoryId: number): Promise<ArchiveSummaryResponse> {
        const response =
            await axios.get<ArchiveSummaryResponse>(
                `/archive/laboratories/${laboratoryId}/cases/samples/summary`)
        return response.data
    }
}
export const archiveCommonService = new ArchiveCommonService()
