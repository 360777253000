import { RouteRecordRaw } from 'vue-router'
import { Optional } from '@/app/core/types/app-types'
import { auth } from '@api/auth'
import { archiveModuleRouter } from '@/app/views/modules/components/archive/archive-module.router'
import { helpModuleRouter } from '@/app/views/modules/components/help/help-module.router'
import { profileModuleRouter } from '@/app/views/modules/components/profile/profile-module.router'
import { wasteModuleRouter } from '@/app/views/modules/components/waste/waste-module.router'
import { LoginModuleRoute } from '@/app/views/login/login-module.router'

export enum ModulesRoute {
    MODULES = 'Modules'
}

export const modulesRouter =
    (): Record<ModulesRoute, RouteRecordRaw> => ({
        [ModulesRoute.MODULES]: {
            path: '/modules',
            children: [
                ...Object.values(archiveModuleRouter()),
                ...Object.values(helpModuleRouter()),
                ...Object.values(profileModuleRouter()),
                ...Object.values(wasteModuleRouter())
            ],
            name: ModulesRoute.MODULES,
            beforeEnter: async (to, from) => {
                if (to.name !== LoginModuleRoute.LOGIN && from.name !== LoginModuleRoute.LOGIN) {
                    const token = to.query?.accessToken as Optional<string>
                    await auth.refreshToken(token)
                    await auth.resetUserDowntimeTimeout()
                }
            },
            meta: { requiresAuth: true },
            component: () => import('@/app/views/modules/modules.vue')
        }
    })
