import { computed, defineComponent, reactive, ref } from 'vue'
import { LoginClass } from '@/app/views/login/components/login/login.class'
import { StrictNullable } from '@/app/core/types/app-types'

export default defineComponent({
    name: 'Login',
    setup() {
        const _class = reactive(new LoginClass())
        return {
            /// DATA
            username: ref(null),
            password: ref(null),
            /// COMPUTED
            loading: computed(() => _class.loading),
            /// METHODS
            login: (username: StrictNullable<string>, password: StrictNullable<string>) => _class.login(username, password),
            showRecoverPassword: () => _class.showRecoverPassword(),
            showChangePassword: () => _class.showChangePassword(),
            fingerPrintAlert: () => _class.fingerPrintAlert(),
            faceIdAlert: () => _class.faceIdAlert(),
            onEnter: (username: StrictNullable<string>, password: StrictNullable<string>) => _class.onEnter(username, password)
        }
    }
})
