import { computed, defineComponent, onMounted, PropType, reactive, watch } from 'vue'
import CardsEmptyState from '@/app/shared/components/cards/cards-empty-state/cards-empty-state.vue'
import Searcher from '@/app/shared/components/searcher/searcher.vue'
import {
    ArchiveAddOperationClass
} from '@/app/views/modules/components/archive/components/shared/add-operation/archive-add-operation.class'
import { SearchInformation } from '@/app/shared/components/searcher/searcher-model'
import { Card } from '@/app/shared/components/cards/card/card.model'
import { ArchiveEntityResponse } from '@/app/views/modules/components/archive/datasources/archive-datasource-factory'
import {
    ArchiveAddOperationEmitEvent,
    ArchiveAddOperationParams
} from '@/app/views/modules/components/archive/components/shared/add-operation/archive-add-operation.model'
import { EntitySelectorType } from '@/app/shared/components/selector/entity/entity-selector.model'
import {
    ArchiveModuleSectionType
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'

export default defineComponent({
    name: 'ArchiveAddOperation',
    components: {
        CardsEmptyState,
        Searcher
    },
    props: {
        entityType: { type: String as PropType<EntitySelectorType>, required: true },
        sectionType: { type: String as PropType<ArchiveModuleSectionType>, required: true },
        title: { type: String, required: true },
        listTitle: { type: String, required: true },
        showStepper: { type: Boolean, default: true }
    },
    emits: [...Object.values(ArchiveAddOperationEmitEvent)],
    setup(props, { emit, slots }) {
        const _getParams =
            (): ArchiveAddOperationParams => ({
                entityType: props.entityType,
                sectionType: props.sectionType
            })
        const _class = reactive(new ArchiveAddOperationClass(emit, _getParams()))
        watch(props, () => _class.params = _getParams())

        /// HOOKS
        onMounted(() => _class.loadData())

        return {
            /// COMPUTED
            isSampleEntity: computed(() => _class.isSampleEntity),
            isPortionEntity: computed(() => _class.isPortionEntity),
            isSubportionEntity: computed(() => _class.isSubportionEntity),
            visibleStepper: computed(() => !!slots['operations'] && props.showStepper),
            visibleActions: computed(() => _class.visibleActions()),
            visibleList: computed(() => _class.visibleList),
            toggleVisibleLabel: computed(() => _class.toggleVisibleLabel),
            cards: computed(() => _class.cards),
            /// METHODS
            goBack: () => _class.goBack(),
            deselectCard: (card: Card<ArchiveEntityResponse>) => _class.deselectCard(card),
            onSampleSearch: (searchInformation: SearchInformation) => _class.onSampleSearch(searchInformation),
            onPortionSearch: (searchInformation: SearchInformation) => _class.onPortionSearch(searchInformation),
            onSubportionSearch: (searchInformation: SearchInformation) => _class.onSubportionSearch(searchInformation),
            toggleVisibleList: () => _class.toggleVisibleList()
        }
    }
})
