import { computed, defineComponent, PropType, reactive, ref } from 'vue'
import { ColDef, CommonSortModelResource, SortType } from '@/app/shared/models/common.model'
import {
    CardsListSortEmitEvent,
    CardsListSortParams
} from '@/app/shared/components/cards/cards-list-sort/cards-list-sort.model'
import { CardsListSortClass } from '@/app/shared/components/cards/cards-list-sort/cards-list-sort.class'

export default defineComponent({
    name: 'CardsListSort',
    props: {
        colDefs: { type: Array as PropType<ColDef[]>, required: true },
        initialSort: { type: Object as PropType<CommonSortModelResource>, required: true }
    },
    emits: [ ...Object.values(CardsListSortEmitEvent) ],
    setup(props, { emit }) {
        const _getParams =
            (): CardsListSortParams => ({
                emit,
                colDefs: props.colDefs,
                sortModel: props.initialSort
            })
        const _class = reactive(new CardsListSortClass(_getParams()))

        /// DATA
        const visiblePopover = ref(false)

        return {
            /// DATA
            visiblePopover,
            /// COMPUTED
            sortableColDefs: computed(() => _class.sortableColDefs),
            sortModel: computed(() => _class.sortModel),
            sortTypes: computed(() => _class.sortTypes),
            /// METHODS
            togglePopover: () => (visiblePopover.value = !visiblePopover.value),
            onSortBy: (field: string) => _class.onSortBy(field),
            onSortType: (sortType: SortType) => _class.onSortType(sortType)
        }
    }
})
