import { AuthenticationDevicePlatform } from '@vitro/anapath-frontend-library-api'

export class DeviceUtil {
    public static get platform(): AuthenticationDevicePlatform {
        const { userAgent, platform } = window.navigator

        if (['iPhone', 'iPad', 'iPod'].indexOf(platform) !== -1) {
            return AuthenticationDevicePlatform.IOS
        } else if (/Android/.test(userAgent)) {
            return AuthenticationDevicePlatform.ANDROID
        }
        return AuthenticationDevicePlatform.UNKNOWN
    }

    public static get isMobile(): boolean {
        const userAgent = navigator.userAgent.toLowerCase()
        return /iPhone|Android/i.test(userAgent)
    }

    public static get isTablet(): boolean {
        const userAgent = navigator.userAgent.toLowerCase()
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)
        return !this.isMobile && isTablet
    }
}
