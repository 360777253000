import App from '@/app/app.vue'
import { router } from '@/app/router'
import { IonicVue } from '@ionic/vue'
import { createApp } from 'vue'
/* Plugins */
import { fontAwesomePlugin } from '@/app/plugins/font-awesome/font-awesome-plugin'
import { ionicPlugin } from '@/app/plugins/ionic/ionic-plugin'
import { vueI18nPlugin } from '@/app/plugins/vue-i18n/vue-i18n-plugin'
import { vuexPlugin } from '@/app/plugins/vuex/vuex-plugin'
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'
/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/display.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
/* Theme variables */
import '@/theme/variables.css'
import '@/theme/_anapath-theme.scss'
/* Ionic icons */
import { addIcons } from 'ionicons'
import * as icons from 'ionicons/icons'
addIcons(icons)
/* PWA */
import '@/registerServiceWorker'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { axiosPlugin } from './plugins/axios-library/axios-library-plugin'

export const app =
    createApp(App)
        .use(IonicVue)
        .use(router)
app.config.performance = process.env.NODE_ENV !== 'production'

/* Plugins */
fontAwesomePlugin.register(app)
ionicPlugin.register(app)
axiosPlugin.register(app)
vueI18nPlugin.register(app)
vuexPlugin.register(app)

router.isReady().then(async () => {
    app.mount('#app')
    await defineCustomElements(window)
})
