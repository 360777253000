import { userDowntimeClass } from '@/app/core/components/user-downtime/user-downtime.class'
import { RootStoreState } from '@/app/plugins/vuex/store/root/root-store-state.model'
import { auth } from '@api/auth'
import { MutationPayload, Plugin, Store } from 'vuex'

export const showUserDowntimeModalChangedStorePlugin: Plugin<RootStoreState> =
    (store: Store<RootStoreState>) => {
        store.subscribe(async (mutation: MutationPayload) => {
            if (mutation.type === 'updateShowUserDowntimeModal') {
                const showUserDowntimeModal: boolean = mutation.payload
                if (store.state.userLoggedIn) {
                    if (showUserDowntimeModal && auth.state) {
                        const timeToDowntimeInSeconds = auth.state.countdownToCloseSessionInSeconds
                        if (typeof timeToDowntimeInSeconds === 'number') {
                            await userDowntimeClass.show(timeToDowntimeInSeconds)
                        }
                    } else {
                        userDowntimeClass.cancelCountDownInterval()
                    }
                }
            }
        })
    }
