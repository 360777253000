import { computed, defineComponent, PropType, reactive, watch } from 'vue'
import ArchiveLocationInput
    from '@/app/views/modules/components/archive/components/shared/location-input/archive-location-input.vue'
import ArchiveAddOperation from '@/app/views/modules/components/archive/components/shared/add-operation/archive-add-operation.vue'
import ConfirmationSummary from '@/app/shared/components/confirmation-summary/confirmation-summary.vue'
import { ArchiveEntityDatasource } from '@/app/views/modules/components/archive/datasources/entities/archive-entity.datasource'
import {
    ArchivePendingToBeArchivedAddOperationEmitEvent, ArchivePendingToBeArchivedAddOperationParams
} from '@/app/views/modules/components/archive/components/pending-to-be-archived/add-operation/archive-pending-to-be-archived-add-operation.model'
import {
    ArchivePendingToBeArchivedAddOperationClass
} from '@/app/views/modules/components/archive/components/pending-to-be-archived/add-operation/archive-pending-to-be-archived-add-operation.class'
import { Card } from '@/app/shared/components/cards/card/card.model'
import { ArchiveEntityResponse } from '@/app/views/modules/components/archive/datasources/archive-datasource-factory'
import { EntitySelectorType } from '@/app/shared/components/selector/entity/entity-selector.model'
import {
    ArchiveModuleSectionType
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'

export default defineComponent({
    name: 'ArchivePendingToBeArchivedAddOperation',
    components: {
        ArchiveLocationInput,
        ArchiveAddOperation,
        ConfirmationSummary
    },
    props: {
        title: { type: String, required: true },
        entityType: { type: String as PropType<EntitySelectorType>, required: true },
        sectionType: { type: String as PropType<ArchiveModuleSectionType>, required: true },
        datasource: { type: Object as PropType<ArchiveEntityDatasource>, required: true }
    },
    emits: [...Object.values(ArchivePendingToBeArchivedAddOperationEmitEvent)],
    setup(props, { emit }) {
        const _getParams =
            (): ArchivePendingToBeArchivedAddOperationParams => ({
                entityType: props.entityType
            })
        const _class = reactive(new ArchivePendingToBeArchivedAddOperationClass(emit, _getParams()))
        watch(props, () => _class.params = _getParams())

        return {
            /// COMPUTED
            elementsWithoutError: computed(() => _class.elementsWithoutError),
            visibleConfirmationSummary: computed(() => _class.visibleConfirmationSummary),
            hasCards: computed(() => _class.hasCards),
            askLocation: computed(() => _class.askLocation),
            disabledTakeIn: computed(() => _class.disabledTakeIn),
            /// METHODS
            goBack: () => _class.goBack(),
            onChange: (cards: Card<ArchiveEntityResponse>[]) => _class.onChange(cards),
            locationChanged: (location: string) => _class.locationChanged(location),
            takeIn: () => _class.takeIn(props.datasource),
            confirm: () => _class.confirm()
        }
    }
})
