import { IconDefinitionCustom } from '@/app/plugins/font-awesome/custom-icons-packs/custom-icons-packs.model'
import {
    IconNameVitro,
    IconPrefixVitro
} from '@/app/plugins/font-awesome/custom-icons-packs/vitro/vitro-icon-pack.model'

export const prefix: IconPrefixVitro = 'vt'
export const iconName: IconNameVitro = 'paraffinblock'
export const width = 1073
export const height = 1073
export const ligatures: string[] = []
export const unicode = ''
export const svgPathData = 'M901.4 998H155.7V0H901.4V998ZM221.9 931.8H835V66.2H221.9V931.8ZM213.1 181.8H835V259.8H213.1V181.8ZM306.3 312.3H493.9V410.2H306.3V312.3ZM554.1 312.3H741.8V410.2H554.1V312.3ZM306.3 468.6H493.9V566.4H306.3V468.6ZM554.1 468.6H741.8V566.4H554.1V468.6ZM306.3 624.8H493.9V722.7H306.3V624.8ZM554.1 624.8H741.8V722.7H554.1V624.8ZM306.3 781.1H493.9V878.9H306.3V781.1ZM554.1 781.1H741.8V878.9H554.1V781.1Z'

export const definition: IconDefinitionCustom<IconPrefixVitro, IconNameVitro> = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]}
export const faParaffinBlock: IconDefinitionCustom<IconPrefixVitro, IconNameVitro> = definition
