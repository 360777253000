import {
    Overlay,
    OverlayEvent,
    OverlayLib,
    OverlayOptions
} from '@api/lib/overlay'
import { alertController as alertIonicController } from '@ionic/vue'
import { Optional } from '@/app/core/types/app-types'

export interface AlertButton {
    text: string
    role?: 'cancel' | 'destructive' | string
    cssClass?: string | string[]
    handler?: (value: unknown) => boolean | void | Record<string, unknown> | Promise<boolean | void | Record<string, unknown>>
}

export interface AlertOptions extends OverlayOptions {
    header?: string
    subheader?: string
    buttons?: (AlertButton | string)[]
}

class Alert implements Overlay<AlertOptions> {
    private _element: HTMLIonAlertElement
    public readonly options: AlertOptions

    constructor(options: AlertOptions, element: HTMLIonAlertElement) {
        this.options = options
        this._element = element
    }

    dismiss<T>(data?: T, role?: string): Promise<boolean> {
        return this._element.dismiss(data, role)
    }

    async onDidDismiss<T>(): Promise<OverlayEvent<T>> {
        const event = await this._element.onDidDismiss()
        return {
            data: event.data,
            role: event.role
        }
    }

    async onWillDismiss<T>(): Promise<OverlayEvent<T>> {
        const event = await this._element.onWillDismiss()
        return {
            data: event.data,
            role: event.role
        }
    }

    present(): Promise<void> {
        return this._element.present()
    }
}

class AlertController implements OverlayLib<AlertOptions, Overlay<AlertOptions>> {
    private _overlay: Optional<Alert>

    async create(options: AlertOptions): Promise<Overlay<AlertOptions>> {
        const alert = await alertIonicController.create(options)
        this._overlay = new Alert(options, alert)
        return this._overlay
    }

    async dismiss(): Promise<boolean> {
        return await this._overlay?.dismiss() ?? false
    }
}
export const alertController = new AlertController()
