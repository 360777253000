import { EmitFn } from '@api/lib'
import { ConfirmationSummaryEvent } from '@/app/shared/components/confirmation-summary/confirmation-summary.model'

export class ConfirmationSummaryClass {
    private readonly _emit: EmitFn<ConfirmationSummaryEvent>

    constructor(emit: EmitFn<ConfirmationSummaryEvent>) {
        this._emit = emit
    }

    public confirm() {
        this._emit(ConfirmationSummaryEvent.CONFIRM)
    }
}
