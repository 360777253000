import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f2c91ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sticky-header" }
const _hoisted_2 = { class: "section-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_entity_selector = _resolveComponent("entity-selector")!
  const _component_archive_pending_to_be_archived_selection_toolbar = _resolveComponent("archive-pending-to-be-archived-selection-toolbar")!
  const _component_archive_pending_to_be_un_archived_selection_toolbar = _resolveComponent("archive-pending-to-be-un-archived-selection-toolbar")!
  const _component_archive_section_selector = _resolveComponent("archive-section-selector")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_searcher = _resolveComponent("searcher")!
  const _component_archive_pending_to_be_un_archived_filter_action = _resolveComponent("archive-pending-to-be-un-archived-filter-action")!
  const _component_cards_list_sort = _resolveComponent("cards-list-sort")!
  const _component_cards_list_header = _resolveComponent("cards-list-header")!
  const _component_cards_skeleton = _resolveComponent("cards-skeleton")!
  const _component_archive_pending_to_be_un_archived_filter_info = _resolveComponent("archive-pending-to-be-un-archived-filter-info")!
  const _component_card = _resolveComponent("card")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_cards_empty_state = _resolveComponent("cards-empty-state")!
  const _component_archive_pending_to_be_archived_actions = _resolveComponent("archive-pending-to-be-archived-actions")!
  const _component_archive_pending_to_be_un_archived_actions = _resolveComponent("archive-pending-to-be-un-archived-actions")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      (_ctx.datasource)
        ? (_openBlock(), _createBlock(_component_ion_content, {
            key: 0,
            class: "archive-module"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher, {
                slot: "fixed",
                onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.refreshData($event)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_refresher_content)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ion_header, { mode: "md" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_toolbar, null, {
                      default: _withCtx(() => [
                        (!_ctx.selectedCards.length)
                          ? (_openBlock(), _createBlock(_component_entity_selector, {
                              key: 0,
                              "entity-type": _ctx.entityType,
                              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeEntity($event)))
                            }, null, 8, ["entity-type"]))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              (_ctx.isPendingToBeArchived)
                                ? (_openBlock(), _createBlock(_component_archive_pending_to_be_archived_selection_toolbar, {
                                    key: 0,
                                    cards: _ctx.selectedCards,
                                    "entity-type": _ctx.entityType,
                                    datasource: _ctx.datasource,
                                    onRefresh: _cache[2] || (_cache[2] = ($event: any) => (_ctx.reloadData()))
                                  }, null, 8, ["cards", "entity-type", "datasource"]))
                                : (_ctx.isPendingToBeUnArchived)
                                  ? (_openBlock(), _createBlock(_component_archive_pending_to_be_un_archived_selection_toolbar, {
                                      key: 1,
                                      cards: _ctx.selectedCards,
                                      datasource: _ctx.datasource,
                                      onRefresh: _cache[3] || (_cache[3] = ($event: any) => (_ctx.reloadData()))
                                    }, null, 8, ["cards", "datasource"]))
                                  : _createCommentVNode("", true)
                            ], 64)),
                        _createVNode(_component_archive_section_selector, {
                          "section-type": _ctx.sectionType,
                          onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeSection($event)))
                        }, null, 8, ["section-type"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_searcher, {
                  id: "archive-module",
                  "error-message": _ctx.$t('anapathapp.archive.message.validIdentifier'),
                  "sample-searching": "",
                  "portion-searching": "",
                  "subportion-searching": "",
                  onSampleSearch: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onSampleSearch($event))),
                  onPortionSearch: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onPortionSearch($event))),
                  onSubportionSearch: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onSubportionSearch($event)))
                }, null, 8, ["error-message"]),
                _createVNode(_component_cards_list_header, {
                  pending: _ctx.totalElements,
                  title: _ctx.listHeader
                }, {
                  default: _withCtx(() => [
                    (_ctx.isPendingToBeUnArchived)
                      ? (_openBlock(), _createBlock(_component_archive_pending_to_be_un_archived_filter_action, {
                          key: _ctx.entityType,
                          datasource: _ctx.datasource,
                          onFiltered: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onFiltered($event)))
                        }, null, 8, ["datasource"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_cards_list_sort, {
                      "col-defs": _ctx.colDefs,
                      "initial-sort": _ctx.sortModel,
                      onSorted: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onSorted($event)))
                    }, null, 8, ["col-defs", "initial-sort"])
                  ]),
                  _: 1
                }, 8, ["pending", "title"])
              ]),
              _createElementVNode("div", _hoisted_2, [
                (_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_cards_skeleton, { key: 0 }))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_ctx.isPendingToBeUnArchived)
                        ? (_openBlock(), _createBlock(_component_archive_pending_to_be_un_archived_filter_info, {
                            key: 0,
                            onFiltered: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onFiltered($event)))
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.cards.length)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card) => {
                              return (_openBlock(), _createBlock(_component_card, {
                                key: card.title,
                                card: card,
                                selectable: _ctx.canSelectCards
                              }, null, 8, ["card", "selectable"]))
                            }), 128)),
                            _createVNode(_component_ion_infinite_scroll, {
                              id: "infinite-scroll",
                              threshold: "100px",
                              disabled: _ctx.infiniteScrollDisabled,
                              onIonInfinite: _cache[11] || (_cache[11] = ($event: any) => (_ctx.loadInfiniteScrollData($event)))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_infinite_scroll_content, { "loading-spinner": "circles" })
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ], 64))
                        : (_openBlock(), _createBlock(_component_cards_empty_state, { key: 2 }))
                    ], 64))
              ]),
              (_ctx.canTakeIn)
                ? (_openBlock(), _createBlock(_component_archive_pending_to_be_archived_actions, {
                    key: 0,
                    "section-type": _ctx.sectionType,
                    "entity-type": _ctx.entityType,
                    datasource: _ctx.datasource,
                    onRefresh: _cache[12] || (_cache[12] = ($event: any) => (_ctx.reloadData()))
                  }, null, 8, ["section-type", "entity-type", "datasource"]))
                : (_ctx.canTakeOut)
                  ? (_openBlock(), _createBlock(_component_archive_pending_to_be_un_archived_actions, {
                      key: 1,
                      "section-type": _ctx.sectionType,
                      "entity-type": _ctx.entityType,
                      datasource: _ctx.datasource,
                      onRefresh: _cache[13] || (_cache[13] = ($event: any) => (_ctx.reloadData()))
                    }, null, 8, ["section-type", "entity-type", "datasource"]))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}