import { i18n } from '@api/lib'

export interface SlideContent {
    title: string
    subtitle?: string
    button?: string
    imageURL: string
}

export const getSliderContent =
    (): SlideContent[] => [
        {
            title: i18n.t('anapathapp.modal.slides.label.keepUpToDate'),
            subtitle: i18n.t('anapathapp.modal.slides.label.reviewListWithPendingElements'),
            imageURL: require('@/app/assets/images/badges/list-badge.jpg')
        },
        {
            title: i18n.t('anapathapp.modal.slides.label.useTheScanner'),
            subtitle: i18n.t('anapathapp.modal.slides.label.useDeviceCam'),
            imageURL: require('@/app/assets/images/badges/scan-badge.jpg')
        },
        {
            title: i18n.t('anapathapp.modal.slides.label.toYourLinking'),
            subtitle: i18n.t('anapathapp.modal.slides.label.accessProfileToConfiguration'),
            imageURL: require('@/app/assets/images/badges/profile-badge.jpg')
        },
        {
            title: i18n.t('anapathapp.modal.slides.label.anyDoubt'),
            subtitle: i18n.t('anapathapp.modal.slides.label.useHelpForMoreDetails'),
            imageURL: require('@/app/assets/images/badges/help-badge.jpg')
        },
        {
            title: i18n.t('anapathapp.modal.slides.label.enjoyAnapath'),
            button: i18n.t('anapathapp.modal.slides.action.goOn'),
            imageURL: require('@/app/assets/images/badges/finale-badge.jpg')
        }
    ]
