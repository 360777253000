import {
    ModalUtilShowConfirmationParams,
    ModalUtilShowParams
} from '@/app/core/utils/lib/overlay/modal/modal-util.model'
import { i18n } from '@api/lib'
import { AxiosError } from 'axios'
import { alertController } from '@api/lib/overlay'
import { ExceptionResponse } from '@vitro/anapath-frontend-library-api'

export class ModalUtil {
    private static async _show(params: ModalUtilShowParams): Promise<void> {
        const cssClass: string[] = []
        if (Array.isArray(params.cssClass)) {
            cssClass.push(...params.cssClass)
        } else if (typeof params.cssClass === 'string') {
            cssClass.push(params.cssClass)
        }
        if (params.icon) { cssClass.push(params.icon) }
        if (params.state) { cssClass.push(params.state) }

        const alert =
            await alertController.create({
                message: params.message,
                header: params.header,
                buttons: params.buttons,
                cssClass
            })
        await alert.present()
        if (params.hideAutomatically) {
            setTimeout(alert.dismiss, 3000)
        }
    }

    public static async showSuccess(message: string = i18n.t('anapathapp.message.savedSuccessfully')): Promise<void> {
        await ModalUtil._show({
            message,
            icon: 'fal fa-check',
            state: 'vt-modal vt-modal--success',
            hideAutomatically: true
        })
    }
    public static async showInfo(message: string): Promise<void> {
        await ModalUtil._show({
            message,
            icon: 'fal fa-info',
            state: 'vt-modal vt-modal--info',
            hideAutomatically: false
        })
    }
    public static async showWarning(message: string): Promise<void> {
        await ModalUtil._show({
            message,
            icon: 'fal fa-exclamation',
            state: 'vt-modal vt-modal--warning',
            hideAutomatically: false
        })
    }
    public static async showError(message: string = i18n.t('anapathapp.message.anErrorOccurred')): Promise<void> {
        await ModalUtil._show({
            message,
            icon: 'fal fa-times',
            state: 'vt-modal vt-modal--error',
            hideAutomatically: false
        })
    }

    public static async showErrorFromErrorResponse(error: AxiosError<ExceptionResponse>, alternativeMessage?: string): Promise<void> {
        const message =
            error?.response?.data?.messageTranslated
                ? error.response.data.message
                : alternativeMessage
        await ModalUtil.showError(message)
    }

    public static async showConfirmation(params: ModalUtilShowConfirmationParams): Promise<void> {
        const alert =
            await alertController.create({
                cssClass: params.cssClass ?? 'alert alert--info',
                header: params.header ?? i18n.t('anapathapp.modal.confirmation.label.confirmAction'),
                message: params.message ?? i18n.t('anapathapp.modal.confirmation.label.areYouSure'),
                buttons: [
                    {
                        text: params.cancel?.title ?? i18n.t('anapathapp.modal.confirmation.label.no'),
                        handler: params.cancel?.handler
                    },
                    {
                        text: params.accept?.title ?? i18n.t('anapathapp.modal.confirmation.label.yes'),
                        handler: params.accept?.handler
                    }
                ]
            })
        await alert.present()
    }
}
