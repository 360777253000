import { StrictNullable } from '@/app/core/types/app-types'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { LoginType } from '@/app/views/login/login-module.model'
import { store } from '@api/lib'
import { PasswordClass } from '@api/password.class'
import { authService } from '@vitro/anapath-frontend-library-api'

export class LoginRecoverPasswordClass extends PasswordClass {
    public async showLogin(): Promise<void> {
        await store.dispatch('login/changeLoginType', LoginType.LOGIN)
    }

    public disabledRecoverPassword(username: StrictNullable<string>): boolean {
        return !username && this._loading
    }

    public async recoverPassword(username: StrictNullable<string>): Promise<void> {
        this._invalid = false
        this._errorMessage = ''

        if (username) {
            try {
                this._loading = true

                const response =
                    await PromiseUtil.toCallSecure(
                        authService.recoveryPassword(username),
                        error => super.errorCallback(error))
                if (response !== null) {
                    await store.dispatch('login/changeLoginType', LoginType.RECOVER_PASSWORD_EMAIL_SENT)
                }
            } finally {
                this._loading = false
            }
        } else {
            this._invalid = true
        }
    }
}
