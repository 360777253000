import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e16109e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-component__title" }
const _hoisted_2 = { class: "login-component__subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_loading_button = _resolveComponent("loading-button")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { class: "login-component" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, { class: "login-component__header ion-margin-vertical" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, {
            class: "ion-text-center ion-margin-top",
            size: "12"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('anapathapp.label.welcome')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, {
            class: "ion-text-center ion-margin-bottom",
            size: "12"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('anapathapp.label.enterCredentials')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_row, { class: "login-component__content ion-justify-content-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, { size: "10" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { class: "login-component__input" }, {
                default: _withCtx(() => [
                  _createVNode(_component_fa_icon, {
                    icon: ['far', 'user'],
                    class: "ion-margin-end app-login__input-icon"
                  }),
                  _createVNode(_component_ion_input, {
                    modelValue: _ctx.username,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.username = $event)),
                    placeholder: _ctx.$t('anapathapp.label.user'),
                    required: "",
                    autofocus: "",
                    onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.onEnter(_ctx.username, _ctx.password)), ["enter"]))
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, {
            size: "10",
            class: "ion-padding-top"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { class: "login-component__input" }, {
                default: _withCtx(() => [
                  _createVNode(_component_fa_icon, {
                    icon: ['far', 'unlock'],
                    class: "ion-margin-end app-login__input-icon"
                  }),
                  _createVNode(_component_ion_input, {
                    modelValue: _ctx.password,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.password = $event)),
                    type: "password",
                    placeholder: _ctx.$t('anapathapp.label.password'),
                    required: "",
                    onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.onEnter(_ctx.username, _ctx.password)), ["enter"]))
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, {
            class: "ion-padding-top ion-margin-top",
            size: "10"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_loading_button, {
                class: "login-component__submit",
                mode: "md",
                expand: "block",
                shape: "round",
                strong: "",
                promise: () => _ctx.login(_ctx.username, _ctx.password)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.login')), 1)
                ]),
                _: 1
              }, 8, ["promise"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, {
            class: "ion-text-center ion-no-padding ion-margin-top",
            size: "10"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_loading_button, {
                fill: "clear",
                color: "light",
                promise: _ctx.showRecoverPassword
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.loginNoAccess')), 1)
                ]),
                _: 1
              }, 8, ["promise"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      false
        ? (_openBlock(), _createBlock(_component_ion_row, {
            key: 0,
            class: "login-component__footer ion-justify-content-center ion-padding-top"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, {
                class: "ion-text-center",
                size: "5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_loading_button, {
                    class: "login-component__footer-button ion-no-padding",
                    fill: "clear",
                    color: "light",
                    promise: _ctx.fingerPrintAlert
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            class: "ion-text-center ion-no-padding",
                            size: "12"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_fa_icon, {
                                icon: ['fal', 'fingerprint'],
                                class: "login-component__footer-icon"
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, { size: "12" }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('anapathapp.label.fingerPrintAccess')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["promise"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, {
                class: "ion-text-center",
                size: "5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_loading_button, {
                    class: "login-component__footer-button ion-no-padding",
                    fill: "clear",
                    color: "light",
                    promise: _ctx.faceIdAlert
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            class: "ion-text-center ion-no-padding",
                            size: "12"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_fa_icon, {
                                icon: ['fal', 'head-side'],
                                class: "login-component__footer-icon"
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, { size: "12" }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('anapathapp.label.faceIdAccess')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["promise"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}