import { isNavigationFailure, NavigationFailureType, RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from '@ionic/vue-router'
import { store } from '@api/lib'
import { StrictNullable } from '@/app/core/types/app-types'
import { LocaleState } from '@/app/plugins/vuex/store/root/modules/session/session-store-state.model'
import { hasMessagesLoaded, loadLanguageAsync } from '@/app/core/utils/lib/i18n-helper'
import { LoginModuleRoute, loginModuleRouter } from '@/app/views/login/login-module.router'
import { PageNotFoundRoute, pageNotFoundRouter } from '@/app/views/page-not-found/page-not-found.router'
import { modulesRouter } from '@/app/views/modules/modules.router'
import { wizardRouter } from '@/app/views/wizard/wizard.router'

/**
 * vue-router meta typing
 * see https://router.vuejs.org/guide/advanced/meta.html#typescript
 */
declare module 'vue-router' {
    interface RouteMeta {
        requiresAuth?: boolean
    }
}

const routes: RouteRecordRaw[] = [
    ...Object.values(loginModuleRouter()),
    ...Object.values(pageNotFoundRouter()),
    /// requires auth
    ...Object.values(modulesRouter()),
    ...Object.values(wizardRouter()),
    /// redirections
    { path: '/', redirect: { name: LoginModuleRoute.LOGIN } },
    { path: '/:pathMatch(.*)*', redirect: { name: PageNotFoundRoute.PAGE_NOT_FOUND } }
]

export const router =
    createRouter({
        history: createWebHistory(),
        routes
    })
router.beforeEach(async () => {
    const locale: StrictNullable<LocaleState> = store.getters['session/locale']
    if (locale && !hasMessagesLoaded(locale.code)) {
        await loadLanguageAsync(locale.code, locale.id)
    }
})
router.beforeResolve((to, _from, next) => {
    if (to.meta.requiresAuth && !store.state.userLoggedIn) {
        next({ name: LoginModuleRoute.LOGIN })
    }
    next()
})
router.afterEach(async (_to, _from, failure) => {
    if (isNavigationFailure(failure, NavigationFailureType.aborted)) {
        await router.push({ name: PageNotFoundRoute.PAGE_NOT_FOUND })
    }
})
