import { Card } from '@/app/shared/components/cards/card/card.model'

export class CardsSelectionToolbarClass<T> {
    public deselectAll(cards: Card<T>[]): void {
        for (const card of cards) {
            card.selected = false
        }
    }

    public deselectCard(cards: Card<T>[], cardToBeUnselected: Card<T>): void {
        const foundCard = cards.find(card => card.title === cardToBeUnselected.title)
        if (foundCard) {
            foundCard.selected = false
        }
    }
}
