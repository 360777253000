import { i18n, store } from '@api/lib'
import moment from 'moment'
import { i18nService } from '@api/services/i18n/i18n.service'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { LocaleState } from '@/app/plugins/vuex/store/root/modules/session/session-store-state.model'
import { forEach, isEmpty, isObject } from 'lodash'
import { LocaleMessage } from '@intlify/core-base'
import { developmentTranslations } from '@api/services/i18n/i18n.develop'

type I18nHelperFlattenTranslations = Record<string, string>
function plainToFlattenObject(object: LocaleMessage): I18nHelperFlattenTranslations {
    const result: I18nHelperFlattenTranslations = {}
    function flatten(obj: LocaleMessage, prefix = '') {
        forEach(obj, (value, key) => {
            if (isObject(value)) {
                flatten(value, `${prefix}${key}.`)
            } else {
                result[`${prefix}${key}`] = value
            }
        })
    }
    flatten(object)
    return result
}

async function setI18nLanguage(locale: string, idLocale: number): Promise<void> {
    const localeState: LocaleState = { code: locale, id: idLocale }
    await store.dispatch('session/changeLocale', localeState)

    document.querySelector('html')?.setAttribute('lang', locale)

    i18n.locale.value = locale
    moment.locale(locale)
}

export async function loadLanguageAsync(locale: string, idLocale: number): Promise<void> {
    let messages: LocaleMessage = {}

    const response =
        await PromiseUtil.toCallSecure(
            i18nService.getTranslations(idLocale))
    if (response) {
        messages =
            (process.env.NODE_ENV === 'development'
                ? { ...response, ...developmentTranslations[locale] }
                : response)
    }

    const localeMessages = i18n.getLocaleMessage(locale)
    const flattenLocaleMessages = plainToFlattenObject(localeMessages)
    const updatedLocaleMessages = {
        ...flattenLocaleMessages,
        ...messages
    }
    i18n.setLocaleMessage(locale, updatedLocaleMessages)

    return setI18nLanguage(locale, idLocale)
}

export function hasMessagesLoaded(locale: string) {
    return !isEmpty(i18n.getLocaleMessage(locale))
}
