import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!

  return (_openBlock(), _createBlock(_component_ion_segment, {
    class: "section-selector",
    mode: "md",
    value: _ctx.selectedSection.code
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
        return (_openBlock(), _createBlock(_component_ion_segment_button, {
          key: section.code,
          value: section.code,
          onClick: ($event: any) => (_ctx.changeSection(section.code))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, { class: "primary-color ion-no-margin" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(section.label)), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["value", "onClick"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["value"]))
}