import { ModuleOperation } from '@api/operations/module-operation'
import { getModule, Module } from '@api/module'
import { store } from '@api/lib'
import { router } from '@/app/router'

export class ModulesClass {
    public get moduleTabs(): Module[] {
        const modules: Module[] = [...store.state.modules]

        const profileModule = getModule(ModuleOperation.PROFILE)
        if (profileModule !== null) {
            modules.push(profileModule)
        }

        const helpModule = getModule(ModuleOperation.HELP)
        if (helpModule !== null) {
            modules.push(helpModule)
        }

        return modules
    }

    public get routePath(): string {
        return router.currentRoute.value.path
    }
}
