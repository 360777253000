import { i18n, store } from '@api/lib'
import { Formatter } from '@/app/core/formatter'
import { StrictNullable } from '@/app/core/types/app-types'
import { UserInformationDetailResponse } from '@api/services/common/initialization/common-initialization.dto'
import { UserProfileUtil } from '@/app/core/utils/user-profile-util'

export class ProfileUserInfoClass {
    private static get _userInformation(): StrictNullable<UserInformationDetailResponse> {
        return store.state.userInformation
    }

    public get userLastProfileImage(): StrictNullable<string> {
        return store.state.userLastProfileImage
    }
    public get fullName(): string {
        if (ProfileUserInfoClass._userInformation) {
            const user = ProfileUserInfoClass._userInformation.petitioner ?? ProfileUserInfoClass._userInformation.personnel
            if (user) {
                return [
                    user.name,
                    user.middleName,
                    user.surname1,
                    user.surname2
                ].join(' ')
            }
        }
        return ''
    }
    public get job(): string {
        if (ProfileUserInfoClass._userInformation) {
            if (ProfileUserInfoClass._userInformation.petitioner) {
                return i18n.t('anapathapp.label.petitionerJob')
            }
            if (ProfileUserInfoClass._userInformation.personnel) {
                return ProfileUserInfoClass._userInformation.personnel.job?.translatedName ?? ''
            }
        }
        return ''
    }
    public get penultimateAccess(): string {
        return Formatter.datetime(ProfileUserInfoClass._userInformation?.penultimateAccess)
    }

    public async changeUserProfileImage(profileImage: File): Promise<void> {
        await UserProfileUtil.changeUserProfileImage(profileImage)
    }
    public async deleteUserProfileImage(): Promise<void> {
        await UserProfileUtil.changeUserProfileImage()
    }
}
