export enum SubportionType {
    SLIDE = 'SLIDE',
    STERILE_TUBE = 'STERILE_TUBE',
    GRILLE = 'GRILLE'
}

export interface CommonSubportionTypeResponse {
    sptId: number
    subportionTypeEnum: SubportionType
    sptName: string
    sptActive: boolean
    sptOriginalName: string
}
