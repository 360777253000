import { archiveDatasourceFactory } from '@/app/views/modules/components/archive/datasources/archive-datasource-factory'
import {
    ArchiveModuleSectionType
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'
import { WasteBannerParams } from '@/app/views/modules/components/waste/components/banner/waste-banner.model'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { router } from '@/app/router'
import { store } from '@api/lib'
import { wasteSampleFilterService } from '@api/services/waste/sample-filter/waste-sample-filter.service'
import { ModuleOperation } from '@api/operations/module-operation'
import { ModuleUtil } from '@/app/core/utils/module-util'
import { StoreUtil } from '@/app/core/utils/lib/store-util'
import { ArchiveModuleRoute } from '@/app/views/modules/components/archive/archive-module.router'

export class WasteBannerClass {
    private static readonly _UN_ARCHIVING_REASON_DISPOSAL = 'unarchiving-reason.disposal'

    private _visible: boolean

    public params: WasteBannerParams

    public get visible(): boolean {
        return this._visible
    }

    public get hasArchiveAccess(): boolean {
        return ModuleUtil.hasModuleAccess(ModuleOperation.ARCHIVE)
    }

    constructor(params: WasteBannerParams) {
        this.params = params

        this._visible = false
    }

    public async checkPendingToTakeOutFromArchiveByDisposal(): Promise<void> {
        const response =
            await PromiseUtil.toCallSecure(
                wasteSampleFilterService.paginator(StoreUtil.labId, 'toBeUnarchivedToDiscard'))
        if (response !== null) {
            this._visible = !!response.totalElements
        }
    }

    public async goToTakeOutByDisposal(): Promise<void> {
        const datasource =
            archiveDatasourceFactory.getEntityDatasource(
                ArchiveModuleSectionType.PENDING_TO_BE_UN_ARCHIVED,
                this.params.entityType)
        if (datasource !== null) {
            const response = await PromiseUtil.toCallSecure(datasource.getPendingUnArchivingReasons())
            const disposalReason =
                response?.find(reason => reason.code === WasteBannerClass._UN_ARCHIVING_REASON_DISPOSAL)
            if (disposalReason) {
                await store.dispatch('appModules/archive/pendingToBeUnArchived/changePendingUnArchivingReason', disposalReason)
                await router.push({
                    name: ArchiveModuleRoute.ARCHIVE,
                    params: {
                        initialEntityType: this.params.entityType,
                        initialSectionType: ArchiveModuleSectionType.PENDING_TO_BE_UN_ARCHIVED
                    }
                })
            }
        }
    }

    public hide(): void {
        this._visible = false
    }
}
