import { EmitFn } from '@api/lib'
import { StrictNullable } from '@/app/core/types/app-types'
import { CommonUnarchivingReasonResponse } from '@api/services/archive/common/archive-common.dto'

export enum TakeOutReasonSelectorEmitEvent {
    CHANGE = 'change'
}

export interface TakeOutReasonSelectorParams {
    emit: EmitFn<TakeOutReasonSelectorEmitEvent>

    reason: StrictNullable<CommonUnarchivingReasonResponse>
}
