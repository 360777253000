import { axios } from '@vitro/anapath-frontend-library-api'
import {
    UIComponentCustomFilterRequest,
    UIComponentCustomFilterResponse
} from '@api/services/ui-component/custom-filter/ui-component-custom-filter.dto'

class UiComponentCustomFilterService {
    /**
     * Retrieves the UI component custom filters of a given component
     * @param componentCode component code
     * @param laboratoryId laboratory ID
     */
    async get<T>(
        componentCode: string,
        laboratoryId?: number
    ): Promise<UIComponentCustomFilterResponse<T>[]> {
        const response = await axios.get<UIComponentCustomFilterResponse<T>[]>(
            `/uicomponents/${componentCode}/customfilters`,
            { params: { laboratoryId } })
        return response.data
    }

    /**
     * Retrieves a UI component custom filter by ID
     * @param componentCode component code
     * @param customFilterId UI component custom filter ID
     */
    async getByCustomFilterId<T>(
        componentCode: string,
        customFilterId: number
    ): Promise<UIComponentCustomFilterResponse<T>> {
        const response = await axios.get<UIComponentCustomFilterResponse<T>>(
            `/uicomponents/${componentCode}/customfilters/${customFilterId}`)
        return response.data
    }

    /**
     * Creates a new UI component custom filter
     * @param componentCode component code
     * @param customFilter customFilter
     * @param laboratoryId laboratory ID
     */
    async create<T>(
        componentCode: string,
        customFilter: UIComponentCustomFilterRequest<T>,
        laboratoryId?: number
    ): Promise<UIComponentCustomFilterResponse<T>> {
        const response = await axios.post<UIComponentCustomFilterResponse<T>>(
            `/uicomponents/${componentCode}/customfilters`,
            customFilter,
            { params: { laboratoryId } })
        return response.data
    }

    /**
     * Updates a UI component custom filter
     * @param componentCode component code
     * @param componentCustomization componentCustomization
     * @param customFilterId component customization ID
     * @param laboratoryId laboratory ID
     */
    async update<T>(
        componentCode: string,
        componentCustomization: UIComponentCustomFilterRequest<T>,
        customFilterId: number,
        laboratoryId?: number
    ): Promise<UIComponentCustomFilterResponse<T>> {
        const response = await axios.put<UIComponentCustomFilterResponse<T>>(
            `/uicomponents/${componentCode}/customfilters/${customFilterId}`,
            componentCustomization,
            { params: { laboratoryId } })
        return response.data
    }

    /**
     * Deletes a UI component custom filter
     * @param componentCode component code
     * @param customFilterId UI component custom filter ID
     * @param laboratoryId laboratory ID
     */
    async delete(
        componentCode: string,
        customFilterId: number,
        laboratoryId?: number
    ): Promise<void> {
        const response = await axios.delete<void>(
            `/uicomponents/${componentCode}/customfilters/${customFilterId}`,
            { params: { laboratoryId } })
        return response.data
    }
}
export const uiComponentCustomFilterService = new UiComponentCustomFilterService()
