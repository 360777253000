import { EmitFn } from '@api/lib'
import {
    ArchiveModuleSectionType,
    ArchiveSectionSelectorEmitEvent,
    ArchiveSectionSelectorTypeModel,
    getArchiveSectionTypes
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'
import { StrictNullable } from '@/app/core/types/app-types'

export class ArchiveSectionSelectorClass {
    private readonly _emit: EmitFn<ArchiveSectionSelectorEmitEvent>

    public get sectionTypes(): ArchiveSectionSelectorTypeModel[] {
        return getArchiveSectionTypes()
    }

    constructor(emit: EmitFn<ArchiveSectionSelectorEmitEvent>) {
        this._emit = emit
    }

    public getSelectedSection(
        archiveSectionType: ArchiveModuleSectionType
    ): StrictNullable<ArchiveSectionSelectorTypeModel> {
        const selected =
            this.sectionTypes.find(
                sectionType => sectionType.code === archiveSectionType)
        return selected ?? null
    }

    public changeSection(archiveSectionType: ArchiveModuleSectionType): void {
        this._emit(ArchiveSectionSelectorEmitEvent.CHANGE, archiveSectionType)
    }
}
