import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32b9a3d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-100 profile-change-password" }
const _hoisted_2 = { class: "ion-padding-vertical" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_loading_button = _resolveComponent("loading-button")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_button, {
              slot: "start",
              fill: "clear",
              color: "primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goBack()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_fa_icon, { icon: ['far', 'arrow-left'] })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, { class: "font-size--xl primary-dark-color ion-no-padding" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.changePassword')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_row, { class: "ion-text-center ion-padding ion-margin" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, { size: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_fa_icon, {
                  class: "profile-change-password-icon",
                  icon: ['fal', 'lock']
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_col, { size: "12" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.label.passwordRuleDescription')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, {
              class: "primary-dark-color",
              position: "floating"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.label.currentPassword')) + " * ", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_input, {
              modelValue: _ctx.currentPassword,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentPassword = $event)),
              type: "password",
              required: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, {
              class: "primary-dark-color",
              position: "floating"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.label.newPassword')) + " * ", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_input, {
              modelValue: _ctx.password,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.password = $event)),
              type: "password",
              required: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, {
              class: "primary-dark-color",
              position: "floating"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.label.repeatNewPassword')) + " * ", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_input, {
              modelValue: _ctx.passwordConfirmation,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.passwordConfirmation = $event)),
              type: "password",
              required: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_loading_button, {
            size: "large",
            class: "ion-margin",
            expand: "block",
            promise: () => _ctx.changePassword(_ctx.currentPassword, _ctx.password, _ctx.passwordConfirmation)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.applyNewPassword')), 1)
            ]),
            _: 1
          }, 8, ["promise"])
        ])
      ]),
      _: 1
    })
  ]))
}