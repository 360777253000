import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_archive_pending_to_be_un_archived_filter_action_pending_un_archiving_reason = _resolveComponent("archive-pending-to-be-un-archived-filter-action-pending-un-archiving-reason")!
  const _component_archive_pending_to_be_un_archived_filter_action_location = _resolveComponent("archive-pending-to-be-un-archived-filter-action-location")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_archive_pending_to_be_un_archived_filter_action_pending_un_archiving_reason, {
      "reason-code": _ctx.pendingUnArchivingReasonCode,
      datasource: _ctx.datasource,
      onClear: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearPendingUnArchivingReasonFilter())),
      onFiltered: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addPendingUnArchivingReasonFilter($event)))
    }, null, 8, ["reason-code", "datasource"]),
    _createVNode(_component_archive_pending_to_be_un_archived_filter_action_location, {
      onClear: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clearLocationFilters())),
      onFiltered: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addLocationFilter($event)))
    })
  ], 64))
}