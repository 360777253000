import { EmitFn } from '@api/lib'
import {
    ArchivePendingToBeUnArchivedSelectionToolbarEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/selection-toolbar/archive-pending-to-be-un-archived-selection-toolbar.model'
import {
    ArchiveEntityIdentifiersListRequest,
    ArchiveEntityResponse
} from '@/app/views/modules/components/archive/datasources/archive-datasource-factory'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { StoreUtil } from '@/app/core/utils/lib/store-util'
import { Card } from '@/app/shared/components/cards/card/card.model'
import { ArchiveEntityDatasource } from '@/app/views/modules/components/archive/datasources/entities/archive-entity.datasource'

export class ArchivePendingToBeUnArchivedSelectionToolbarClass {
    private readonly _emit: EmitFn<ArchivePendingToBeUnArchivedSelectionToolbarEmitEvent>
    private _elementsWithoutError: number
    private _visibleConfirmationSummary: boolean

    public get elementsWithoutError(): number {
        return this._elementsWithoutError
    }
    public get visibleConfirmationSummary(): boolean {
        return this._visibleConfirmationSummary
    }

    constructor(emit: EmitFn<ArchivePendingToBeUnArchivedSelectionToolbarEmitEvent>) {
        this._emit = emit
        this._elementsWithoutError = 0
        this._visibleConfirmationSummary = false
    }

    public async takeOut(
        cards: Card<ArchiveEntityResponse>[],
        datasource: ArchiveEntityDatasource
    ): Promise<void> {
        const request: ArchiveEntityIdentifiersListRequest = {
            entityIdentifiers:
                    cards.map(({ data }) => datasource.getEntityHierarchicalIds(data))
        }
        const errors =
                await PromiseUtil.toCallSecure(
                    datasource.unarchivePendingToBeUnarchived(StoreUtil.labId, request))
        this._elementsWithoutError = cards.length - (errors?.length ?? 0)
        this._visibleConfirmationSummary = true
    }

    public confirm(): void {
        this._visibleConfirmationSummary = false
        this._emit(ArchivePendingToBeUnArchivedSelectionToolbarEmitEvent.REFRESH)
    }
}
