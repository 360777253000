import { IconDefinitionCustom } from '@/app/plugins/font-awesome/custom-icons-packs/custom-icons-packs.model'
import {
    IconNameVitro,
    IconPrefixVitro
} from '@/app/plugins/font-awesome/custom-icons-packs/vitro/vitro-icon-pack.model'

export const prefix: IconPrefixVitro = 'vt'
export const iconName: IconNameVitro = 'slide'
export const width = 1073
export const height = 1073
export const ligatures: string[] = []
export const unicode = ''
export const svgPathData = 'M287.5 963.7L63.9 740 769.5 34.4 993.2 258 287.5 963.7ZM130.5 713.1L314.3 896.9 926.4 284.8 742.6 101 130.5 713.1ZM737.5 80.9L945.1 288.5 737.5 496.3 529.7 288.5 737.5 80.9Z'

export const definition: IconDefinitionCustom<IconPrefixVitro, IconNameVitro> = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]}
export const faSlide: IconDefinitionCustom<IconPrefixVitro, IconNameVitro> = definition
