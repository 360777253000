import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-110e40c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "take-out-return-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.returnOptions.length)
      ? (_openBlock(), _createBlock(_component_ion_list, {
          key: 0,
          lines: "full"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_radio_group, {
              value: _ctx.selectedReturnOption,
              onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectReturnOption($event.detail.value)))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.returnOptions, (returnOption) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: returnOption.code
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(returnOption.getLabel()), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_radio, {
                        slot: "end",
                        value: returnOption.code
                      }, null, 8, ["value"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}