import { axios } from '@vitro/anapath-frontend-library-api'
import { CommonFilterModel, CommonParams, Page } from '@/app/shared/models/common.model'
import {
    ArchivePortionFilterPaginatorType,
    ArchivePortionGridResponse
} from '@api/services/archive/portion-filter/archive-portion-filter.dto'
import { PortionType } from '@api/services/common/portion-type/common-portion-type.dto'
import { PendingUnarchivingReasonResponse } from '@api/services/archive/archive.dto'

class ArchivePortionFilterService {
    public async getPortionPendingUnArchivingReasons(): Promise<PendingUnarchivingReasonResponse[]> {
        const response =
            await axios.get<PendingUnarchivingReasonResponse[]>(
                `/archive/laboratories/portionPendingUnarchivingReasons`)
        return response.data
    }

    public async paginator(
        laboratoryId: number,
        entitySubtype: PortionType,
        url: ArchivePortionFilterPaginatorType,
        filterModel?: CommonFilterModel,
        params?: CommonParams
    ): Promise<Page<ArchivePortionGridResponse>> {
        const response =
            await axios.put<Page<ArchivePortionGridResponse>>(
                `/archive/laboratories/${laboratoryId}/cases/samples/portions/${url}`,
                { ...filterModel },
                { params: { entitySubtype, ...params} })
        return response.data
    }
}
export const archivePortionFilterService = new ArchivePortionFilterService()
