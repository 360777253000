import { RootStoreState } from '@/app/plugins/vuex/store/root/root-store-state.model'
import { MutationPayload, Plugin, Store } from 'vuex'
import { loadingController } from '@api/lib/overlay'

export const loadingChangedStorePlugin: Plugin<RootStoreState> =
    (store: Store<RootStoreState>) => {
        store.subscribe(async (mutation: MutationPayload) => {
            if (mutation.type === 'updateLoading') {
                const loading: boolean = mutation.payload
                if (loading) {
                    const loadingOverlay =
                        await loadingController.create({
                            showBackdrop: true
                        })
                    await loadingOverlay.present()

                    // check if loading state changed during overlay creation
                    if (!store.state.loading) {
                        await loadingOverlay.dismiss()
                    }
                } else {
                    await loadingController.dismiss()
                }
            }
        })
    }
