import { defineComponent, reactive } from 'vue'
import { WizardClass } from '@/app/views/wizard/wizard.class'
import Slides from '@/app/shared/components/slides/slides.vue'

export default defineComponent({
    name: 'Wizard',
    components: {
        Slides
    },
    props: {
        routeName: { type: String, required: true }
    },
    setup(props) {
        const _class = reactive(new WizardClass(props.routeName))
        return {
            /// METHODS
            goToRoute: () => _class.goToRoute()
        }
    }
})
