import { RouteRecordRaw } from 'vue-router'

export enum HelpModuleRoute {
    HELP = 'Help'
}

export const helpModuleRouter =
    (): Record<HelpModuleRoute, RouteRecordRaw> => ({
        [HelpModuleRoute.HELP]: {
            path: 'help',
            name: HelpModuleRoute.HELP,
            component: () => import('@/app/views/modules/components/help/help-module.vue')
        }
    })
