import { defineComponent, PropType, ref } from 'vue'
import { getSliderContent, SlideContent } from '@/app/shared/components/slides/slides.model'

export default defineComponent({
    name: 'Slides',
    props: {
        action: { type: Function as PropType<() => void | Promise<void>>, required: true }
    },
    setup() {
        return {
            /// DATA
            sliderContent: ref<SlideContent[]>(getSliderContent())
        }
    }
})
