import { router } from '@/app/router'

export class WizardClass {
    private readonly _routeName: string

    constructor(routeName: string) {
        this._routeName = routeName
    }

    public async goToRoute(): Promise<void> {
        await router.replace({ name: this._routeName })
    }
}
