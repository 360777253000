import { Module } from 'vuex'
import { ModulesArchiveStoreState } from '@/app/views/modules/store/modules/archive/modules-archive-store-state.model'
import { RootStoreState } from '@/app/plugins/vuex/store/root/root-store-state.model'
import {
    modulesArchivePendingToBeUnArchivedStoreModule
} from '@/app/views/modules/store/modules/archive/modules/pending-to-be-un-archived/modules-archive-pending-to-be-un-archived-store-module'

const initialModulesArchiveStoreState =
    (): ModulesArchiveStoreState => ({})

export const modulesArchiveStoreModule: Module<ModulesArchiveStoreState, RootStoreState> = {
    namespaced: true,
    state: initialModulesArchiveStoreState,
    mutations: {
        resetState:
            (state): void => {
                Object.assign(state, initialModulesArchiveStoreState())
            }
    },
    actions: {
        reset:
            ({ commit }): void => {
                commit('resetState')
            }
    },
    modules: {
        pendingToBeUnArchived: modulesArchivePendingToBeUnArchivedStoreModule
    }
}
