import { EmitFn } from '@api/lib'
import { FilterChipEmitEvent } from '@/app/shared/components/filter-chip/filter-chip.model'

export class FilterChipClass {
    private readonly _emit: EmitFn<FilterChipEmitEvent>

    constructor(emit: EmitFn<FilterChipEmitEvent>) {
        this._emit = emit
    }

    public removeFilter(): void {
        this._emit(FilterChipEmitEvent.REMOVED)
    }
}
