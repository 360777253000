import { defineComponent, reactive } from 'vue'
import { LoginRecoverPasswordEmailSentClass } from '@/app/views/login/components/recover-password-email-sent/login-recover-password-email-sent.class'

export default defineComponent({
    name: 'LoginRecoverPasswordEmailSent',
    setup() {
        const _class = reactive(new LoginRecoverPasswordEmailSentClass())
        return {
            /// METHODS
            showLogin: () => _class.showLogin(),
            showRecoverPassword: () => _class.showRecoverPassword()
        }
    }
})
