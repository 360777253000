import { axios } from '@vitro/anapath-frontend-library-api'
import {
    UIComponentCustomConfigRequest,
    UIComponentCustomConfigResponse
} from '@api/services/ui-component/custom-config/ui-component-custom-config.dto'

class UiComponentCustomConfigService {
    /**
     * Retrieves the UI component customizations of the logged user by component name and, optionally, by laboratory
     * @param componentCode component code
     * @param laboratoryId laboratory ID
     */
    async get<T>(
        componentCode: string,
        laboratoryId?: number
    ): Promise<UIComponentCustomConfigResponse<T>> {
        const response = await axios.get<UIComponentCustomConfigResponse<T>>(
            `/uicomponents/${componentCode}/customconfigs`,
            { params: { laboratoryId } })
        return response.data
    }

    /**
     * Retrieves a UI component custom config by ID
     * @param componentCode component code
     * @param customConfigId UI component custom config ID
     */
    async getByCustomConfigId<T>(
        componentCode: string,
        customConfigId: number
    ): Promise<UIComponentCustomConfigResponse<T>> {
        const response = await axios.get<UIComponentCustomConfigResponse<T>>(
            `/uicomponents/${componentCode}/customconfigs/${customConfigId}`)
        return response.data
    }

    /**
     * Creates a new UI component custom configuration
     * @param componentCode component code
     * @param customConfig customFilter
     * @param laboratoryId laboratory ID
     */
    async create<T>(
        componentCode: string,
        customConfig: UIComponentCustomConfigRequest<T>,
        laboratoryId?: number
    ): Promise<UIComponentCustomConfigResponse<T>> {
        const response = await axios.post<UIComponentCustomConfigResponse<T>>(
            `/uicomponents/${componentCode}/customconfigs`,
            customConfig, { params: { laboratoryId } })
        return response.data
    }

    /**
     * Updates a UI component custom config
     * @param componentCode component code
     * @param componentCustomization componentCustomization
     * @param customConfigId component custom config ID
     * @param laboratoryId laboratory ID
     */
    async update<T>(
        componentCode: string,
        componentCustomization: UIComponentCustomConfigRequest<T>,
        customConfigId: number,
        laboratoryId?: number
    ): Promise<UIComponentCustomConfigResponse<T>> {
        const response = await axios.put<UIComponentCustomConfigResponse<T>>(
            `/uicomponents/${componentCode}/customconfigs/${customConfigId}`,
            componentCustomization,
            { params: { laboratoryId } })
        return response.data
    }

    /**
     * Deletes a UI component customization
     * @param componentCode component code
     * @param customConfigId UI component custom config ID
     * @param laboratoryId laboratory ID
     */
    async delete(
        componentCode: string,
        customConfigId: number,
        laboratoryId?: number
    ): Promise<void> {
        const response = await axios.delete<void>(
            `/uicomponents/${componentCode}/customconfigs/${customConfigId}`,
            { params: { laboratoryId } })
        return response.data
    }
}
export const uiComponentCustomConfigService = new UiComponentCustomConfigService()
