import { auth } from '@api/auth'
import { i18n, store } from '@api/lib'
import { commonInitializationService } from '@api/services/common/initialization/common-initialization.service'
import { HelperUtil } from '@/app/core/utils/helper-util'
import { Optional, StrictNullable } from '@/app/core/types/app-types'
import SockJS from 'sockjs-client'
import Stomp, { Client } from 'webstomp-client'
import { clinicalInitializationService } from '@api/services/clinical/initialization/clinical-initialization.service'
import {
    IdentifierRegexResponse,
    LaboratoryConfigurationResponse,
    UserType
} from '@api/services/common/initialization/common-initialization.dto'
import { loadLanguageAsync } from '@/app/core/utils/lib/i18n-helper'
import { getLaboratoryModules, getModule } from '@api/module'
import { ModuleOperation } from '@api/operations/module-operation'
import { UiComponentUtil } from '@/app/core/utils/ui-component-util'
import {
    UIComponentCustomConfigResponse
} from '@api/services/ui-component/custom-config/ui-component-custom-config.dto'
import {
    UserDefaultAccessUiComponentConfig,
    UserDefaultLabModule
} from '@/app/plugins/vuex/store/root/root-store-state.model'

class SessionStore {
    private static readonly _USER_DEFAULT_ACCESS_UI_COMPONENT_CODE = 'uicomponent.anapathapp.userapp.defaultaccess'
    private _stompClient: StrictNullable<Client>

    public get isPersonnelUser(): boolean {
        return store.state.userInformation?.userType === UserType.PERSONNEL
    }
    public get isPetitionerUser(): boolean {
        return store.state.userInformation?.userType === UserType.PETITIONER
    }

    constructor() {
        this._stompClient = null
    }

    public reset(): void {
        if (this._stompClient) {
            try {
                this._stompClient.disconnect()
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e)
            }
        }
    }

    private static async _loadGlobalParameters(): Promise<void> {
        const globalParameters = await commonInitializationService.getGlobalParameters()
        await store.dispatch('changeGlobalParameters', globalParameters)
    }

    private static async _loadLaboratoriesConfiguration(): Promise<void> {
        const laboratoriesConfiguration = await commonInitializationService.getLaboratoriesConfiguration()
        await store.dispatch('changeLaboratoriesConfiguration', laboratoriesConfiguration)
    }

    private static async _loadUserInformation(): Promise<void> {
        const userInformation = await commonInitializationService.getUserInformation()
        await store.dispatch('changeUserInformation', userInformation)

        if (
            userInformation.uiLanguage.uiEnabled
            && i18n.locale.value !== userInformation.uiLanguage.locale
        ) {
            await loadLanguageAsync(userInformation.uiLanguage.locale, userInformation.uiLanguage.id)
        }

        const labId: StrictNullable<number> = store.getters['session/labId']
        if (!labId) {
            const userLabId = userInformation?.personnel?.defaultLaboratory?.labId
            if (userLabId) {
                await store.dispatch('session/changeLabId', userLabId)
            }
        }
    }

    private static _mapDefaultLabModules(
        laboratoriesConfiguration: LaboratoryConfigurationResponse[]
    ): UserDefaultLabModule[] {
        const profileModule = getModule(ModuleOperation.PROFILE)
        if (profileModule !== null) {
            return laboratoriesConfiguration.map(({ laboratory, modules }) => {
                let moduleCode = ModuleOperation.PROFILE
                const laboratoryModules =
                    modules.filter(module => getModule(module.moduleCode as ModuleOperation) !== null)
                if (laboratoryModules.length) {
                    moduleCode = laboratoryModules[0].moduleCode as ModuleOperation
                }
                return { laboratory, moduleCode }
            })
        }
        return []
    }
    public async saveUserDefaultAccess(
        userDefaultAccessUiComponentConfig: UserDefaultAccessUiComponentConfig
    ): Promise<StrictNullable<UserDefaultAccessUiComponentConfig>> {
        let config: Optional<UserDefaultAccessUiComponentConfig>
        if (this.isPersonnelUser) {
            const { defaultLab, defaultLabModules } = userDefaultAccessUiComponentConfig
            config = { defaultLab, defaultLabModules }
        } else if (this.isPetitionerUser) {
            const { moduleCode } = userDefaultAccessUiComponentConfig
            config = { moduleCode }
        }

        if (config) {
            store.commit('updateDefaultAccess', config)
            await UiComponentUtil.saveCustomConfig<UserDefaultAccessUiComponentConfig>(
                SessionStore._USER_DEFAULT_ACCESS_UI_COMPONENT_CODE,
                { config })
            return config
        }
        return null
    }
    private async _loadPersonnelUserDefaultAccess(
        userDefaultAccessUiComponent: StrictNullable<UIComponentCustomConfigResponse<UserDefaultAccessUiComponentConfig>>
    ): Promise<void> {
        const config = userDefaultAccessUiComponent?.config
        const defaultLabId = config?.defaultLab?.labId
        if (!defaultLabId) {
            const userDefaultLabId = store.getters.userDefaultLabId

            const labConf =
                store.state.laboratoriesConfiguration.find(
                    laboratoryConfiguration => laboratoryConfiguration.laboratory.labId === userDefaultLabId)
            const userDefaultAccessUiComponentConfig: UserDefaultAccessUiComponentConfig = {
                defaultLab: labConf?.laboratory,
                defaultLabModules: SessionStore._mapDefaultLabModules(store.state.laboratoriesConfiguration)
            }
            await this.saveUserDefaultAccess(userDefaultAccessUiComponentConfig)
            await store.dispatch('session/changeLabId', userDefaultLabId)
        } else {
            await store.dispatch('changeDefaultAccess', config)

            const labId = store.getters['session/labId'] ?? defaultLabId
            await store.dispatch('session/changeLabId', labId)
        }
    }
    private async _loadPetitionerUserDefaultAccess(
        userDefaultAccessUiComponent: StrictNullable<UIComponentCustomConfigResponse<UserDefaultAccessUiComponentConfig>>
    ): Promise<void> {
        const config = userDefaultAccessUiComponent?.config
        if (!config) {
            const moduleCode =
                store.state.modules.length
                    ? store.state.modules[0].moduleCode
                    : ModuleOperation.PROFILE
            const userDefaultAccessUiComponentConfig = { moduleCode }
            await this.saveUserDefaultAccess(userDefaultAccessUiComponentConfig)
        } else {
            store.commit('updateDefaultAccess', config)
        }

        const modules = getLaboratoryModules(store.state.laboratoriesConfiguration)
        if (modules.length) {
            store.commit('updateModules', modules)
        }
    }
    private async _loadUserDefaultAccess(): Promise<void> {
        const userDefaultAccessUiComponent =
            await UiComponentUtil.getCustomConfig<UserDefaultAccessUiComponentConfig>(
                SessionStore._USER_DEFAULT_ACCESS_UI_COMPONENT_CODE)
        if (this.isPersonnelUser) {
            await this._loadPersonnelUserDefaultAccess(userDefaultAccessUiComponent)
        } else if (this.isPetitionerUser) {
            await this._loadPetitionerUserDefaultAccess(userDefaultAccessUiComponent)
        }
    }

    private async _loadIdentifierRegexes(): Promise<void> {
        let identifierRegexes: IdentifierRegexResponse[]
        if (this.isPetitionerUser) {
            identifierRegexes = await clinicalInitializationService.getIdentifierRegexes()
        } else {
            identifierRegexes = await commonInitializationService.getIdentifierRegexes()
        }
        await store.dispatch('changeIdentifierRegexes', identifierRegexes)
    }

    private async _createWebsocketConnectionIfNotSet(): Promise<void> {
        if (!this._stompClient) {
            const token = auth.state?.accessToken
            if (token) {
                const socket =
                    new SockJS(HelperUtil.webSocketUrl + '/main/?authenticationToken=' + token)
                const stompClient =
                    Stomp.over(socket, {
                        debug: false,
                        heartbeat: { outgoing: 120000, incoming: 120000 }
                    })
                try {
                    stompClient.connect(
                        {},
                        () => (this._stompClient = stompClient),
                        (error: unknown) => {
                            // eslint-disable-next-line no-console
                            console.error(error)

                            stompClient.disconnect(() => {
                                this._stompClient = null
                                setTimeout(() => this._createWebsocketConnectionIfNotSet(), 10000)
                            })
                        }
                    )
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e)
                }
            }
        }
    }

    public async update(): Promise<void> {
        if (!store.state.userLoggedIn) {
            this.reset()
        } else {
            await Promise.all([
                SessionStore._loadGlobalParameters(),
                SessionStore._loadLaboratoriesConfiguration()
            ])
            await SessionStore._loadUserInformation()
            await Promise.all([
                this._loadUserDefaultAccess(),
                this._loadIdentifierRegexes()
            ])
            await this._createWebsocketConnectionIfNotSet()
        }
    }
}
export const sessionStore = new SessionStore()
