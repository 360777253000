import { computed, defineComponent, reactive } from 'vue'
import { PageNotFoundClass } from '@/app/views/page-not-found/page-not-found.class'

export default defineComponent({
    name: 'PageNotFound',
    setup() {
        const _class = reactive(new PageNotFoundClass())
        return {
            /// COMPUTED
            userLoggedIn: computed(() => _class.userLoggedIn),
            profileModule: computed(() => _class.profileModule),
            /// METHODS
            goBack: () => _class.goBack(),
            goToProfileRoute: () => _class.goToProfileRoute()
        }
    }
})
