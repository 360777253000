import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86aa3910"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sticky-header" }
const _hoisted_2 = { class: "section-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_entity_selector = _resolveComponent("entity-selector")!
  const _component_loading_button = _resolveComponent("loading-button")!
  const _component_cards_selection_toolbar = _resolveComponent("cards-selection-toolbar")!
  const _component_confirmation_summary = _resolveComponent("confirmation-summary")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_waste_banner = _resolveComponent("waste-banner")!
  const _component_searcher = _resolveComponent("searcher")!
  const _component_cards_list_sort = _resolveComponent("cards-list-sort")!
  const _component_cards_list_header = _resolveComponent("cards-list-header")!
  const _component_cards_skeleton = _resolveComponent("cards-skeleton")!
  const _component_card = _resolveComponent("card")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_cards_empty_state = _resolveComponent("cards-empty-state")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { class: "waste-module" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher, {
            slot: "fixed",
            onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.refreshData($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_header, { mode: "md" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_toolbar, null, {
                  default: _withCtx(() => [
                    (!_ctx.selectedCards.length)
                      ? (_openBlock(), _createBlock(_component_entity_selector, {
                          key: 0,
                          "entity-type": _ctx.entityType,
                          disabled: "",
                          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeEntity($event)))
                        }, null, 8, ["entity-type"]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createVNode(_component_cards_selection_toolbar, { cards: _ctx.selectedCards }, {
                            default: _withCtx(() => [
                              _createVNode(_component_loading_button, {
                                fill: "solid",
                                class: "ion-margin-horizontal",
                                color: "primary",
                                promise: () => _ctx.discardSamples(_ctx.selectedCards)
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.discard')), 1)
                                ]),
                                _: 1
                              }, 8, ["promise"])
                            ]),
                            _: 1
                          }, 8, ["cards"]),
                          _createVNode(_component_ion_modal, {
                            mode: "md",
                            "is-open": _ctx.visibleConfirmationSummary,
                            component: "",
                            onDidDismiss: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSorted(_ctx.sortModel)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_confirmation_summary, {
                                icon: "trash",
                                title: _ctx.$t('anapathapp.label.youHaveDiscarded'),
                                counter: _ctx.elementsWithoutError,
                                onConfirm: _cache[2] || (_cache[2] = ($event: any) => (_ctx.confirm()))
                              }, null, 8, ["title", "counter"])
                            ]),
                            _: 1
                          }, 8, ["is-open"])
                        ], 64))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_waste_banner, { "entity-type": _ctx.entityType }, null, 8, ["entity-type"]),
            (_ctx.cards.length)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.cards.length)
                    ? (_openBlock(), _createBlock(_component_searcher, {
                        key: 0,
                        id: "waste-module",
                        "error-message": _ctx.$t('anapathapp.waste.message.validIdentifier'),
                        "sample-searching": "",
                        onSampleSearch: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onSampleSearch($event)))
                      }, null, 8, ["error-message"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_cards_list_header, {
                    pending: _ctx.totalElements,
                    title: _ctx.$t('anapathapp.label.wastePending', _ctx.cards.length)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_cards_list_sort, {
                        "col-defs": _ctx.colDefs,
                        "initial-sort": _ctx.sortModel,
                        onSorted: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onSorted($event)))
                      }, null, 8, ["col-defs", "initial-sort"])
                    ]),
                    _: 1
                  }, 8, ["pending", "title"])
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_cards_skeleton, { key: 0 }))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.cards.length)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card) => {
                          return (_openBlock(), _createBlock(_component_card, {
                            key: card.title,
                            card: card
                          }, null, 8, ["card"]))
                        }), 128)),
                        _createVNode(_component_ion_infinite_scroll, {
                          id: "infinite-scroll",
                          threshold: "100px",
                          disabled: _ctx.infiniteScrollDisabled,
                          onIonInfinite: _cache[6] || (_cache[6] = ($event: any) => (_ctx.loadInfiniteScrollData($event)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_infinite_scroll_content, { "loading-spinner": "circles" })
                          ]),
                          _: 1
                        }, 8, ["disabled"])
                      ], 64))
                    : (_openBlock(), _createBlock(_component_cards_empty_state, { key: 1 }))
                ], 64))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}