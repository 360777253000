import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a4ab941"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "take-out-conflicts" }
const _hoisted_2 = { class: "take-out-conflicts__container" }
const _hoisted_3 = { class: "take-out-conflicts__title ion-padding ion-justify-content-between" }
const _hoisted_4 = { class: "take-out-conflicts__content" }
const _hoisted_5 = { class: "ion-margin-vertical ion-padding-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_item_group = _resolveComponent("ion-item-group")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_loading_button = _resolveComponent("loading-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                slot: "start",
                fill: "clear",
                color: "primary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goBack()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_fa_icon, { icon: ['far', 'arrow-left'] })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, { class: "font-size--xl primary-dark-color" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.label.takeOutConflict', _ctx.cards.length)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('anapathapp.message.takeOutSelection', _ctx.cards.length)), 1),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingUnarchivingReasons, (pendingUnarchivingReason) => {
                  return (_openBlock(), _createBlock(_component_ion_list, {
                    key: pendingUnarchivingReason,
                    class: "take-out-conflicts__list"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item_divider, {
                        class: "take-out-conflicts__separator",
                        onClick: ($event: any) => (_ctx.toggleReasonConfirmation(pendingUnarchivingReason))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "take-out-conflicts__separator-label" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('global.label.reason')) + ": " + _toDisplayString(pendingUnarchivingReason), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_checkbox, {
                            slot: "end",
                            class: "ion-margin-end",
                            checked: _ctx.hasReasonAllConfirmed(pendingUnarchivingReason)
                          }, null, 8, ["checked"])
                        ]),
                        _: 2
                      }, 1032, ["onClick"]),
                      _createVNode(_component_ion_item_group, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedCards[pendingUnarchivingReason], (card) => {
                            return (_openBlock(), _createBlock(_component_ion_item, {
                              key: card.title,
                              onClick: ($event: any) => (_ctx.toggleCardConfirmation(card))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(card.title), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_checkbox, {
                                  slot: "end",
                                  name: card.title,
                                  checked: card.confirmed
                                }, null, 8, ["name", "checked"])
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_footer, { class: "ion-no-border" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "ion-margin" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_loading_button, {
                      expand: "block",
                      promise: _ctx.takeOut
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.takeOutFromArchive')), 1)
                      ]),
                      _: 1
                    }, 8, ["promise"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}