import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_archive_pending_to_be_archived_add_operation = _resolveComponent("archive-pending-to-be-archived-add-operation")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_fab, {
      slot: "fixed",
      vertical: "bottom",
      horizontal: "end"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_fab_button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_fa_icon, {
              size: "2x",
              icon: ['fal', 'plus']
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_modal, {
      "css-class": "archive-pending-to-be-archived-action-add__modal",
      mode: "md",
      "show-backdrop": "",
      "is-open": _ctx.visibleModal,
      component: "",
      onDidDismiss: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hideModal()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_archive_pending_to_be_archived_add_operation, {
          title: _ctx.title,
          "entity-type": _ctx.entityType,
          "section-type": _ctx.sectionType,
          datasource: _ctx.datasource,
          onGoBack: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hideModal())),
          onTakeIn: _cache[2] || (_cache[2] = ($event: any) => (_ctx.takeIn()))
        }, null, 8, ["title", "entity-type", "section-type", "datasource"])
      ]),
      _: 1
    }, 8, ["is-open"])
  ], 64))
}