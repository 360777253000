import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue'
import { CodeReaderClass } from '@/app/shared/components/code-reader/code-reader.class'
import { CodeReaderEvent } from '@/app/shared/components/code-reader/code-reader.model'

export default defineComponent({
    name: 'CodeReader',
    emits: [ ...Object.values(CodeReaderEvent) ],
    setup(props, { emit }) {
        const _class = reactive(new CodeReaderClass())

        /// DATA
        const scanner = ref(null)

        watch(() => _class.decoded, value => {
            if (value) {
                emit(CodeReaderEvent.DECODED, value.getText())
            }
        })

        /// HOOKS
        onMounted(async () => {
            const element = scanner.value
            if (element) {
                await _class.decode(element)
            }
        })

        onBeforeUnmount(() => _class.reset())

        return {
            /// DATA
            scanner,
            loading: ref(true),
            /// COMPUTED
            mediaStreamAPISupported: computed(() => _class.mediaStreamAPISupported)
        }
    }
})
