import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79898efc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_loading_button = _resolveComponent("loading-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        "scroll-y": false,
        class: "user-downtime"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "user-downtime__header ion-padding ion-margin" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, {
                size: "12",
                class: "flex-all-center ion-margin-vertical"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_fa_icon, {
                    icon: ['far', 'sign-out-alt'],
                    class: "user-downtime__icon primary-dark-color ion-margin"
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, {
                size: "12",
                class: "flex-all-center"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('anapathapp.label.downtimeLimit')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, {
                size: "12",
                class: "flex-all-center"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h1", null, _toDisplayString(_ctx.countDownMessage), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, { class: "user-downtime__footer" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, {
                size: "12",
                class: "flex-all-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_loading_button, {
                    class: "btn--primary",
                    promise: _ctx.continueLogged
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_fa_icon, {
                        icon: ['fal', 'check'],
                        class: "ion-margin-end"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('anapathapp.action.downtimeContinue')), 1)
                    ]),
                    _: 1
                  }, 8, ["promise"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, {
                size: "12",
                class: "flex-all-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_loading_button, {
                    fill: "clear",
                    color: "danger",
                    promise: _ctx.logOut
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.logOut')), 1)
                    ]),
                    _: 1
                  }, 8, ["promise"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}