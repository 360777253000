import { RouteRecordRaw } from 'vue-router'

export enum ProfileModuleRoute {
    PROFILE = 'Profile'
}

export const profileModuleRouter =
    (): Record<ProfileModuleRoute, RouteRecordRaw> => ({
        [ProfileModuleRoute.PROFILE]: {
            path: 'profile',
            name: ProfileModuleRoute.PROFILE,
            component: () => import('@/app/views/modules/components/profile/profile-module.vue')
        }
    })
