import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!

  return (_openBlock(), _createBlock(_Suspense, null, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    fallback: _withCtx(() => [
      _createVNode(_component_ion_spinner, { name: "circles" })
    ]),
    _: 3
  }))
}