import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import LanguageSelector from '@/app/shared/components/selector/language/language-selector.vue'
import ModuleSelector from '@/app/shared/components/selector/module/module-selector.vue'
import ProfileUserInfo from '@/app/views/modules/components/profile/components/user-info/profile-user-info.vue'
import { ProfileModuleClass } from '@/app/views/modules/components/profile/profile-module.class'
import { i18n } from '@api/lib'

export default defineComponent({
    name: 'ProfileModule',
    components: {
        LanguageSelector,
        ModuleSelector,
        ProfileUserInfo
    },
    setup() {
        const _class = reactive(new ProfileModuleClass())

        /// DATA
        const canChangePassword = ref(true)

        /// HOOKS
        onMounted(async () => (canChangePassword.value = await _class.canChangePassword()))

        return {
            /// DATA
            canChangePassword,
            /// COMPUTED
            locale: computed(() => i18n.locale.value),
            /// METHODS
            logoutAlert: () => _class.logoutAlert(),
            showChangePasswordModal: () => _class.changePasswordModal(),
            saveChanges: () => _class.saveChanges()
        }
    }
})
