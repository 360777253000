import { CodeResult, multiFormatCodeReader } from '@api/lib/code-reader'
import { StrictNullable } from '@/app/core/types/app-types'

export class CodeReaderClass {
    private _decoded: StrictNullable<CodeResult>

    public get decoded(): StrictNullable<CodeResult> {
        return this._decoded
    }
    public get mediaStreamAPISupported(): boolean {
        return multiFormatCodeReader.isMediaDevicesSupported
    }

    constructor() {
        this._decoded = null
    }

    public async decode(scanner: HTMLVideoElement): Promise<void> {
        await multiFormatCodeReader.decodeFromVideoDevice(
            null,
            scanner,
            result => (this._decoded = result))
    }

    public reset(): void {
        multiFormatCodeReader.reset()
    }
}
