import { StrictNullable } from '@/app/core/types/app-types'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { LoginChangePasswordMode, LoginType } from '@/app/views/login/login-module.model'
import { auth } from '@api/auth'
import { i18n, store } from '@api/lib'
import { PasswordClass } from '@api/password.class'
import { sessionStore } from '@api/session-store'
import { authService } from '@vitro/anapath-frontend-library-api'
import { ModuleUtil } from '@/app/core/utils/module-util'

export class LoginChangePasswordClass extends PasswordClass {
    private readonly _mode: LoginChangePasswordMode
    private _showPassword: boolean
    private _successMessage: StrictNullable<string>

    public get showPassword(): boolean {
        return this._showPassword
    }
    public get successMessage(): StrictNullable<string> {
        return this._successMessage
    }
    public get hasSuccess(): boolean {
        return !!this._successMessage
    }

    public get passwordInputType(): string {
        return this._showPassword ? 'text' : 'password'
    }

    constructor(mode: LoginChangePasswordMode) {
        super()

        this._mode = mode
        this._showPassword = false
        this._successMessage = null
    }

    public async showLogin(): Promise<void> {
        await store.dispatch('login/changeLoginType', LoginType.LOGIN)
    }

    public togglePassword(): void {
        this._showPassword = !this._showPassword
    }

    private async _successCallback(): Promise<void> {
        await sessionStore.update()

        // Validations
        if (!store.state.laboratoriesConfiguration.length) {
            // No operation configured to any laboratory
            this._errorMessage = i18n.t('anapathapp.modal.changePassword.message.noOperationsError')
        } else if (!store.state.modules.length) {
            // No access to any laboratory
            this._errorMessage = i18n.t('anapathapp.modal.changePassword.message.noModulesError')
        }
        if (this._errorMessage) {
            this._successMessage = i18n.t('anapathapp.modal.changePassword.message.successfulPasswordChange')
            this._loading = true // disable submit button
            await auth.clearSession()
            return
        }

        setTimeout(() => store.dispatch('changeUserLoggedIn', true), 500)
        await ModuleUtil.navigateToDefaultModule()
    }

    private async _changePasswordAfterFirstLogin(newPassword: string): Promise<void> {
        const response =
            await PromiseUtil.toCallSecure(
                authService.changePasswordAfterFirstLogin({ newPassword }),
                error => super.errorCallback(error))
        if (response !== null) {
            await this._successCallback()
        }
    }

    private async _recoveryChangePassword(newPassword: string): Promise<void> {
        const response =
            await PromiseUtil.toCallSecure(
                authService.recoveryChangePassword({ newPassword }),
                error => super.errorCallback(error))
        if (response !== null) {
            await auth.updateAuthData(response)

            if (!response.passwordSingleUse) {
                await this._successCallback()
            }
        }
    }

    public async changePassword(password1: StrictNullable<string>, password2: StrictNullable<string>): Promise<void> {
        this._invalid = false
        this._errorMessage = ''
        this._successMessage = ''

        if (super.validatePassword(password1, password2)) {
            try {
                this._loading = true

                if (password1) {
                    if (this._mode === LoginChangePasswordMode.RECOVERY) {
                        await this._recoveryChangePassword(password1)
                    } else if (this._mode === LoginChangePasswordMode.CHANGE_PASSWORD_AFTER_FIRST_LOGIN) {
                        await this._changePasswordAfterFirstLogin(password1)
                    }
                }
            } finally {
                this._loading = false
            }
        }
    }
}
