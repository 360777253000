import { EmitFn, i18n, store } from '@api/lib'
import { LanguageSelectorEmitEvent } from '@/app/shared/components/selector/language/language-selector.model'
import { loadLanguageAsync } from '@/app/core/utils/lib/i18n-helper'
import { CommonLanguageResponse } from '@api/services/common/language/common-language.dto'
import { StrictNullable } from '@/app/core/types/app-types'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { commonLanguageService } from '@api/services/common/language/common-language.service'

export class LanguageSelectorClass {
    private readonly _emit: EmitFn<LanguageSelectorEmitEvent>

    public language: StrictNullable<CommonLanguageResponse>
    private _languages: CommonLanguageResponse[]

    public get languages(): CommonLanguageResponse[] {
        return this._languages
    }

    constructor(emit: EmitFn<LanguageSelectorEmitEvent>) {
        this._emit = emit
        this.language = null
        this._languages = []
    }

    public async changeLanguage(lang: CommonLanguageResponse, silent = false): Promise<void> {
        await store.dispatch('changeLoading', true)
        await loadLanguageAsync(lang.locale, lang.id)
        await store.dispatch('changeLoading', false)

        if (!silent) {
            this._emit(LanguageSelectorEmitEvent.CHANGE, lang)
        }
    }

    private async _loadLanguages(): Promise<void> {
        const languages =
            await PromiseUtil.toCallSecure(
                commonLanguageService.getUILanguages())
        if (languages) {
            this._languages = languages
        }
    }

    private async _loadDefaultLanguage(): Promise<void> {
        if (this.language === null) {
            this.language = this._findDefaultLanguage(this._languages)
        }
        if (this.language !== null) {
            await this.changeLanguage(this.language, true) 
        }
    }

    private _findDefaultLanguage(languages: CommonLanguageResponse[]): StrictNullable<CommonLanguageResponse> {
        return languages.find(
            language => language.locale === i18n.locale.value ?? language.defaultLanguage
        ) ?? null
    }

    public async initialize(): Promise<void> {
        await this._loadLanguages()
        await this._loadDefaultLanguage()
    }
}
