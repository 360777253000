import { RouteRecordRaw } from 'vue-router'
import { LoginChangePasswordMode, LoginType } from '@/app/views/login/login-module.model'
import { Optional } from '@/app/core/types/app-types'
import { store } from '@api/lib'
import { auth } from '@api/auth'

export enum LoginModuleRoute {
    LOGIN = 'Login',
    CHANGE_PASSWORD = 'changePassword'
}

export const loginModuleRouter =
    (): Record<LoginModuleRoute, RouteRecordRaw> => ({
        [LoginModuleRoute.LOGIN]: {
            path: '/login',
            name: LoginModuleRoute.LOGIN,
            component: () => import('@/app/views/login/login-module.vue')
        },
        [LoginModuleRoute.CHANGE_PASSWORD]: {
            path: '/change-password',
            name: LoginModuleRoute.CHANGE_PASSWORD,
            beforeEnter: async (to) => {
                const token = to.query?.accessToken as Optional<string>
                if (token) {
                    await store.dispatch('session/changeAuth', { ...auth.state, accessToken: token })
                }
            },
            component: () => import('@/app/views/login/login-module.vue'),
            props: {
                loginType: LoginType.NEW_PASSWORD,
                loginChangePasswordMode: LoginChangePasswordMode.RECOVERY
            }
        }
    })
