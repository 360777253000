import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d93edc0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cards-skeleton" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_skeleton_text, {
      class: "cards-skeleton-header",
      animated: ""
    }),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(20, (index) => {
      return _createVNode(_component_ion_skeleton_text, {
        key: index,
        class: "cards-skeleton-card",
        animated: ""
      })
    }), 64))
  ]))
}