import { EmitFn, store } from '@api/lib'
import { ModuleSelectorEmitEvent } from '@/app/shared/components/selector/module/module-selector.model'
import { getModule, Module } from '@api/module'
import {
    UserDefaultAccessUiComponentConfig,
    UserDefaultLabModule
} from '@/app/plugins/vuex/store/root/root-store-state.model'
import { ModuleOperation } from '@api/operations/module-operation'
import { ModuleUtil } from '@/app/core/utils/module-util'
import { ToastUtil } from '@/app/core/utils/lib/overlay/toast-util'
import { sessionStore } from '@api/session-store'

export class ModuleSelectorClass {
    private readonly _emit: EmitFn<ModuleSelectorEmitEvent>
    private readonly _moduleOperation: ModuleOperation

    public get modules(): Module[] {
        return store.state.modules
    }

    public get moduleOperation(): ModuleOperation {
        return this._moduleOperation
    }

    constructor(emit: EmitFn<ModuleSelectorEmitEvent>) {
        this._emit = emit
        this._moduleOperation = ModuleUtil.defaultModule
    }

    public async changeModule(moduleOperation: ModuleOperation) {
        const module = getModule(moduleOperation)
        if (module !== null) {
            const defaultLabModules: UserDefaultLabModule[] =
                store.state.defaultAccess?.defaultLabModules?.map(defaultLabModule => {
                    if (defaultLabModule.laboratory.labId === store.getters['session/labId']) {
                        return {
                            ...defaultLabModule,
                            moduleCode: module.moduleCode
                        }
                    }
                    return defaultLabModule
                }) ?? []
            const userDefaultAccessUiComponentConfig: UserDefaultAccessUiComponentConfig = {
                ...store.state.defaultAccess,
                defaultLabModules
            }
            const response = await sessionStore.saveUserDefaultAccess(userDefaultAccessUiComponentConfig)
            if (response !== null) {
                await ToastUtil.showSuccess()
                this._emit(ModuleSelectorEmitEvent.CHANGE)
            }
        }
    }
}
