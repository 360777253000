import { EmitFn } from '@api/lib'
import {
    ArchivePendingToBeUnArchivedFilterActionLocationEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/filter/action/components/location/archive-pending-to-be-un-archived-filter-action-location.model'

export class ArchivePendingToBeUnArchivedFilterActionLocationClass {
    private readonly _emit: EmitFn<ArchivePendingToBeUnArchivedFilterActionLocationEmitEvent>
    private _location: string

    constructor(emit: EmitFn<ArchivePendingToBeUnArchivedFilterActionLocationEmitEvent>) {
        this._emit = emit
        this._location = ''
    }

    public locationChanged(location: string): void {
        this._location = location
    }

    public clearFilters(): void {
        this._emit(ArchivePendingToBeUnArchivedFilterActionLocationEmitEvent.CLEAR)
    }

    public filter(): void {
        this._emit(ArchivePendingToBeUnArchivedFilterActionLocationEmitEvent.FILTERED, this._location)
    }
}
