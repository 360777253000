import { computed, defineComponent } from 'vue'
import version from '@/../version.json'

export default defineComponent({
    name: 'LoginMenu',
    setup() {
        return {
            /// COMPUTED
            version: computed(() => version.version)
        }
    }
})
