import { Module } from 'vuex'
import { LoginStoreState } from '@/app/views/login/store/login-store-state.model'
import { RootStoreState } from '@/app/plugins/vuex/store/root/root-store-state.model'
import { VuexStoreModule } from '@/app/plugins/vuex/store/vuex-store-module'
import { LoginType } from '@/app/views/login/login-module.model'

const vuexStoreModule: Module<LoginStoreState, RootStoreState> = {
    namespaced: true,
    state: () => ({
        loginType: LoginType.LOGIN
    }),
    getters: {
        loginType:
            (state): LoginStoreState['loginType'] => state.loginType
    },
    mutations: {
        updateLoginType:
            (state, payload: LoginStoreState['loginType']): void => {
                state.loginType = payload
            }
    },
    actions: {
        changeLoginType:
            ({ commit, getters }, payload: LoginStoreState['loginType']): void => {
                if (getters.loginType !== payload) {
                    commit('updateLoginType', payload)
                }
            }
    }
}
export const loginStoreModule = new VuexStoreModule<LoginStoreState>('login', vuexStoreModule)
