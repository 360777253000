import { defineComponent, reactive } from 'vue'
import { FilterChipEmitEvent } from '@/app/shared/components/filter-chip/filter-chip.model'
import { FilterChipClass } from '@/app/shared/components/filter-chip/filter-chip.class'

export default defineComponent({
    name: 'FilterChip',
    props: {
        filterIcon: { type: String, required: true },
        filterLabel: { type: String, required: true }
    },
    emits: [ ...Object.values(FilterChipEmitEvent) ],
    setup(props, { emit }) {
        const _class = reactive(new FilterChipClass(emit))
        return {
            /// METHODS
            removeFilter: () => _class.removeFilter()
        }
    }
})
