import { CommonFilterModel } from '@/app/shared/models/common.model'
import { GridUtil } from '@/app/core/utils/grid-util'
import { PendingUnarchivingReasonResponse } from '@api/services/archive/archive.dto'
import { StrictNullable } from '@/app/core/types/app-types'
import { CombinedFilterModel } from '@/app/shared/models/ag-grid.model'

export class ModulesLinkUtil {
    public static getPendingUnArchivingReasonFilterModel(
        reasonCode?: StrictNullable<PendingUnarchivingReasonResponse['code']>
    ): CommonFilterModel {
        const filterModel: CommonFilterModel = {}
        if (reasonCode) {
            filterModel['pendingUnarchivingReason'] = GridUtil.getSetFilterModel([reasonCode])
        }
        return filterModel
    }

    public static getLocationFilterModel(locations: string[]): CommonFilterModel | CombinedFilterModel {
        const simpleFilterModel: CommonFilterModel = {}
        let combinedFilterModel: StrictNullable<CombinedFilterModel> = null
        if (locations.length === 1) {
            simpleFilterModel['locationLabel'] = GridUtil.getTextFilterModel('contains', locations[0])
        }
        if (locations.length > 1) {
            combinedFilterModel = GridUtil.getCombinedFilter(locations, 'locationLabel', 'text')
        }
        return combinedFilterModel ?? simpleFilterModel
    }
}
