import { defineComponent, reactive } from 'vue'
import Searcher from '@/app/shared/components/searcher/searcher.vue'
import {
    ArchiveLocationInputClass
} from '@/app/views/modules/components/archive/components/shared/location-input/archive-location-input.class'
import {
    ArchiveLocationInputEmitEvent
} from '@/app/views/modules/components/archive/components/shared/location-input/archive-location-input.model'
import { SearchInformation } from '@/app/shared/components/searcher/searcher-model'

export default defineComponent({
    name: 'ArchiveLocationInput',
    components: {
        Searcher
    },
    emits: [...Object.values(ArchiveLocationInputEmitEvent)],
    setup(props, { emit }) {
        const _class = reactive(new ArchiveLocationInputClass(emit))
        return {
            /// METHODS
            onLocationClear: () => _class.onLocationClear(),
            onLocationSearch: (searchInformation: SearchInformation) => _class.onSearch(searchInformation)
        }
    }
})
