import { I18nOptions } from 'vue-i18n'
import { MessageResolver, Path, PathValue, resolveWithKeyValue } from '@intlify/core-base'

/**
 * WORKAROUND to resolve locale linked messages.
 * see bug at https://github.com/kazupon/vue-i18n/issues/1424
 * @param obj
 * @param path
 */
const messageResolver: MessageResolver =
    (obj: unknown, path: Path): PathValue => resolveWithKeyValue(obj, path)

export const vueI18nOptions: I18nOptions = {
    legacy: false,
    globalInjection: true,
    locale: process.env.VUE_APP_I18N_LOCALE,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    missingWarn: process.env.NODE_ENV !== 'test',
    silentFallbackWarn: process.env.NODE_ENV !== 'development',
    silentTranslationWarn: process.env.NODE_ENV !== 'development',
    messageResolver
}
