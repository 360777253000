import { SimpleFilterModel } from '@/app/shared/models/ag-grid.model'

export interface CommonFilterModel {
    [key: string]: SimpleFilterModel
}

export interface CommonParams {
    sort?: string[]
    page?: number
    size?: number
}
export interface CommonParamsWithQuery extends CommonParams {
    query: string
}

export interface Sort {
    sorted: boolean
    unsorted: boolean
    empty: boolean
}

export interface Pageable {
    sort: Sort
    pageNumber: number
    pageSize: number
    offset: number
    unpaged: boolean
    paged: boolean
}

export interface Page<T> {
    content: T[]
    pageable: Pageable
    last: boolean
    totalElements: number
    totalPages: number
    first: boolean
    numberOfElements: number
    sort: Sort
    size: number
    number: number
    empty: boolean
}

export interface ColDef<T = unknown> {
    colId?: string
    field: keyof T
    label?: string
    sortable?: boolean
}

export enum SortType {
    ASC = 'asc',
    DESC = 'desc'
}

export interface CommonSortModelResource<T = unknown> {
    colDef: ColDef<T>
    sort: SortType
}

///

export interface VtCustomFilter {
    filterModel: CommonFilterModel
}
