import { i18n } from '@api/lib'
import { toastController, ToastOptions } from '@api/lib/overlay'
import { ExceptionResponse } from '@vitro/anapath-frontend-library-api'
import { AxiosError } from 'axios'

export class ToastUtil {
    /**
     * Toast's visibility duration in milliseconds
     */
    public static get toastDuration(): number {
        return 2000
    }

    private static async _show(color: ToastOptions['color'], message: string, header?: string, hideAutomatically?: boolean): Promise<void> {
        const options: ToastOptions = {
            color,
            message,
            header,
            buttons: [i18n.t('anapathapp.action.cancel')]
        }
        if (hideAutomatically) {
            options.duration = ToastUtil.toastDuration
        }
        await toastController.dismiss() // hide any other visible toast
        const toast = await toastController.create(options)
        await toast.present()
    }

    public static async showSuccess(message: string = i18n.t('anapathapp.message.savedSuccessfully'), title?: string, hideAutomatically = true): Promise<void> {
        await ToastUtil._show('success', message, title, hideAutomatically)
    }
    public static async showInfo(message: string, title?: string, hideAutomatically = true): Promise<void> {
        await ToastUtil._show('primary', message, title, hideAutomatically)
    }
    public static async showWarning(message: string, title?: string, hideAutomatically = true): Promise<void> {
        await ToastUtil._show('warning', message, title, hideAutomatically)
    }
    public static async showError(message: string = i18n.t('anapathapp.message.anErrorOccurred'), title?: string, hideAutomatically = true): Promise<void> {
        await ToastUtil._show('danger', message, title, hideAutomatically)
    }

    public static async showErrorFromErrorResponse(error: AxiosError<ExceptionResponse>, alternativeMessage?: string): Promise<void> {
        const message =
            error?.response?.data?.messageTranslated
                ? error.response.data.message
                : alternativeMessage
        await ToastUtil.showError(message)
    }
}
