import {
    Overlay,
    OverlayEvent,
    OverlayLib,
    OverlayOptions
} from '@api/lib/overlay'
import { toastController as toastIonicController } from '@ionic/vue'
import { Optional } from '@/app/core/types/app-types'

export interface ToastOptions extends OverlayOptions {
    color?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger'
    header?: string
    duration?: number
    buttons?: string[]
}

class Toast implements Overlay<ToastOptions> {
    private _element: HTMLIonToastElement
    public readonly options: ToastOptions

    constructor(options: ToastOptions, element: HTMLIonToastElement) {
        this.options = options
        this._element = element
    }

    dismiss<T>(data?: T, role?: string): Promise<boolean> {
        return this._element.dismiss(data, role)
    }

    async onDidDismiss<T>(): Promise<OverlayEvent<T>> {
        const event = await this._element.onDidDismiss()
        return {
            data: event.data,
            role: event.role
        }
    }

    async onWillDismiss<T>(): Promise<OverlayEvent<T>> {
        const event = await this._element.onWillDismiss()
        return {
            data: event.data,
            role: event.role
        }
    }

    present(): Promise<void> {
        return this._element.present()
    }
}

class ToastController implements OverlayLib<ToastOptions, Overlay<ToastOptions>> {
    private _overlay: Optional<Toast>

    async create(options: ToastOptions): Promise<Overlay<ToastOptions>> {
        const toast =
            await toastIonicController.create({
                ...options,
                position: 'top'
            })
        this._overlay = new Toast(options, toast)
        return this._overlay
    }

    async dismiss(): Promise<boolean> {
        return await this._overlay?.dismiss() ?? false
    }
}
export const toastController = new ToastController()
