import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79e97960"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-padding" }
const _hoisted_2 = { class: "ion-padding" }
const _hoisted_3 = { class: "ion-padding-bottom" }
const _hoisted_4 = { class: "ion-text-end ion-margin-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_archive_location_input = _resolveComponent("archive-location-input")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ion_label, { class: "popover__title" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.label.archiveLocationQuestion')), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_archive_location_input, {
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.locationChanged($event)))
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ion_button, {
        disabled: _ctx.disabledApply,
        class: "btn--primary",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.apply()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_fa_icon, {
            icon: ['fal', 'check'],
            class: "ion-margin-end"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('anapathapp.action.apply')), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ]))
}