import { IconDefinitionCustom } from '@/app/plugins/font-awesome/custom-icons-packs/custom-icons-packs.model'
import {
    IconNameVitro,
    IconPrefixVitro
} from '@/app/plugins/font-awesome/custom-icons-packs/vitro/vitro-icon-pack.model'

export const prefix: IconPrefixVitro = 'vt'
export const iconName: IconNameVitro = 'microcentrifuge'
export const width = 1073
export const height = 1073
export const ligatures: string[] = []
export const unicode = ''
export const svgPathData = 'M43 933C3.9 893.9 17.6 813.9 84.6 688.1 133.6 595.9 197.9 505.7 231.6 472.3L527.7 176.2 800 448.2 503.9 744.3C470.1 778.1 380.3 842.4 288.1 891.4 162.1 958.6 82 972.1 43 933ZM527.7 263.1L275.2 515.6C245.1 545.7 183.2 633.8 138.9 717 75 836.7 79.9 882.6 86.5 889.3 93.2 895.9 139.3 900.8 258.8 836.9 342 792.4 430.3 730.7 460.2 700.6L712.7 448.2 527.7 263.1ZM747.5 483L487.3 222.9C460.5 196.1 460.5 152.3 487.3 125.6L564.3 48.6C591 21.9 634.8 21.9 661.5 48.6L921.7 308.8C948.4 335.5 948.4 379.3 921.7 406.1L844.7 483C818 510 774.2 510 747.5 483ZM618 92.2C615.2 89.5 610.5 89.5 607.8 92.2L530.9 169.1C528.1 171.9 528.1 176.6 530.9 179.3L791 439.5C793.8 442.2 798.4 442.2 801.2 439.5L878.1 362.5C880.9 359.8 880.9 355.1 878.1 352.3L618 92.2ZM477.1 410.7L415.6 349.2C403.7 337.3 403.7 317.6 415.6 305.7 427.5 293.8 447.3 293.8 459.2 305.7L520.7 367.2C532.6 379.1 532.6 398.8 520.7 410.7 508.6 422.9 489.1 422.9 477.1 410.7ZM380.5 507.4L318.9 445.9C307 434 307 414.3 318.9 402.3 330.9 390.4 350.6 390.4 362.5 402.3L424 463.9C435.9 475.8 435.9 495.5 424 507.4 411.9 519.5 392.4 519.5 380.5 507.4ZM833.8 838.9L875 363.3C876.8 343.4 894.3 328.5 914.3 330.3 934.2 332 949 349.6 947.3 369.5L906.1 845.1C904.3 865 886.7 879.9 866.8 878.1 846.9 876.6 832 859 833.8 838.9ZM842.6 820.3L868.7 518.8C870.1 502.7 884.2 491 900.2 492.4L1028.1 503.5C1044.1 504.9 1055.9 518.9 1054.5 535L1028.3 836.5C1027 852.5 1012.9 864.3 996.9 862.9L868.9 851.8C853.1 850.4 841.2 836.3 842.6 820.3ZM924.2 552.7L903.1 796.3 973 802.3 994.1 558.8 924.2 552.7Z'

export const definition: IconDefinitionCustom<IconPrefixVitro, IconNameVitro> = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]}
export const faMicrocentrifuge: IconDefinitionCustom<IconPrefixVitro, IconNameVitro> = definition
