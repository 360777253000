export enum SampleOperation {
    VIEW_ALL_PROCESS = 'operation.entity.sample.viewallprocess',
    CREATE = 'operation.entity.sample.create',
    EDIT = 'operation.entity.sample.edit',
    PROCESS = 'operation.entity.sample.process',
    REPROCESS = 'operation.entity.sample.reprocess',
    REQUEST_REPROCESS = 'operation.entity.sample.request-reprocess',
    TO_PROCESSOR = 'operation.entity.sample.to-processor',
    FROM_PROCESSOR = 'operation.entity.sample.from-processor',
    DELETE = 'operation.entity.sample.delete',
    ATTACH = 'operation.entity.sample.attach',
    ARCHIVE = 'operation.entity.sample.archive',
    UNARCHIVE = 'operation.entity.sample.unarchive',
    TAKE_OUT_FROM_LABORATORY = 'operation.entity.sample.take-out-from-laboratory',
    DISCARD = 'operation.entity.sample.discard'
}
