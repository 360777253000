import { ArchiveEntityDatasource } from '@/app/views/modules/components/archive/datasources/entities/archive-entity.datasource'
import { ArchiveSampleGridResponse } from '@api/services/archive/sample-filter/archive-sample-filter.dto'
import {
    ArchiveModuleSectionType
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'
import { ColDef, Page } from '@/app/shared/models/common.model'
import { i18n } from '@api/lib'
import { StoreUtil } from '@/app/core/utils/lib/store-util'
import { archiveSampleFilterService } from '@api/services/archive/sample-filter/archive-sample-filter.service'
import { PendingUnarchivingReasonResponse } from '@api/services/archive/archive.dto'
import { archiveSampleService } from '@api/services/archive/sample/archive-sample.service'
import {
    CommonEntityIdentifiersListRequestCommonSampleFamilyTreeRequest,
    CommonSampleFamilyTreeRequest
} from '@api/services/common.dto'
import { EntityIdentifierWithErrorResponse } from '@/app/core/models/http.model'
import {
    ArchiveRequestCommonSampleFamilyTreeRequest,
    UnarchiveRequestCommonSampleFamilyTreeRequest
} from '@api/services/archive/sample/archive-sample.dto'

export class ArchiveEntitySampleDatasource extends ArchiveEntityDatasource<
    ArchiveSampleGridResponse,
    CommonEntityIdentifiersListRequestCommonSampleFamilyTreeRequest,
    ArchiveRequestCommonSampleFamilyTreeRequest,
    UnarchiveRequestCommonSampleFamilyTreeRequest
> {
    constructor(sectionType: ArchiveModuleSectionType) {
        super(sectionType)
    }

    protected _getColDefs(): ColDef<ArchiveSampleGridResponse>[] {
        return [
            { colId: 'samLabel', field: 'label', label: i18n.t('anapathapp.label.elementId') },
            { colId: 'locationLabel', field: 'archivingLocation', label: i18n.t('anapathapp.label.archiveLocation') },
            { field: 'pendingUnarchivingReason', sortable: false }
        ]
    }

    protected _fetchData(): Promise<Page<ArchiveSampleGridResponse>> {
        return archiveSampleFilterService.paginator(
            StoreUtil.labId,
            this._sectionType === ArchiveModuleSectionType.PENDING_TO_BE_ARCHIVED
                ? 'pendingToBeArchived'
                : 'pendingToBeUnarchived',
            this._filterModel ?? undefined,
            super.getFetchDataParams())
    }

    public getPendingUnArchivingReasons(): Promise<PendingUnarchivingReasonResponse[]> {
        return archiveSampleFilterService.getSamplePendingUnArchivingReasons()
    }

    ///

    public updateLocation(
        archivingLocation: string,
        laboratoryId: number,
        caseId: number,
        sampleId: number
    ): Promise<ArchiveSampleGridResponse> {
        return archiveSampleService.updateLocation(laboratoryId, caseId, sampleId, archivingLocation)
    }

    public getEntityHierarchicalIds(entity: ArchiveSampleGridResponse): CommonSampleFamilyTreeRequest {
        return {
            caseId: entity.caseId,
            sampleId: entity.id
        }
    }
    public unarchivePendingToBeUnarchived(
        laboratoryId: number,
        request: CommonEntityIdentifiersListRequestCommonSampleFamilyTreeRequest
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        return archiveSampleService.unarchivePendingToBeUnarchived(laboratoryId, request)
    }
    public archive(
        laboratoryId: number,
        request: ArchiveRequestCommonSampleFamilyTreeRequest[]
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        return archiveSampleService.archive(laboratoryId, request)
    }
    public takeOutFromLaboratory(
        laboratoryId: number,
        request: UnarchiveRequestCommonSampleFamilyTreeRequest[]
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        return archiveSampleService.takeOutFromLaboratory(laboratoryId, request)
    }
}
