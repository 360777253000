import { defineComponent, reactive } from 'vue'
import { ConfirmationSummaryClass } from '@/app/shared/components/confirmation-summary/confirmation-summary.class'
import { ConfirmationSummaryEvent } from '@/app/shared/components/confirmation-summary/confirmation-summary.model'

export default defineComponent({
    name: 'ConfirmationSummary',
    props: {
        icon: { type: String, required: true },
        title: { type: String, required: true },
        counter: { type: Number, required: true }
    },
    emits: [ ...Object.values(ConfirmationSummaryEvent) ],
    setup(props, { emit }) {
        const _class = reactive(new ConfirmationSummaryClass(emit))
        return {
            /// METHODS
            confirm: () => _class.confirm()
        }
    }
})
