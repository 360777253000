import { defineComponent, PropType, reactive, toRef } from 'vue'
import {
    EntitySelectorEmitEvent,
    EntitySelectorParams,
    EntitySelectorType
} from '@/app/shared/components/selector/entity/entity-selector.model'
import { EntitySelectorClass } from '@/app/shared/components/selector/entity/entity-selector.class'

export default defineComponent({
    name: 'EntitySelector',
    props: {
        disabled: { type: Boolean, default: false },
        entityType: { type: String as PropType<EntitySelectorType>, default: EntitySelectorType.SAMPLES }
    },
    emits: [...Object.values(EntitySelectorEmitEvent)],
    setup(props, { emit }) {
        const _getParams =
            (): EntitySelectorParams => ({
                emit,
                entityType: props.entityType
            })
        const _class = reactive(new EntitySelectorClass(_getParams()))

        return {
            /// DATA
            selectedEntity: toRef(_class, 'entity'),
            /// METHODS
            changeEntity: () => _class.changeEntity()
        }
    }
})
