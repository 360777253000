import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67d45b7b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-margin" }
const _hoisted_2 = { class: "ion-margin-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_take_out_reason_selector = _resolveComponent("take-out-reason-selector")!
  const _component_take_out_return_selector = _resolveComponent("take-out-return-selector")!
  const _component_loading_button = _resolveComponent("loading-button")!
  const _component_archive_add_operation = _resolveComponent("archive-add-operation")!
  const _component_take_out_conflicts = _resolveComponent("take-out-conflicts")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_confirmation_summary = _resolveComponent("confirmation-summary")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_archive_add_operation, {
      "entity-type": _ctx.entityType,
      "section-type": _ctx.sectionType,
      title: _ctx.title,
      "list-title": "anapathapp.label.elementsToTakeOut",
      "show-stepper": _ctx.hasCards,
      onGoBack: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goBack())),
      onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onChange($event)))
    }, {
      operations: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_component_ion_title, { class: "font-size--xl primary-dark-color" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.label.removeReason')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_take_out_reason_selector, {
              reason: _ctx.reason,
              onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeReason($event)))
            }, null, 8, ["reason"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ion_title, { class: "font-size--xl primary-dark-color" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.archive.label.return')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_take_out_return_selector, {
              "should-be-returned": _ctx.shouldBeReturned,
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeShouldBeReturned($event)))
            }, null, 8, ["should-be-returned"])
          ])
        ])
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_loading_button, {
          expand: "block",
          disabled: _ctx.disabledTakeOut,
          promise: _ctx.takeOutNow
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.takeOutFromArchive')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "promise"])
      ]),
      _: 1
    }, 8, ["entity-type", "section-type", "title", "show-stepper"]),
    _createVNode(_component_ion_modal, {
      mode: "md",
      "show-backdrop": "",
      "is-open": _ctx.visibleConflicts,
      component: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_take_out_conflicts, {
          cards: _ctx.elementsPendingToBeUnarchived,
          "go-back": _ctx.hideConflicts,
          "take-out": _ctx.takeOutFromLaboratory
        }, null, 8, ["cards", "go-back", "take-out"])
      ]),
      _: 1
    }, 8, ["is-open"]),
    _createVNode(_component_ion_modal, {
      mode: "md",
      "show-backdrop": "",
      "is-open": _ctx.visibleConfirmationSummary,
      component: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_confirmation_summary, {
          icon: "inbox-out",
          title: _ctx.$t('anapathapp.archive.label.takenOut'),
          counter: _ctx.elementsWithoutError,
          onConfirm: _cache[4] || (_cache[4] = ($event: any) => (_ctx.confirm()))
        }, null, 8, ["title", "counter"])
      ]),
      _: 1
    }, 8, ["is-open"])
  ], 64))
}