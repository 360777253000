import { ColDef, CommonFilterModel, CommonSortModelResource, SortType } from '@/app/shared/models/common.model'
import { SearchInformation } from '@/app/shared/components/searcher/searcher-model'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { ToastUtil } from '@/app/core/utils/lib/overlay/toast-util'
import { i18n } from '@api/lib'
import { StrictNullable } from '@/app/core/types/app-types'
import { CombinedFilterModel } from '@/app/shared/models/ag-grid.model'

export abstract class BaseDatasource<T, S, R> {
    protected _loading: boolean
    protected _sortModel: StrictNullable<CommonSortModelResource<T>>
    protected _filterModel: StrictNullable<CommonFilterModel>

    public get loading(): boolean {
        return this._loading
    }
    public get sortModel(): StrictNullable<CommonSortModelResource<T>> {
        return this._sortModel
    }
    public get filterModel(): StrictNullable<CommonFilterModel | CombinedFilterModel> {
        return this._filterModel
    }

    protected _getColDefs?(): ColDef<T>[]
    public get colDefs(): ColDef<T>[] {
        return this._getColDefs
            ? this._getColDefs()
            : []
    }

    protected constructor() {
        this._loading = false
        this._sortModel = null
        this._filterModel = null

        if (this.colDefs.length) {
            this._sortModel = {
                colDef: this.colDefs[0],
                sort: SortType.ASC
            }
        }
    }

    /// DATA LOAD

    protected abstract _fetchData(): Promise<R>

    protected _onBeforeLoad?(): void | Promise<void>
    protected _onLoaded?(response: R): void | Promise<void>

    public async loadData(): Promise<void> {
        this._loading = true
        if (this._onBeforeLoad) {
            await this._onBeforeLoad()
        }
        const response =
            await PromiseUtil.toCallSecure(
                this._fetchData())
        if (response) {
            if (this._onLoaded) {
                await this._onLoaded(response)
            }
        }
        this._loading = false
    }

    /// SEARCH DATA

    protected _searcher?(label: string): Promise<S[]>
    public onSearched?(searchedElement: S, search: string): Promise<void>
    public async onSearch(searchInformation: SearchInformation): Promise<void> {
        if (this._searcher) {
            const response =
                await PromiseUtil.toCallUnique(
                    this._searcher(searchInformation.search))
            if (response?.length) {
                if (this.onSearched) {
                    await this.onSearched(response[0], searchInformation.search)
                }
            } else {
                await ToastUtil.showError(i18n.t('anapathapp.message.elementNotFound'))
            }
        }
    }

    /// SORT DATA

    public onSorted(sortModel: StrictNullable<CommonSortModelResource<T>>): void {
        this._sortModel = sortModel
    }

    /// FILTER DATA

    public onFiltered(filterModel: StrictNullable<CommonFilterModel>): void {
        this._filterModel = filterModel
    }
}
