import { StoreOptions } from 'vuex'
import {
    LaboratoryStoreState,
    RootStoreState
} from '@/app/plugins/vuex/store/root/root-store-state.model'
import { Optional, StrictNullable } from '@/app/core/types/app-types'
import {
    FrontGlobalParametersDetailResponse,
    LaboratoryConfigurationResponse
} from '@api/services/common/initialization/common-initialization.dto'
import { sessionStoreModule } from '@/app/plugins/vuex/store/root/modules/session/session-store-module'
import { modulesStoreModule } from '@/app/views/modules/store/modules-store-module'
import { sessionStorePlugin } from '@/app/plugins/vuex/store/root/plugins/session-store-plugin'
import {
    userLoggedInChangedStorePlugin
} from '@/app/plugins/vuex/store/root/plugins/user-logged-in-changed-store-plugin'
import {
    showUserDowntimeModalChangedStorePlugin
} from '@/app/plugins/vuex/store/root/plugins/show-user-downtime-modal-changed-store-plugin'
import { loadingChangedStorePlugin } from '@/app/plugins/vuex/store/root/plugins/loading-changed-store-plugin'
import { laboratoryChangedPlugin } from '@/app/plugins/vuex/store/root/plugins/laboratory-changed-plugin'

const initialRootStoreState =
    (): RootStoreState => ({
        defaultAccess: null,
        laboratoriesConfiguration: [],
        loading: false,
        modules: [],
        parameters: { global: null, local: null },
        identifierRegexes: [],
        showUserDowntimeModal: false,
        userInformation: null,
        userLastProfileImage: null,
        userLoggedIn: false
    })

export const rootStore: StoreOptions<RootStoreState> = {
    strict: process.env.NODE_ENV !== 'production',
    state: initialRootStoreState,
    getters: {
        defaultAccess:
            (state): RootStoreState['defaultAccess'] => state.defaultAccess,
        identifierRegexes:
            (state): RootStoreState['identifierRegexes'] => state.identifierRegexes,
        laboratoriesConfiguration:
            (state): RootStoreState['laboratoriesConfiguration'] => state.laboratoriesConfiguration,
        laboratories:
            (state): LaboratoryStoreState[] =>
                state.laboratoriesConfiguration.map(laboratoryConfiguration => ({
                    ...laboratoryConfiguration.laboratory,
                    operationCodes: laboratoryConfiguration.operations.map(operation => operation.code)
                })),
        laboratory:
            (state, getters): Optional<LaboratoryConfigurationResponse> => {
                return state.laboratoriesConfiguration.find(laboratoryConfiguration =>
                    laboratoryConfiguration.laboratory.labId === getters['session/labId'])
            },
        loading:
            (state): RootStoreState['loading'] => state.loading,
        modules:
            (state): RootStoreState['modules'] => state.modules,
        parameters:
            (state): RootStoreState['parameters'] => state.parameters,
        showUserDowntimeModal:
            (state): RootStoreState['showUserDowntimeModal'] => state.showUserDowntimeModal,
        userDefaultLabId:
            (state): StrictNullable<number> => state.userInformation?.personnel?.defaultLaboratory?.labId ?? null,
        userInformation:
            (state): RootStoreState['userInformation'] => state.userInformation,
        userLastProfileImage:
            (state): RootStoreState['userLastProfileImage'] => state.userLastProfileImage,
        userLoggedIn:
            (state): RootStoreState['userLoggedIn'] => state.userLoggedIn
    },
    mutations: {
        resetState:
            (state): void => {
                Object.assign(state, initialRootStoreState())
            },

        updateDefaultAccess: (state, payload: RootStoreState['defaultAccess']): void => {
            state.defaultAccess = payload
        },
        updateIdentifierRegexes:
            (state, payload: RootStoreState['identifierRegexes']): void => {
                state.identifierRegexes = payload.map(item => Object.freeze(item))
            },
        updateLaboratoriesConfiguration:
            (state, payload: RootStoreState['laboratoriesConfiguration']): void => {
                state.laboratoriesConfiguration = payload.map(item => Object.freeze(item))
            },
        updateModules:
            (state, payload: RootStoreState['modules']): void => {
                state.modules = payload.map(item => Object.freeze(item))
            },
        updateLoading:
            (state, payload: RootStoreState['loading']): void => {
                state.loading = payload
            },
        updateGlobalParameters:
            (state, payload: StrictNullable<FrontGlobalParametersDetailResponse>): void => {
                state.parameters.global = Object.freeze(payload)
            },
        updateLocalParameters:
            (state, payload: StrictNullable<unknown>): void => {
                state.parameters.local = Object.freeze(payload)
            },
        updateShowUserDowntimeModal:
            (state, payload: RootStoreState['showUserDowntimeModal']): void => {
                state.showUserDowntimeModal = payload
            },
        updateUserInformation:
            (state, payload: RootStoreState['userInformation']): void => {
                state.userInformation = payload
            },
        updateUserLastProfileImage:
            (state, payload: RootStoreState['userLastProfileImage']): void => {
                state.userLastProfileImage = Object.freeze(payload)
            },
        updateUserLoggedIn:
            (state, payload: RootStoreState['userLoggedIn']): void => {
                state.userLoggedIn = payload
            }
    },
    actions: {
        reset:
            ({ commit }): void => {
                commit('resetState')
            },

        changeDefaultAccess:
            ({ commit }, payload: RootStoreState['defaultAccess']): void => {
                commit('updateDefaultAccess', payload)
            },
        changeIdentifierRegexes:
            ({ commit }, payload: RootStoreState['identifierRegexes']): void => {
                commit('updateIdentifierRegexes', payload)
            },
        changeLaboratoriesConfiguration:
            ({ commit }, payload: RootStoreState['laboratoriesConfiguration']): void => {
                commit('updateLaboratoriesConfiguration', payload)
            },
        changeModules:
            ({ commit }, payload: RootStoreState['modules']): void => {
                commit('updateModules', payload)
            },
        changeLoading:
            ({ commit, getters }, payload: RootStoreState['loading']): void => {
                if (getters.loading !== payload) {
                    commit('updateLoading', payload)
                }
            },
        changeGlobalParameters:
            ({ commit, getters }, payload: StrictNullable<FrontGlobalParametersDetailResponse>): void => {
                if (getters.parameters.global !== payload) {
                    commit('updateGlobalParameters', payload)
                }
            },
        changeLocalParameters:
            ({ commit, getters }, payload: StrictNullable<unknown>): void => {
                if (getters.parameters.local !== payload) {
                    commit('updateLocalParameters', payload)
                }
            },
        changeShowUserDowntimeModal:
            ({ commit, getters }, payload: RootStoreState['showUserDowntimeModal']): void => {
                if (getters.showUserDowntimeModal !== payload) {
                    commit('updateShowUserDowntimeModal', payload)
                }
            },
        changeUserInformation:
            ({ commit, getters }, payload: RootStoreState['userInformation']): void => {
                if (getters.userInformation !== payload) {
                    commit('updateUserInformation', payload)
                }
            },
        changeUserLastProfileImage:
            ({ commit, getters }, payload: RootStoreState['userLastProfileImage']): void => {
                if (getters.userLastProfileImage !== payload) {
                    commit('updateUserLastProfileImage', payload)
                }
            },
        changeUserLoggedIn:
            ({ commit, getters }, payload: RootStoreState['userLoggedIn']): void => {
                if (getters.userLoggedIn !== payload) {
                    commit('updateUserLoggedIn', payload)
                }
            }
    },
    modules: {
        session: sessionStoreModule,
        appModules: modulesStoreModule
    },
    plugins: [
        sessionStorePlugin,
        laboratoryChangedPlugin,
        loadingChangedStorePlugin,
        userLoggedInChangedStorePlugin,
        showUserDowntimeModalChangedStorePlugin
    ]
}
