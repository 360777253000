import { EmitFn } from '@api/lib'
import {
    ArchiveLocationInputEmitEvent
} from '@/app/views/modules/components/archive/components/shared/location-input/archive-location-input.model'
import { SearchInformation } from '@/app/shared/components/searcher/searcher-model'

export class ArchiveLocationInputClass {
    private readonly _emit: EmitFn<ArchiveLocationInputEmitEvent>

    constructor(emit: EmitFn<ArchiveLocationInputEmitEvent>) {
        this._emit = emit
    }

    private _onChange(search: string): void {
        this._emit(ArchiveLocationInputEmitEvent.CHANGE, search)
    }
    public onLocationClear(): void {
        this._onChange('')
    }
    public onSearch(searchInformation: SearchInformation): void {
        this._onChange(searchInformation.search)
    }
}
