import { EmitFn, i18n } from '@api/lib'
import {
    EntitySelectorEmitEvent,
    EntitySelectorParams,
    EntitySelectorType,
    EntitySelectorTypeModel,
    getEntitySelectorTypes
} from '@/app/shared/components/selector/entity/entity-selector.model'
import { StrictNullable } from '@/app/core/types/app-types'
import { ActionSheetButton, actionSheetController } from '@api/lib/overlay'

export class EntitySelectorClass {
    private readonly _emit: EmitFn<EntitySelectorEmitEvent>
    private _selected: EntitySelectorType

    private static get _entitySelectorTypes(): EntitySelectorTypeModel[] {
        return getEntitySelectorTypes()
    }

    public get entity(): StrictNullable<EntitySelectorTypeModel> {
        const selected =
            EntitySelectorClass._entitySelectorTypes.find(
                entitySelectorType => entitySelectorType.code === this._selected)
        return selected ?? null
    }

    constructor(params: EntitySelectorParams) {
        this._emit = params.emit
        this._selected = params.entityType
    }

    public async changeEntity(): Promise<void> {
        const actionSheet =
            await actionSheetController.create({
                header: i18n.t('anapathapp.label.changeElement'),
                cssClass: 'entity-selector-action-sheet',
                buttons: EntitySelectorClass._entitySelectorTypes.map(entitySelectorType => ({
                    text: i18n.t(entitySelectorType.label),
                    cssClass: entitySelectorType.code === this._selected ? 'active' : undefined,
                    handler: () => {
                        this._selected = entitySelectorType.code
                        this._emit(EntitySelectorEmitEvent.CHANGE, this._selected)
                    }
                } as ActionSheetButton))
            })
        await actionSheet.present()
    }
}
