import { Card } from '@/app/shared/components/cards/card/card.model'
import { BaseCardDatasource } from '@/app/shared/resources/datasources/base-card.datasource'

export abstract class CardDatasource<T, S> extends BaseCardDatasource<T, S, T[]> {
    protected abstract _createCard(element: T): Card<T>

    protected _onLoaded(response: T[]): void {
        this._cards =
            this._cards.concat(
                response.map(element => this._createCard(element)))
    }
}
