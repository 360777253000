import { axios } from '@vitro/anapath-frontend-library-api'
import { AxiosRequestConfig, CancelToken } from 'axios'
class CommonUserappProfileImageService {
    public async getProfileImage(personnelId: number, cancelToken?: CancelToken): Promise<Blob> {
        const config: AxiosRequestConfig = {
            headers: { 'content-type': 'multipart/form-data' },
            responseType: 'blob',
            cancelToken
        }
        const response = await axios.get<Blob>(`/profileImage/${personnelId}`, config)
        return response.data
    }

    public async getUserProfileImage(): Promise<Blob> {
        const config: AxiosRequestConfig = {
            headers: { 'content-type': 'multipart/form-data' },
            responseType: 'blob'
        }
        const response = await axios.get<Blob>(`/profileImage`, config)
        return response.data
    }

    public async replaceUserProfileImage(file: File, thumbnail?: File, notes?: string): Promise<Blob> {
        const formData = new FormData()
        formData.append('file', file, file.name)
        if (thumbnail) {
            formData.append('thumbnail', thumbnail, thumbnail.name)
        }

        const config: AxiosRequestConfig = {
            headers: { 'content-type': 'multipart/form-data' },
            params: { notes }
        }

        const response = await axios.put<Blob>(`/profileImage`, formData, config)
        return response.data
    }

    public async deleteUserProfileImage(): Promise<void> {
        const response = await axios.delete<void>(`/profileImage`)
        return response.data
    }
}
export const commonUserappProfileImageService = new CommonUserappProfileImageService()
