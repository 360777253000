import { router } from '@/app/router'
import { getModule, Module } from '@api/module'
import { ModuleOperation } from '@api/operations/module-operation'
import { StrictNullable } from '@/app/core/types/app-types'
import { store } from '@api/lib'

export class PageNotFoundClass {
    public get userLoggedIn(): boolean {
        return store.state.userLoggedIn
    }

    public get profileModule(): StrictNullable<Module> {
        return getModule(ModuleOperation.PROFILE)
    }

    public goBack(): void {
        router.back()
    }

    public async goToProfileRoute(): Promise<void> {
        if (this.profileModule !== null) {
            await router.replace(this.profileModule.route)
        }
    }
}
