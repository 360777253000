import { LoadingButtonEmitEvent, LoadingButtonParams } from '@/app/core/components/loading-button/loading-button.model'
import { EmitFn } from '@api/lib'

export class LoadingButtonClass {
    private readonly _emit: EmitFn<LoadingButtonEmitEvent>

    private _loading: boolean

    public params: LoadingButtonParams

    constructor(emit: EmitFn<LoadingButtonEmitEvent>, params: LoadingButtonParams) {
        this._emit = emit
        this.params = params

        this._loading = false
    }

    public get loading(): boolean {
        return this._loading
    }

    public async onClick(): Promise<void>{
        this._loading = true
        try {
            const response = await this.params.promise()
            this._emit(LoadingButtonEmitEvent.RESOLVED, response)
        } catch (e) {
            this._emit(LoadingButtonEmitEvent.REJECTED, e)
        } finally {
            this._loading = false
        }
    }
}
