export enum ModuleOperation {
    ARCHIVE = 'operation.module.archive',
    HELP = 'help',
    PROFILE = 'profile',
    WASTE = 'operation.module.waste'
}

export enum ModuleOperationAccess {
    ARCHIVE = 'operation.module.archive.access',
    WASTE = 'operation.module.waste.access'
}
