import { computed, defineComponent, reactive } from 'vue'
import ArchiveLocationInput
    from '@/app/views/modules/components/archive/components/shared/location-input/archive-location-input.vue'
import {
    ArchivePendingToBeArchivedLocationSelectorEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-archived/location-selector/archive-pending-to-be-archived-location-selector.model'
import {
    ArchivePendingToBeArchivedLocationSelectorClass
} from '@/app/views/modules/components/archive/components/pending-to-be-archived/location-selector/archive-pending-to-be-archived-location-selector.class'

export default defineComponent({
    name: 'ArchivePendingToBeArchivedLocationSelector',
    components: {
        ArchiveLocationInput
    },
    emits: [...Object.values(ArchivePendingToBeArchivedLocationSelectorEmitEvent)],
    setup(props, { emit }) {
        const _class = reactive(new ArchivePendingToBeArchivedLocationSelectorClass(emit))
        return {
            /// COMPUTED
            disabledApply: computed(() => _class.disabledApply),
            /// METHODS
            locationChanged: (location: string) => _class.locationChanged(location),
            apply: () => _class.apply()
        }
    }
})
