import { VuePlugin } from '@/app'
import { App } from 'vue'
import { Composer, createI18n, I18n } from 'vue-i18n'
import { vueI18nOptions } from '@/app/plugins/vue-i18n/i18n/i18n-config'

class VueI18nPlugin implements VuePlugin {
    private readonly _i18n: I18n

    constructor() {
        this._i18n = createI18n(vueI18nOptions)
    }

    public get i18n(): Composer {
        return this._i18n.global as Composer // Vue 3 Composition API
    }

    public register(app: App<Element>): void {
        app.use(this._i18n)
    }
}
export const vueI18nPlugin = new VueI18nPlugin()
