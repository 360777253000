import { computed, defineComponent, PropType, reactive, watch } from 'vue'
import {
    TakeOutCard,
    TakeOutConflictsEmitEvent,
    TakeOutConflictsParams
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/take-out-conflicts/take-out-conflicts.model'
import {
    TakeOutConflictsClass
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/take-out-conflicts/take-out-conflicts.class'
import { ArchiveEntityResponse } from '@/app/views/modules/components/archive/datasources/archive-datasource-factory'

export default defineComponent({
    name: 'TakeOutConflicts',
    props: {
        cards: { type: Array as PropType<TakeOutCard<ArchiveEntityResponse>[]>, required: true },
        goBack: { type: Function as PropType<() => void | Promise<void>>, required: true },
        takeOut: { type: Function as PropType<() => void | Promise<void>>, required: true },
    },
    emits: [...Object.values(TakeOutConflictsEmitEvent)],
    setup(props, { emit }) {
        const _getParams =
            (): TakeOutConflictsParams => ({
                cards: props.cards
            })
        const _class = reactive(new TakeOutConflictsClass(emit, _getParams()))
        watch(props, () => _class.params = _getParams())

        return {
            /// COMPUTED
            groupedCards: computed(() => _class.groupedCards),
            pendingUnarchivingReasons: computed(() => _class.pendingUnarchivingReasons),
            /// METHODS
            toggleCardConfirmation: (card: TakeOutCard<ArchiveEntityResponse>) => _class.toggleCardConfirmation(card),
            hasReasonAllConfirmed: (reason: ArchiveEntityResponse['pendingUnarchivingReason']) => _class.hasReasonAllConfirmed(reason),
            toggleReasonConfirmation: (reason: ArchiveEntityResponse['pendingUnarchivingReason']) => _class.toggleReasonConfirmation(reason)
        }
    }
})
