import { ExceptionCodeType } from '@vitro/anapath-frontend-library-api'
import { HelperUtil } from '@/app/core/utils/helper-util'
import { router } from '@/app/router'
import { auth } from '@api/auth'
import { i18n, store } from '@api/lib'
import {
    AuthenticationExceptionResponse,
    AxiosAPI,
    RequestUseInterceptorConfig,
    ResponseUseInterceptorConfig,
    UseInterceptorError
} from '@vitro/anapath-frontend-library-api'
import qs from 'qs'
import StatusCode from 'status-code-enum'
import { PageNotFoundRoute } from '@/app/views/page-not-found/page-not-found.router'
import { LoginModuleRoute } from '@/app/views/login/login-module.router'

const AUTOMATIC_REQUEST_URLS = [
    'summary', 'Summary',
    '/auth/refresh', '/auth/logout', '/auth/login', '/userInformation',
    '/globalParameter', '/sampleclassifications/parameters',
    '/laboratoriesConfiguration',
    '/priorities', '/common/identifierRegexes', '/notifications/unread'
]

async function resetUserDowntimeIntervalIfManualRequest(url: string): Promise<void> {
    if (store.getters.userLoggedIn && !AUTOMATIC_REQUEST_URLS.some(automaticUrl => url.includes(automaticUrl))) {
        await auth.resetUserDowntimeTimeout()
    }
}

export function configureAxiosLibrary(): void {
    const paramsSerializer = (params: unknown) => qs.stringify(params, { arrayFormat: 'repeat', encode: false })

    const requestUseInterceptorConfig: RequestUseInterceptorConfig = async config => {
        if (config.url) {
            await resetUserDowntimeIntervalIfManualRequest(config.url)
        }
        if (config.headers) {
            config.headers['Accept-Language'] = i18n.locale.value

            if (auth.state?.accessToken) {
                config.headers.Authorization = `Bearer ${auth.state.accessToken}`
            }
        }
        return config
    }

    const requestUseInterceptorError: UseInterceptorError = error => Promise.reject(error)

    const responseUseInterceptorConfig: ResponseUseInterceptorConfig = r => r

    const responseUseInterceptorError: UseInterceptorError<AuthenticationExceptionResponse> = async error => {
        if (error.response) {
            const status = error.response.status
            if (status === StatusCode.ClientErrorUnauthorized) {
                if (error?.response?.data.code === ExceptionCodeType.ACCESS_DENIED) {
                    await router.push({ name: PageNotFoundRoute.PAGE_NOT_FOUND })
                } else {
                    await auth.clearSession()
                    await router.replace({ name: LoginModuleRoute.LOGIN })
                }
            }
        }
        return Promise.reject(error)
    }

    AxiosAPI.buildAxios({
        baseURL: HelperUtil.apiUrl,
        validate: process.env.NODE_ENV !== 'production',
        paramsSerializer,
        requestUseInterceptorConfig,
        requestUseInterceptorError,
        responseUseInterceptorConfig,
        responseUseInterceptorError
    })
}
