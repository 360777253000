import { defineComponent, reactive, toRef, watch } from 'vue'
import CodeReader from '@/app/shared/components/code-reader/code-reader.vue'
import { SearcherClass } from '@/app/shared/components/searcher/searcher.class'
import { SearcherEvent, SearcherParams, SearchInformationEvent } from '@/app/shared/components/searcher/searcher-model'
import { i18n } from '@api/lib'

export default defineComponent({
    name: 'Searcher',
    components: {
        CodeReader
    },
    props: {
        id: { type: String, required: true },
        errorMessage: { type: String, required: true },
        placeholder: { type: String, default: () => i18n.t('anapathapp.action.search') },

        searchOnBlur: { type: Boolean, default: false },
        closeAfterSearch: { type: Boolean, default: false },
        resetAfterSearch: { type: Boolean, default: false },

        /// searching types
        patientSearching: { type: Boolean, default: false },
        requestSearching: { type: Boolean, default: false },
        subrequestSearching: { type: Boolean, default: false },
        caseSearching: { type: Boolean, default: false },
        sampleSearching: { type: Boolean, default: false },
        portionSearching: { type: Boolean, default: false },
        subportionSearching: { type: Boolean, default: false },
        locationSearching: { type: Boolean, default: false }
    },
    emits: [
        ...Object.values(SearcherEvent),
        ...Object.values(SearchInformationEvent),
    ],
    setup(props, { emit }) {
        const _getParams =
            (): SearcherParams => ({
                errorMessage: props.errorMessage,
                searchOnBlur: props.searchOnBlur,
                closeAfterSearch: props.closeAfterSearch,
                resetAfterSearch: props.resetAfterSearch,
                patientSearching: props.patientSearching,
                requestSearching: props.requestSearching,
                subrequestSearching: props.subrequestSearching,
                caseSearching: props.caseSearching,
                sampleSearching: props.sampleSearching,
                portionSearching: props.portionSearching,
                subportionSearching: props.subportionSearching,
                locationSearching: props.locationSearching
            })
        const _class = reactive(new SearcherClass(emit, _getParams()))
        watch(props, () => _class.params = _getParams())

        return {
            /// DATA
            search: toRef(_class, 'search'),
            visibleCodeReader: toRef(_class, 'visibleCodeReader'),
            /// METHODS
            clearSearch: () => _class.clearSearch(),
            doSearch: (search?: string) => _class.doSearch(search),
            showCodeReader: () => _class.showCodeReader(),
            hideCodeReader: () => _class.hideCodeReader(),
            onFocus: () => _class.select(),
            onBlur: () => _class.searchOnBlur()
        }
    }
})
