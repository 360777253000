export enum PortionType {
    PARAFFIN_BLOCK = 'PARAFFIN_BLOCK',
    FROZEN_BLOCK = 'FROZEN_BLOCK',
    VIRTUAL = 'VIRTUAL'
}

export interface CommonPortionTypeResponse {
    potId: number
    potActive: boolean
    potName: string
    potOriginalName: string
    portionTypeEnum: PortionType
}
