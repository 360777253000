import { EmitFn } from '@api/lib'
import {
    ArchivePendingToBeArchivedAddOperationEmitEvent, ArchivePendingToBeArchivedAddOperationParams
} from '@/app/views/modules/components/archive/components/pending-to-be-archived/add-operation/archive-pending-to-be-archived-add-operation.model'
import { Card } from '@/app/shared/components/cards/card/card.model'
import {
    ArchiveEntityArchiveRequest,
    ArchiveEntityResponse
} from '@/app/views/modules/components/archive/datasources/archive-datasource-factory'
import { ArchiveEntityDatasource } from '@/app/views/modules/components/archive/datasources/entities/archive-entity.datasource'
import { ParameterUtil } from '@/app/core/utils/parameter-util'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { StoreUtil } from '@/app/core/utils/lib/store-util'

export class ArchivePendingToBeArchivedAddOperationClass {
    private readonly _emit: EmitFn<ArchivePendingToBeArchivedAddOperationEmitEvent>

    private _cards: Card<ArchiveEntityResponse>[]
    private _location: string
    private _elementsWithoutError: number
    private _visibleConfirmationSummary: boolean

    public params: ArchivePendingToBeArchivedAddOperationParams

    public get elementsWithoutError(): number {
        return this._elementsWithoutError
    }
    public get visibleConfirmationSummary(): boolean {
        return this._visibleConfirmationSummary
    }

    public get askLocation(): boolean {
        return ParameterUtil.isLocationRequired(this.params.entityType)
    }
    public get hasCards(): boolean {
        return !!this._cards.length
    }
    public get disabledTakeIn(): boolean {
        return !this._cards.length ||
            (this.askLocation && !this._location.trim().length)
    }

    constructor(
        emit: EmitFn<ArchivePendingToBeArchivedAddOperationEmitEvent>,
        params: ArchivePendingToBeArchivedAddOperationParams
    ) {
        this._emit = emit
        this.params = params

        this._cards = []
        this._location = ''
        this._elementsWithoutError = 0
        this._visibleConfirmationSummary = false
    }

    public goBack(): void {
        this._emit(ArchivePendingToBeArchivedAddOperationEmitEvent.GO_BACK)
    }

    public onChange(cards: Card<ArchiveEntityResponse>[]): void {
        this._cards = cards
    }

    public locationChanged(location: string): void {
        this._location = location
    }

    public async takeIn(datasource: ArchiveEntityDatasource): Promise<void> {
        const request: ArchiveEntityArchiveRequest[] =
            this._cards.map(({ data }) => ({
                idHierarchy: datasource.getEntityHierarchicalIds(data),
                locationLabel: this.askLocation ? this._location : undefined,
                id: data.id
            }))
        const errors =
            await PromiseUtil.toCallSecure(
                datasource.archive(StoreUtil.labId, request))
        this._elementsWithoutError = this._cards.length - (errors?.length ?? 0)
        this._visibleConfirmationSummary = true
    }

    public confirm(): void {
        this._visibleConfirmationSummary = false
        this._emit(ArchivePendingToBeArchivedAddOperationEmitEvent.TAKE_IN)
        this.goBack()
    }
}
