import { EntitySelectorType } from '@/app/shared/components/selector/entity/entity-selector.model'
import {
    ArchiveModuleSectionType
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'

export enum ArchiveAddOperationEmitEvent {
    CHANGE = 'change',
    GO_BACK = 'goBack'
}

export interface ArchiveAddOperationParams {
    entityType: EntitySelectorType
    sectionType: ArchiveModuleSectionType
}
