import { RouteRecordRaw } from 'vue-router'
import { ModuleOperationAccessGuard } from '@api/guards/module-operation-access-guard'

export enum WasteModuleRoute {
    WASTE = 'Waste'
}

export const wasteModuleRouter =
    (): Record<WasteModuleRoute, RouteRecordRaw> => ({
        [WasteModuleRoute.WASTE]: {
            path: 'waste',
            name: WasteModuleRoute.WASTE,
            beforeEnter: () => ModuleOperationAccessGuard.canAccessWaste(),
            component: () => import('@/app/views/modules/components/waste/waste-module.vue')
        }
    })
