import { RouteRecordRaw } from 'vue-router'

export enum PageNotFoundRoute {
    PAGE_NOT_FOUND = 'PageNotFound'
}

export const pageNotFoundRouter =
    (): Record<PageNotFoundRoute, RouteRecordRaw> => ({
        [PageNotFoundRoute.PAGE_NOT_FOUND]: {
            path: '/page-not-found',
            name: PageNotFoundRoute.PAGE_NOT_FOUND,
            component: () => import('@/app/views/page-not-found/page-not-found.vue')
        }
    })
