import { ArchiveEntityDatasource } from '@/app/views/modules/components/archive/datasources/entities/archive-entity.datasource'
import { ArchivePortionGridResponse } from '@api/services/archive/portion-filter/archive-portion-filter.dto'
import { PortionType } from '@api/services/common/portion-type/common-portion-type.dto'
import {
    ArchiveModuleSectionType
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'
import { ColDef, Page } from '@/app/shared/models/common.model'
import { i18n } from '@api/lib'
import { StoreUtil } from '@/app/core/utils/lib/store-util'
import { archivePortionFilterService } from '@api/services/archive/portion-filter/archive-portion-filter.service'
import { PendingUnarchivingReasonResponse } from '@api/services/archive/archive.dto'
import {
    ArchiveRequestCommonPortionFamilyTreeRequest,
    UnarchiveRequestCommonPortionFamilyTreeRequest
} from '@api/services/archive/portion/archive-portion.dto'
import {
    CommonEntityIdentifiersListRequestCommonPortionFamilyTreeRequest,
    CommonPortionFamilyTreeRequest
} from '@api/services/common.dto'
import { EntityIdentifierWithErrorResponse } from '@/app/core/models/http.model'
import { archivePortionService } from '@api/services/archive/portion/archive-portion.service'

export class ArchiveEntityPortionDatasource extends ArchiveEntityDatasource<
    ArchivePortionGridResponse,
    CommonEntityIdentifiersListRequestCommonPortionFamilyTreeRequest,
    ArchiveRequestCommonPortionFamilyTreeRequest,
    UnarchiveRequestCommonPortionFamilyTreeRequest
> {
    private readonly _portionType: PortionType

    constructor(sectionType: ArchiveModuleSectionType, portionType: PortionType) {
        super(sectionType)
        this._portionType = portionType
    }

    protected _getColDefs(): ColDef<ArchivePortionGridResponse>[] {
        return [
            { colId: 'porLabel', field: 'label', label: i18n.t('anapathapp.label.elementId') },
            { colId: 'locationLabel', field: 'archivingLocation', label: i18n.t('anapathapp.label.archiveLocation') },
            { field: 'pendingUnarchivingReason', sortable: false }
        ]
    }

    protected _fetchData(): Promise<Page<ArchivePortionGridResponse>> {
        return archivePortionFilterService.paginator(
            StoreUtil.labId,
            this._portionType,
            this._sectionType === ArchiveModuleSectionType.PENDING_TO_BE_ARCHIVED
                ? 'pendingToBeArchived'
                : 'pendingToBeUnarchived',
            this._filterModel ?? undefined,
            super.getFetchDataParams())
    }

    public getPendingUnArchivingReasons(): Promise<PendingUnarchivingReasonResponse[]> {
        return archivePortionFilterService.getPortionPendingUnArchivingReasons()
    }

    ///

    public updateLocation(
        archivingLocation: string,
        laboratoryId: number,
        caseId: number,
        sampleId: number,
        portionId: number
    ): Promise<ArchivePortionGridResponse> {
        return archivePortionService.updateLocation(laboratoryId, caseId, sampleId, portionId, archivingLocation)
    }

    public getEntityHierarchicalIds(entity: ArchivePortionGridResponse): CommonPortionFamilyTreeRequest {
        return {
            caseId: entity.caseId,
            sampleId: entity.sampleId,
            portionId: entity.id
        }
    }
    public unarchivePendingToBeUnarchived(
        laboratoryId: number,
        request: CommonEntityIdentifiersListRequestCommonPortionFamilyTreeRequest
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        return archivePortionService.unarchivePendingToBeUnarchived(laboratoryId, request)
    }
    public archive(
        laboratoryId: number,
        request: ArchiveRequestCommonPortionFamilyTreeRequest[]
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        return archivePortionService.archive(laboratoryId, request)
    }
    public takeOutFromLaboratory(
        laboratoryId: number,
        request: UnarchiveRequestCommonPortionFamilyTreeRequest[]
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        return archivePortionService.takeOutFromLaboratory(laboratoryId, request)
    }
}
