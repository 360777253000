import {
    UIComponentCustomConfigRequest,
    UIComponentCustomConfigResponse
} from '@api/services/ui-component/custom-config/ui-component-custom-config.dto'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import {
    uiComponentCustomConfigService
} from '@api/services/ui-component/custom-config/ui-component-custom-config.service'
import {
    UIComponentCustomFilterRequest,
    UIComponentCustomFilterResponse
} from '@api/services/ui-component/custom-filter/ui-component-custom-filter.dto'
import {
    uiComponentCustomFilterService
} from '@api/services/ui-component/custom-filter/ui-component-custom-filter.service'
import { ModalUtil } from '@/app/core/utils/lib/overlay/modal/modal-util'
import { i18n } from '@api/lib'
import { StrictNullable } from '@/app/core/types/app-types'
import { VtCustomFilter } from '@/app/shared/models/common.model'

export class UiComponentUtil {
    // CUSTOM CONFIG ///

    public static getCustomConfig<T>(
        componentCode: string,
        laboratoryId?: number
    ): Promise<StrictNullable<UIComponentCustomConfigResponse<T>>> {
        return PromiseUtil.toCallSecure(
            uiComponentCustomConfigService.get<T>(componentCode, laboratoryId),
            error => PromiseUtil.handleAxiosError(error, [404]))
    }

    private static _createCustomConfig<T>(
        componentCode: string,
        customConfig: UIComponentCustomConfigRequest<T>,
        laboratoryId?: number
    ): Promise<StrictNullable<UIComponentCustomConfigResponse<T>>> {
        return PromiseUtil.toCallSecure(
            uiComponentCustomConfigService.create<T>(
                componentCode,
                customConfig,
                laboratoryId))
    }

    private static _updateCustomConfig<T>(
        componentCode: string,
        componentCustomization: UIComponentCustomConfigRequest<T>,
        customConfigId: number,
        laboratoryId?: number
    ): Promise<StrictNullable<UIComponentCustomConfigResponse<T>>> {
        return PromiseUtil.toCallSecure(
            uiComponentCustomConfigService.update<T>(
                componentCode,
                componentCustomization,
                customConfigId,
                laboratoryId))
    }

    public static async saveCustomConfig<T>(
        componentCode: string,
        customConfigRequest: UIComponentCustomConfigRequest<T>,
        laboratoryId?: number
    ): Promise<StrictNullable<UIComponentCustomConfigResponse<T>>> {
        const customConfigResponse = await UiComponentUtil.getCustomConfig<T>(componentCode, laboratoryId)
        if (customConfigResponse) {
            return UiComponentUtil._updateCustomConfig<T>(
                componentCode,
                customConfigRequest,
                customConfigResponse.id,
                laboratoryId)
        }
        return UiComponentUtil._createCustomConfig<T>(
            componentCode,
            customConfigRequest,
            laboratoryId)
    }

    /// CUSTOM FILTERS ///

    public static getCustomFilter<T>(
        componentCode: string,
        laboratoryId?: number
    ): Promise<StrictNullable<UIComponentCustomFilterResponse<T>[]>> {
        return PromiseUtil.toCallSecure(
            uiComponentCustomFilterService.get<T>(componentCode, laboratoryId),
            error => PromiseUtil.handleAxiosError(error, [404]))
    }

    public static createCustomFilter<T>(
        componentCode: string,
        customFilter: UIComponentCustomFilterRequest<T>,
        laboratoryId?: number
    ): Promise<StrictNullable<UIComponentCustomFilterResponse<T>>> {
        return PromiseUtil.toCallSecure(
            uiComponentCustomFilterService.create<T>(
                componentCode,
                customFilter,
                laboratoryId))
    }

    public static removeCustomFilter(
        componentCode: string,
        customFilterId: number,
        laboratoryId?: number
    ): Promise<StrictNullable<void>> {
        return PromiseUtil.toCallSecure(
            uiComponentCustomFilterService.delete(
                componentCode,
                customFilterId,
                laboratoryId))
    }

    public static updateCustomFilter<T>(
        componentCode: string,
        componentCustomization: UIComponentCustomFilterRequest<T>,
        customFilterId: number,
        laboratoryId?: number
    ): Promise<StrictNullable<UIComponentCustomFilterResponse<T>>> {
        return PromiseUtil.toCallSecure(
            uiComponentCustomFilterService.update<T>(
                componentCode,
                componentCustomization,
                customFilterId,
                laboratoryId))
    }

    public static async validateCustomFilter<T extends VtCustomFilter>(
        customFilter: UIComponentCustomFilterRequest<T> | UIComponentCustomFilterResponse<T>
    ): Promise<boolean> {
        if (!customFilter.filterName) {
            await ModalUtil.showWarning(i18n.t('global.modal.addCustomFilter.message.noFilterName'))
            return false
        } else if (!customFilter.filterCriteria.filterModel) {
            await ModalUtil.showWarning(i18n.t('global.modal.addCustomFilter.message.noFilterSelected'))
            return false
        }
        return true
    }
}
