import { computed, defineComponent, PropType, reactive, ref } from 'vue'
import {
    LoginChangePasswordClass
} from '@/app/views/login/components/change-password/login-change-password.class'
import { StrictNullable } from '@/app/core/types/app-types'
import { LoginChangePasswordMode } from '@/app/views/login/login-module.model'

export default defineComponent({
    name: 'LoginChangePassword',
    props: {
        mode: {
            type: String as PropType<LoginChangePasswordMode>,
            required: true
        }
    },
    setup(props) {
        const _class = reactive(new LoginChangePasswordClass(props.mode))
        return {
            /// DATA
            password: ref(null),
            passwordConfirmation: ref(null),
            /// COMPUTED
            invalid: computed(() => _class.invalid),
            errorMessage: computed(() => _class.errorMessage),
            hasError: computed(() => _class.hasError),
            successMessage: computed(() => _class.successMessage),
            hasSuccess: computed(() => _class.hasSuccess),
            loading: computed(() => _class.loading),
            showPassword: computed(() => _class.showPassword),
            passwordInputType: computed(() => _class.passwordInputType),
            /// METHODS
            changePassword: (password1: StrictNullable<string>, password2: StrictNullable<string>) => _class.changePassword(password1, password2),
            togglePassword: () => _class.togglePassword(),
            showLogin: () => _class.showLogin()
        }
    }
})
