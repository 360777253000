import { StrictNullable } from '@/app/core/types/app-types'
import { MultiFormatCodeReader } from '@api/lib/code-reader/multi-format/multi-format-code-reader'

/**
 * Encapsulates the result of decoding a code within an image.
 */
export class CodeResult {
    private readonly _text: string
    private readonly _rawBytes: StrictNullable<Uint8Array>
    private readonly _timestamp: number

    constructor(text: string, rawBytes: Uint8Array, timestamp: number) {
        this._text = text
        this._rawBytes = rawBytes
        this._timestamp = timestamp
    }

    /**
     * raw text encoded by the barcode
     */
    public getText(): string {
        return this._text
    }

    /**
     * raw bytes encoded by the barcode, if applicable, otherwise {@code null}
     */
    public getRawBytes(): StrictNullable<Uint8Array> {
        return this._rawBytes
    }

    public getTimestamp(): number {
        return this._timestamp
    }
}

/// READERS

export const multiFormatCodeReader = new MultiFormatCodeReader()
