import { RouteRecordRaw } from 'vue-router'
import { ModuleOperationAccessGuard } from '@api/guards/module-operation-access-guard'

export enum ArchiveModuleRoute {
    ARCHIVE = 'Archive'
}

export const archiveModuleRouter =
    (): Record<ArchiveModuleRoute, RouteRecordRaw> => ({
        [ArchiveModuleRoute.ARCHIVE]: {
            path: 'archive',
            name: ArchiveModuleRoute.ARCHIVE,
            beforeEnter: () => ModuleOperationAccessGuard.canAccessArchive(),
            component: () => import('@/app/views/modules/components/archive/archive-module.vue'),
            props: true
        }
    })
