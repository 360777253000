import { computed, defineComponent, PropType, reactive } from 'vue'
import {
    TakeOutReturnOption,
    TakeOutReturnSelectorEmitEvent,
    TakeOutReturnSelectorParams
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/take-out-return-selector/take-out-return-selector.model'
import {
    TakeOutReturnSelectorClass
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/take-out-return-selector/take-out-return-selector.class'
import { StrictNullable } from '@/app/core/types/app-types'

export default defineComponent({
    name: 'TakeOutReasonSelector',
    props: {
        shouldBeReturned: { type: Object as PropType<StrictNullable<boolean>>, default: () => null }
    },
    emits: [...Object.values(TakeOutReturnSelectorEmitEvent)],
    setup(props, { emit }) {
        const _getParams =
            (): TakeOutReturnSelectorParams => ({
                emit,
                shouldBeReturned: props.shouldBeReturned
            })
        const _class = reactive(new TakeOutReturnSelectorClass(_getParams()))

        return {
            /// COMPUTED
            returnOptions: computed(() => _class.returnOptions),
            selectedReturnOption: computed(() => _class.selectedReturnOption?.code),
            /// METHODS
            selectReturnOption: (returnOptionCode: TakeOutReturnOption['code']) => _class.selectReturnOption(returnOptionCode)
        }
    }
})
