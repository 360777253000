import { computed, defineComponent, PropType, reactive, ref, watch } from 'vue'
import ArchivePendingToBeArchivedLocationSelector
    from '@/app/views/modules/components/archive/components/pending-to-be-archived/location-selector/archive-pending-to-be-archived-location-selector.vue'
import CardsSelectionToolbar from '@/app/shared/components/cards/cards-selection-toolbar/cards-selection-toolbar.vue'
import ConfirmationSummary from '@/app/shared/components/confirmation-summary/confirmation-summary.vue'
import { Card } from '@/app/shared/components/cards/card/card.model'
import { ArchiveEntityResponse } from '@/app/views/modules/components/archive/datasources/archive-datasource-factory'
import { ArchiveEntityDatasource } from '@/app/views/modules/components/archive/datasources/entities/archive-entity.datasource'
import {
    ArchivePendingToBeArchivedSelectionToolbarEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-archived/selection-toolbar/archive-pending-to-be-archived-selection-toolbar.model'
import {
    ArchivePendingToBeArchivedSelectionToolbarClass
} from '@/app/views/modules/components/archive/components/pending-to-be-archived/selection-toolbar/archive-pending-to-be-archived-selection-toolbar.class'
import { EntitySelectorType } from '@/app/shared/components/selector/entity/entity-selector.model'

export default defineComponent({
    name: 'ArchivePendingToBeArchivedSelectionToolbar',
    components: {
        ArchivePendingToBeArchivedLocationSelector,
        CardsSelectionToolbar,
        ConfirmationSummary
    },
    props: {
        cards: { type: Array as PropType<Card<ArchiveEntityResponse>[]>, required: true },
        entityType: { type: String as PropType<EntitySelectorType>, required: true },
        datasource: { type: Object as PropType<ArchiveEntityDatasource>, required: true }
    },
    emits: [...Object.values(ArchivePendingToBeArchivedSelectionToolbarEmitEvent)],
    setup(props, { emit }) {
        const _class = reactive(new ArchivePendingToBeArchivedSelectionToolbarClass(emit))

        /// DATA
        const visiblePopover = ref(_class.askLocation)
        watch(_class, () => visiblePopover.value = _class.askLocation)

        return {
            /// DATA
            visiblePopover,
            /// COMPUTED
            elementsWithoutError: computed(() => _class.elementsWithoutError),
            visibleLocationSelector: computed(() => _class.askLocation),
            visibleConfirmationSummary: computed(() => _class.visibleConfirmationSummary),
            /// METHODS
            hidePopover: () => (visiblePopover.value = false),
            takeIn: () => _class.takeIn(props.cards, props.entityType, props.datasource),
            archive: (locationLabel: string) => _class.archive(props.cards, props.datasource, locationLabel),
            confirm: () => _class.confirm()
        }
    }
})
