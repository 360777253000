import {
    Overlay,
    OverlayEvent,
    OverlayLib,
    OverlayOptions
} from '@api/lib/overlay'
import { popoverController as popoverIonicController } from '@ionic/vue'
import { Optional } from '@/app/core/types/app-types'

export interface PopoverOptions extends OverlayOptions {
    component: Function | HTMLElement | string
    componentProps?: Record<string, unknown>
    showBackdrop?: boolean
}

class Popover implements Overlay<PopoverOptions> {
    private _element: HTMLIonPopoverElement
    public readonly options: PopoverOptions

    constructor(options: PopoverOptions, element: HTMLIonPopoverElement) {
        this.options = options
        this._element = element
    }

    dismiss<T>(data?: T, role?: string): Promise<boolean> {
        return this._element.dismiss(data, role)
    }

    async onDidDismiss<T>(): Promise<OverlayEvent<T>> {
        const event = await this._element.onDidDismiss()
        return {
            data: event.data,
            role: event.role
        }
    }

    async onWillDismiss<T>(): Promise<OverlayEvent<T>> {
        const event = await this._element.onWillDismiss()
        return {
            data: event.data,
            role: event.role
        }
    }

    present(): Promise<void> {
        return this._element.present()
    }
}

class PopoverController implements OverlayLib<PopoverOptions, Overlay<PopoverOptions>> {
    private _overlay: Optional<Popover>

    async create(options: PopoverOptions): Promise<Overlay<PopoverOptions>> {
        const popover = await popoverIonicController.create(options)
        this._overlay = new Popover(options, popover)
        return this._overlay
    }

    async dismiss<D>(data?: D): Promise<boolean> {
        return await this._overlay?.dismiss(data) ?? false
    }
}
export const popoverController = new PopoverController()
