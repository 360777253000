import { EntityIdentifierWithErrorResponse } from '@/app/core/models/http.model'
import { StrictNullable } from '@/app/core/types/app-types'
import { ToastUtil } from '@/app/core/utils/lib/overlay/toast-util'
import { store } from '@api/lib'
import { axios, ExceptionResponse } from '@vitro/anapath-frontend-library-api'
import { AxiosError } from 'axios'
import { groupBy } from 'lodash'

export class PromiseUtil {
    private static _isEntityIdentifierWithErrorResponse(payload: unknown): boolean {
        return typeof payload === 'object' &&
            Object.prototype.hasOwnProperty.call(payload, 'id') &&
            Object.prototype.hasOwnProperty.call(payload, 'errorMessage')
    }

    public static async handleAxiosError(error: unknown, excludedResponseStatuses?: number[]): Promise<void> {
        if (!axios.isCancel(error)) {
            if (axios.isAxiosError(error)) {
                if (error.response && excludedResponseStatuses?.includes(error.response.status)) {
                    return
                }

                await ToastUtil.showErrorFromErrorResponse(error as AxiosError<ExceptionResponse>)
            } else {
                await ToastUtil.showError()
            }
        }
    }

    public static async handleErrors(response: EntityIdentifierWithErrorResponse[]): Promise<void> {
        const errors = groupBy(response, 'errorMessage')
        let errorMessages = ''
        Object.keys(errors).forEach(key => {
            errorMessages += `${key} \n`
        })
        await ToastUtil.showError(errorMessages)
    }

    public static async toCallSecure<T>(
        promise: Promise<T>,
        errorHandler = (error: unknown): Promise<void> => this.handleAxiosError(error)
    ): Promise<StrictNullable<T>> {
        try {
            const data = await promise
            if (Array.isArray(data) &&
                data.length &&
                PromiseUtil._isEntityIdentifierWithErrorResponse(data[0])) {
                await this.handleErrors(data)
            }
            return data
        } catch (err) {
            await errorHandler(err)
            return null
        }
    }

    public static async toCallSecureLoading<T>(
        promise: Promise<T>,
        errorHandler?: (error: unknown) => Promise<void>
    ): Promise<StrictNullable<T>> {
        const resolved = await this.toCallSecure<T>(promise, errorHandler)
        if (resolved === null) {
            await store.dispatch('changeLoading', false)
        }
        return resolved
    }

    public static async toCallUnique<T>(
        promise: Promise<T>,
        errorHandler?: (error: unknown) => Promise<void>
    ): Promise<StrictNullable<T>> {
        await store.dispatch('changeLoading', true)
        try {
            return await this.toCallSecureLoading<T>(promise, errorHandler)
        } finally {
            await store.dispatch('changeLoading', false)
        }
    }
}
