import { EmitFn } from '@api/lib'
import {
    ArchivePendingToBeUnArchivedAddOperationEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/add-operation/archive-pending-to-be-un-archived-add-operation.model'
import { ArchiveEntityDatasource } from '@/app/views/modules/components/archive/datasources/entities/archive-entity.datasource'
import {
    ArchiveEntityResponse,
    ArchiveEntityUnarchiveRequest
} from '@/app/views/modules/components/archive/datasources/archive-datasource-factory'
import { Card } from '@/app/shared/components/cards/card/card.model'
import { StrictNullable } from '@/app/core/types/app-types'
import { CommonUnarchivingReasonResponse } from '@api/services/archive/common/archive-common.dto'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { StoreUtil } from '@/app/core/utils/lib/store-util'
import {
    TakeOutCard
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/take-out-conflicts/take-out-conflicts.model'

export class ArchivePendingToBeUnArchivedAddOperationClass {
    private readonly _emit: EmitFn<ArchivePendingToBeUnArchivedAddOperationEmitEvent>
    private _cards: TakeOutCard<ArchiveEntityResponse>[]
    private _reason: StrictNullable<CommonUnarchivingReasonResponse>
    private _shouldBeReturned: StrictNullable<boolean>
    private _elementsWithoutError: number
    private _visibleConfirmationSummary: boolean
    private _visibleConflicts: boolean

    public get reason(): StrictNullable<CommonUnarchivingReasonResponse> {
        return this._reason
    }
    public get shouldBeReturned(): StrictNullable<boolean> {
        return this._shouldBeReturned
    }
    public get elementsWithoutError(): number {
        return this._elementsWithoutError
    }
    public get visibleConfirmationSummary(): boolean {
        return this._visibleConfirmationSummary
    }
    public get visibleConflicts(): boolean {
        return this._visibleConflicts
    }

    public get hasCards(): boolean {
        return !!this._cards.length
    }
    public get elementsPendingToBeUnarchived(): TakeOutCard<ArchiveEntityResponse>[] {
        return this._cards.filter(({ data }) => data.isPendingUnarchiving)
    }
    public get disabledTakeOut(): boolean {
        return !this._cards.length ||
            this._reason === null ||
            this._shouldBeReturned === null
    }

    constructor(emit: EmitFn<ArchivePendingToBeUnArchivedAddOperationEmitEvent>) {
        this._emit = emit
        this._cards = []
        this._reason = null
        this._shouldBeReturned = null
        this._elementsWithoutError = 0
        this._visibleConfirmationSummary = false
        this._visibleConflicts = false
    }

    public goBack(): void {
        this._emit(ArchivePendingToBeUnArchivedAddOperationEmitEvent.GO_BACK)
    }

    public hideConflicts(): void {
        this._visibleConflicts = false
    }

    public onChange(cards: Card<ArchiveEntityResponse>[]): void {
        this._cards = cards.map(card => ({ ...card, confirmed: true }))
    }

    public changeReason(reason: CommonUnarchivingReasonResponse): void {
        this._reason = reason
    }
    public changeShouldBeReturned(shouldBeReturned: boolean): void {
        this._shouldBeReturned = shouldBeReturned
    }

    public async takeOutFromLaboratory(datasource: ArchiveEntityDatasource): Promise<void> {
        if (this._reason !== null) {
            const unarchivingReasonId = this._reason.id
            const confirmedElementsToTakeOut = this._cards.filter(({ confirmed }) => confirmed)
            const request: ArchiveEntityUnarchiveRequest[] =
                confirmedElementsToTakeOut
                    .filter(({ confirmed }) => confirmed)
                    .map(({ data }) => ({
                        idHierarchy: datasource.getEntityHierarchicalIds(data),
                        unarchivingReasonId,
                        unarchivingWithReturnOption: this._shouldBeReturned ?? data.unarchivedWithReturnOption,
                        id: data.id
                    }))
            const errors =
                await PromiseUtil.toCallSecure(
                    datasource.takeOutFromLaboratory(StoreUtil.labId, request))
            this._elementsWithoutError = confirmedElementsToTakeOut.length - (errors?.length ?? 0)
            this._visibleConfirmationSummary = true
        }
    }
    public async takeOutNow(datasource: ArchiveEntityDatasource): Promise<void> {
        if (this.elementsPendingToBeUnarchived.length) {
            this._visibleConflicts = true
        } else {
            await this.takeOutFromLaboratory(datasource)
        }
    }

    public confirm(): void {
        this._visibleConflicts = false
        this._visibleConfirmationSummary = false
        this._emit(ArchivePendingToBeUnArchivedAddOperationEmitEvent.TAKE_OUT)
        this.goBack()
    }
}
