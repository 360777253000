import { EntitySelectorType } from '@/app/shared/components/selector/entity/entity-selector.model'
import { Optional } from '@/app/core/types/app-types'
import { SearchInformation } from '@/app/shared/components/searcher/searcher-model'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { archiveSampleService } from '@api/services/archive/sample/archive-sample.service'
import { StoreUtil } from '@/app/core/utils/lib/store-util'
import { archivePortionService } from '@api/services/archive/portion/archive-portion.service'
import { archiveSubportionService } from '@api/services/archive/subportion/archive-subportion.service'
import { PortionType } from '@api/services/common/portion-type/common-portion-type.dto'
import { SubportionType } from '@api/services/common/subportion-type/common-subportion-type.dto'
import { ToastUtil } from '@/app/core/utils/lib/overlay/toast-util'
import { EmitFn, i18n } from '@api/lib'
import { Card } from '@/app/shared/components/cards/card/card.model'
import { CardDatasource } from '@/app/shared/resources/datasources/card.datasource'
import {
    ArchiveEntityResponse,
    ArchiveEntitySearchResponse
} from '@/app/views/modules/components/archive/datasources/archive-datasource-factory'
import {
    ArchiveAddOperationEmitEvent,
    ArchiveAddOperationParams
} from '@/app/views/modules/components/archive/components/shared/add-operation/archive-add-operation.model'
import { PortionOperation } from '@api/operations/portion-operation'
import { SubportionOperation } from '@api/operations/subportion-operation'
import {
    ArchiveModuleSectionType
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'
import { SampleOperation } from '@api/operations/sample-operation'

export class ArchiveAddOperationClass extends CardDatasource<ArchiveEntityResponse, ArchiveEntitySearchResponse> {
    private readonly _emit: EmitFn<ArchiveAddOperationEmitEvent>

    private _visibleList: boolean

    public params: ArchiveAddOperationParams

    public get isSampleEntity(): boolean {
        return this.params.entityType === EntitySelectorType.SAMPLES
    }
    public get isPortionEntity(): boolean {
        return [EntitySelectorType.PARAFFIN_BLOCKS, EntitySelectorType.FROZEN_BLOCKS].includes(this.params.entityType)
    }
    public get isSubportionEntity(): boolean {
        return [EntitySelectorType.SLIDES, EntitySelectorType.MICRO_CENTRIFUGE_TUBES].includes(this.params.entityType)
    }

    private get _isPendingToBeArchived(): boolean {
        return this.params.sectionType === ArchiveModuleSectionType.PENDING_TO_BE_ARCHIVED
    }
    private get _isPendingToBeUnArchived(): boolean {
        return this.params.sectionType === ArchiveModuleSectionType.PENDING_TO_BE_UN_ARCHIVED
    }

    public get visibleList(): boolean {
        return this._visibleList
    }
    public get toggleVisibleLabel(): string {
        return this._visibleList
            ? i18n.t('anapathapp.modal.archiveManagement.action.next')
            : i18n.t('anapathapp.modal.archiveManagement.action.previous')
    }

    constructor(emit: EmitFn<ArchiveAddOperationEmitEvent>, params: ArchiveAddOperationParams) {
        super()

        this._emit = emit
        this.params = params

        this._visibleList = true
    }

    public toggleVisibleList(): void {
        this._visibleList = !this._visibleList
    }
    public visibleActions(): boolean {
        return !this._visibleList
    }

    protected async _fetchData(): Promise<ArchiveEntityResponse[]> {
        return []
    }

    protected _createCard(element: ArchiveEntityResponse): Card<ArchiveEntityResponse> {
        return {
            data: element,
            selected: false,
            title: element.label
        }
    }

    protected _createCardFromSearch(element: ArchiveEntitySearchResponse): Card<ArchiveEntityResponse> {
        return this._createCard(element.foundEntity)
    }

    public async onSearched(searched: ArchiveEntitySearchResponse, search: string): Promise<void> {
        await super.onSearched(searched, search)
        this._emit(ArchiveAddOperationEmitEvent.CHANGE, this._cards)
    }

    private _searchedSampleIsValid(executableOperations: SampleOperation[]): boolean {
        if (this._isPendingToBeArchived) {
            return executableOperations.includes(SampleOperation.ARCHIVE)
        } else if (this._isPendingToBeUnArchived) {
            return executableOperations.includes(SampleOperation.TAKE_OUT_FROM_LABORATORY)
        }
        return false
    }
    public async onSampleSearch(searchInformation: SearchInformation): Promise<void> {
        const response =
            await PromiseUtil.toCallUnique(
                archiveSampleService.searchByLabel(StoreUtil.labId, searchInformation.search))
        if (response?.length) {
            const entitySelected = response[0] // TODO: multi entity selection modal
            if (
                entitySelected &&
                entitySelected.executableOperations &&
                this._searchedSampleIsValid(entitySelected.executableOperations as SampleOperation[])
            ) {
                await this.onSearched(response[0], searchInformation.search)
                return
            }
        }

        await ToastUtil.showError(i18n.t('anapathapp.message.elementNotFound'))
    }

    private _searchedPortionIsValid(executableOperations: PortionOperation[]): boolean {
        if (this._isPendingToBeArchived) {
            return executableOperations.includes(PortionOperation.ARCHIVE)
        } else if (this._isPendingToBeUnArchived) {
            return executableOperations.includes(PortionOperation.TAKE_OUT_FROM_LABORATORY)
        }
        return false
    }
    public async onPortionSearch(searchInformation: SearchInformation): Promise<void> {
        const response =
            await PromiseUtil.toCallUnique(
                archivePortionService.searchByLabel(StoreUtil.labId, searchInformation.search))
        if (response?.length) {
            const entitySelected = response[0] // TODO: multi entity selection modal
            if (
                entitySelected &&
                entitySelected.executableOperations &&
                this._searchedPortionIsValid(entitySelected.executableOperations as PortionOperation[])
            ) {
                let entityType: Optional<EntitySelectorType>
                if (entitySelected.foundEntity.portionType === PortionType.PARAFFIN_BLOCK) {
                    entityType = EntitySelectorType.PARAFFIN_BLOCKS
                } else if (entitySelected.foundEntity.portionType === PortionType.FROZEN_BLOCK) {
                    entityType = EntitySelectorType.FROZEN_BLOCKS
                }
                if (entityType) {
                    await this.onSearched(entitySelected, searchInformation.search)
                    return
                }
            }
        }

        await ToastUtil.showError(i18n.t('anapathapp.message.elementNotFound'))
    }

    private _searchedSubportionIsValid(executableOperations: SubportionOperation[]): boolean {
        if (this._isPendingToBeArchived) {
            return executableOperations.includes(SubportionOperation.ARCHIVE)
        } else if (this._isPendingToBeUnArchived) {
            return executableOperations.includes(SubportionOperation.TAKE_OUT_FROM_LABORATORY)
        }
        return false
    }
    public async onSubportionSearch(searchInformation: SearchInformation): Promise<void> {
        const response =
            await PromiseUtil.toCallUnique(
                archiveSubportionService.searchByLabel(StoreUtil.labId, searchInformation.search))
        if (response?.length) {
            const entitySelected = response[0] // TODO: multi entity selection modal
            if (
                entitySelected &&
                entitySelected.executableOperations &&
                this._searchedSubportionIsValid(entitySelected.executableOperations as SubportionOperation[])
            ) {
                let entityType: Optional<EntitySelectorType>
                if (entitySelected.foundEntity.subportionType === SubportionType.SLIDE) {
                    entityType = EntitySelectorType.SLIDES
                } else if (entitySelected.foundEntity.subportionType === SubportionType.STERILE_TUBE) {
                    entityType = EntitySelectorType.MICRO_CENTRIFUGE_TUBES
                }
                if (entityType) {
                    await this.onSearched(entitySelected, searchInformation.search)
                    return
                }
            }
        }

        await ToastUtil.showError(i18n.t('anapathapp.message.elementNotFound'))
    }

    public goBack(): void {
        this._emit(ArchiveAddOperationEmitEvent.GO_BACK)
    }

    public deselectCard(cardToBeUnselected: Card<ArchiveEntityResponse>): void {
        this._cards = this._cards.filter(card => card.title !== cardToBeUnselected.title)
        this._emit(ArchiveAddOperationEmitEvent.CHANGE, this._cards)
    }
}
