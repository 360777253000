import { EmitFn } from '@api/lib'
import {
    ArchivePendingToBeArchivedLocationSelectorEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-archived/location-selector/archive-pending-to-be-archived-location-selector.model'

export class ArchivePendingToBeArchivedLocationSelectorClass {
    private readonly _emit: EmitFn<ArchivePendingToBeArchivedLocationSelectorEmitEvent>
    private _location: string

    public get disabledApply(): boolean {
        return !this._location.trim().length
    }

    constructor(emit: EmitFn<ArchivePendingToBeArchivedLocationSelectorEmitEvent>) {
        this._emit = emit
        this._location = ''
    }

    public locationChanged(location: string): void {
        this._location = location
    }

    public apply(): void {
        this._emit(ArchivePendingToBeArchivedLocationSelectorEmitEvent.CHANGE, this._location)
    }
}
