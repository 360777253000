import { computed, defineComponent, PropType, reactive } from 'vue'
import ArchivePendingToBeUnArchivedFilterActionPendingUnArchivingReason
    from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/filter/action/components/pending-un-archiving-reason/archive-pending-to-be-un-archived-filter-action-pending-un-archiving-reason.vue'
import ArchivePendingToBeUnArchivedFilterActionLocation
    from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/filter/action/components/location/archive-pending-to-be-un-archived-filter-action-location.vue'
import { PendingUnarchivingReasonResponse } from '@api/services/archive/archive.dto'
import { ArchiveEntityDatasource } from '@/app/views/modules/components/archive/datasources/entities/archive-entity.datasource'
import {
    ArchivePendingToBeUnArchivedFilterEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/filter/archive-pending-to-be-un-archived-filter.model'
import {
    ArchivePendingToBeUnArchivedFilterClass
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/filter/archive-pending-to-be-un-archived-filter.class'

export default defineComponent({
    name: 'ArchivePendingToBeUnArchivedFilter',
    components: {
        ArchivePendingToBeUnArchivedFilterActionPendingUnArchivingReason,
        ArchivePendingToBeUnArchivedFilterActionLocation
    },
    props: {
        datasource: { type: Object as PropType<ArchiveEntityDatasource>, required: true }
    },
    emits: [ ...Object.values(ArchivePendingToBeUnArchivedFilterEmitEvent) ],
    setup(props, { emit }) {
        const _class = reactive(new ArchivePendingToBeUnArchivedFilterClass(emit))
        return {
            /// COMPUTED
            pendingUnArchivingReasonCode: computed(() => _class.pendingUnArchivingReason?.code),
            /// METHODS
            addPendingUnArchivingReasonFilter: (reason: PendingUnarchivingReasonResponse) => _class.addPendingUnArchivingReasonFilter(reason),
            clearPendingUnArchivingReasonFilter: () => _class.clearPendingUnArchivingReasonFilter(),
            addLocationFilter: (location: string) => _class.addLocationFilter(location),
            clearLocationFilters: () => _class.clearLocationFilters()
        }
    }
})
