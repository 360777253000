import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, _mergeProps({ ref: "button" }, _ctx.$attrs, {
    disabled: _ctx.loading,
    class: { 'ion-activatable ripple-parent': _ctx.rippleEffect },
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick()))
  }), {
    default: _withCtx(() => [
      (_ctx.rippleEffect)
        ? (_openBlock(), _createBlock(_component_ion_ripple_effect, {
            key: 0,
            type: "unbounded"
          }))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_ion_spinner, { key: 1 }))
        : _renderSlot(_ctx.$slots, "default", { key: 2 }, undefined, true)
    ]),
    _: 3
  }, 16, ["disabled", "class"]))
}