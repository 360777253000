import { BaseDatasource } from '@/app/shared/resources/datasources/base.datasource'
import { Card } from '@/app/shared/components/cards/card/card.model'
import { CommonFilterModel, CommonSortModelResource } from '@/app/shared/models/common.model'
import { i18n } from '@api/lib'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { ToastUtil } from '@/app/core/utils/lib/overlay/toast-util'
import { CardUtil } from '@/app/shared/components/cards/card/card-util'
import { StrictNullable } from '@/app/core/types/app-types'
import { RefresherCustomEvent } from '@/app/plugins/ionic/ionic-plugin'

export abstract class BaseCardDatasource<T, S, R> extends BaseDatasource<T, S, R> {
    protected _cards: Card<T>[]

    public get cards(): Card<T>[] {
        return this._cards
    }
    public get selectedCards(): Card<T>[] {
        return this._cards.filter(entityCard => entityCard.selected)
    }

    protected constructor() {
        super()
        this._cards = []
    }

    protected _resetData(): void {
        this._cards = []
    }
    public async reloadData(): Promise<void> {
        this._resetData()
        await this.loadData()
    }
    public async refreshData(event: RefresherCustomEvent): Promise<void> {
        await this.reloadData()
        await event.target.complete()
    }

    protected async _sort(
        sortModel: CommonSortModelResource<T>,
        onBeforeSort: () => void | Promise<void>
    ): Promise<void> {
        super.onSorted(sortModel)

        const previousCards = [...this._cards]
        await onBeforeSort()
        await CardUtil.keepSelection(
            previousCards,
            this._cards,
            async (label: string) => {
                if (this._searcher) {
                    const searches =
                        await PromiseUtil.toCallUnique(
                            this._searcher(label))
                    return !!searches?.length
                }
                return false
            })
        this._cards = CardUtil.sort(this._cards, sortModel)
    }
    public async onSorted(sortModel: StrictNullable<CommonSortModelResource<T>>): Promise<void> {
        if (sortModel) {
            await this._sort(
                sortModel,
                () => this.reloadData())
        } else {
            await this.reloadData()
        }
    }

    public async onFiltered(filterModel: StrictNullable<CommonFilterModel>): Promise<void> {
        super.onFiltered(filterModel)
        await this.onSorted(this._sortModel)
    }

    protected abstract _createCardFromSearch(element: S): Card<T>
    public async onSearched(searched: S, search: string): Promise<void> {
        if (searched) {
            const searchedCard = this._cards.find(card => card.title === search)
            if (searchedCard) {
                if (searchedCard.selected) {
                    await ToastUtil.showWarning(
                        i18n.t('anapathapp.message.elementAlreadyAdded'))
                } else {
                    searchedCard.selected = true
                    await ToastUtil.showSuccess(
                        i18n.t('anapathapp.message.elementSelectedSuccessfully', { id: searchedCard.title }))
                }
            } else {
                const card = this._createCardFromSearch(searched)
                card.selected = true
                if (this._sortModel) {
                    await this._sort(
                        this._sortModel,
                        () => { this._cards.push(card) })
                } else {
                    this._cards.push(card)
                }
                await ToastUtil.showSuccess(
                    i18n.t('anapathapp.message.elementSelectedSuccessfully', { id: card.title }))
            }
        }
    }
}
