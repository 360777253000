import moment from 'moment'
import { Nullable, StrictNullable } from '@/app/core/types/app-types'

export class DateUtil {
    public static get datePattern(): string {
        return 'YYYY-MM-DD'
    }
    public static get dateTimePattern(): string {
        return 'YYYY-MM-DD HH:mm'
    }
    public static get dateTimeWithSecondsPattern(): string {
        return 'YYYY-MM-DD HH:mm:ss'
    }
    public static get zonedDateTimePattern(): string {
        return 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ'
    }

    public static get localeDatePattern(): string {
        return moment.localeData().longDateFormat('L')
    }
    public static get localeDatePatternWithText(): string {
        return moment.localeData().longDateFormat('LL')
    }
    public static get localeDateTimePattern(): string {
        return moment.localeData().longDateFormat('llll')
    }

    public static formatDate(date: Nullable<Date>, format: string = DateUtil.datePattern): StrictNullable<string> {
        return date ? moment(date).format(format) : null
    }
}
