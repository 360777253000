import { computed, defineComponent, reactive } from 'vue'
import { HelpModuleClass } from '@/app/views/modules/components/help/help-module.class'
import version from '@/../version.json'

export default defineComponent({
    name: 'HelpModule',
    setup() {
        const _class = reactive(new HelpModuleClass())

        return {
            /// COMPUTED
            version: computed(() => version.version),
            /// METHODS
            showWizard: () => _class.showWizard()
        }
    }
})
