import { StrictNullable } from '@/app/core/types/app-types'
import { EntitySelectorType } from '@/app/shared/components/selector/entity/entity-selector.model'
import {
    ArchiveEntitySampleDatasource
} from '@/app/views/modules/components/archive/datasources/entities/archive-entity-sample.datasource'
import {
    ArchiveEntityPortionDatasource
} from '@/app/views/modules/components/archive/datasources/entities/archive-entity-portion.datasource'
import { PortionType } from '@api/services/common/portion-type/common-portion-type.dto'
import {
    ArchiveEntitySubportionDatasource
} from '@/app/views/modules/components/archive/datasources/entities/archive-entity-subportion.datasource'
import { SubportionType } from '@api/services/common/subportion-type/common-subportion-type.dto'
import {
    ArchiveModuleSectionType
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'
import { ArchiveSampleGridResponse } from '@api/services/archive/sample-filter/archive-sample-filter.dto'
import { ArchivePortionGridResponse } from '@api/services/archive/portion-filter/archive-portion-filter.dto'
import { ArchiveSubportionGridResponse } from '@api/services/archive/subportion-filter/archive-subportion-filter.dto'
import {
    ArchiveEntitySearchResponseArchivePortionGridResponseSearchPortionResponse,
    ArchiveRequestCommonPortionFamilyTreeRequest,
    UnarchiveRequestCommonPortionFamilyTreeRequest
} from '@api/services/archive/portion/archive-portion.dto'
import {
    ArchiveEntitySearchResponseArchiveSampleGridResponseSearchSampleResponse,
    ArchiveRequestCommonSampleFamilyTreeRequest,
    UnarchiveRequestCommonSampleFamilyTreeRequest
} from '@api/services/archive/sample/archive-sample.dto'
import {
    ArchiveEntitySearchResponseArchiveSubportionGridResponseSearchSubportionResponse,
    ArchiveRequestCommonSubportionFamilyTreeRequest,
    UnarchiveRequestCommonSubportionFamilyTreeRequest
} from '@api/services/archive/subportion/archive-subportion.dto'
import {
    CommonEntityIdentifiersListRequestCommonPortionFamilyTreeRequest,
    CommonEntityIdentifiersListRequestCommonSampleFamilyTreeRequest,
    CommonEntityIdentifiersListRequestCommonSubportionFamilyTreeRequest,
    CommonPortionFamilyTreeRequest,
    CommonSampleFamilyTreeRequest,
    CommonSubportionFamilyTreeRequest
} from '@api/services/common.dto'

export type ArchiveEntityDatasource =
    ArchiveEntitySampleDatasource |
    ArchiveEntityPortionDatasource |
    ArchiveEntitySubportionDatasource

export type ArchiveEntityResponse =
    ArchiveSampleGridResponse |
    ArchivePortionGridResponse |
    ArchiveSubportionGridResponse

export type ArchiveEntitySearchResponse =
    ArchiveEntitySearchResponseArchiveSampleGridResponseSearchSampleResponse |
    ArchiveEntitySearchResponseArchivePortionGridResponseSearchPortionResponse |
    ArchiveEntitySearchResponseArchiveSubportionGridResponseSearchSubportionResponse

export type ArchiveEntityFamilyTreeRequest =
    CommonSampleFamilyTreeRequest |
    CommonPortionFamilyTreeRequest |
    CommonSubportionFamilyTreeRequest

export type ArchiveEntityIdentifiersListRequest =
    CommonEntityIdentifiersListRequestCommonSampleFamilyTreeRequest |
    CommonEntityIdentifiersListRequestCommonPortionFamilyTreeRequest |
    CommonEntityIdentifiersListRequestCommonSubportionFamilyTreeRequest

export type ArchiveEntityArchiveRequest =
    ArchiveRequestCommonSampleFamilyTreeRequest |
    ArchiveRequestCommonPortionFamilyTreeRequest |
    ArchiveRequestCommonSubportionFamilyTreeRequest

export type ArchiveEntityUnarchiveRequest =
    UnarchiveRequestCommonSampleFamilyTreeRequest |
    UnarchiveRequestCommonPortionFamilyTreeRequest |
    UnarchiveRequestCommonSubportionFamilyTreeRequest

class ArchiveDatasourceFactory {
    public getEntityDatasource(
        sectionType: ArchiveModuleSectionType,
        entityType: EntitySelectorType
    ): StrictNullable<ArchiveEntityDatasource> {
        switch (entityType) {
        // SAMPLE
        case EntitySelectorType.SAMPLES:
            return new ArchiveEntitySampleDatasource(sectionType)
        // PORTION
        case EntitySelectorType.PARAFFIN_BLOCKS:
            return new ArchiveEntityPortionDatasource(sectionType, PortionType.PARAFFIN_BLOCK)
        case EntitySelectorType.FROZEN_BLOCKS:
            return new ArchiveEntityPortionDatasource(sectionType, PortionType.FROZEN_BLOCK)
        // SUBPORTION
        case EntitySelectorType.SLIDES:
            return new ArchiveEntitySubportionDatasource(sectionType, SubportionType.SLIDE)
        case EntitySelectorType.MICRO_CENTRIFUGE_TUBES:
            return new ArchiveEntitySubportionDatasource(sectionType, SubportionType.STERILE_TUBE)
        }
        return null
    }
}
export const archiveDatasourceFactory = new ArchiveDatasourceFactory()
