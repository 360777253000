import { CommonLanguageResponse } from '@api/services/common/language/common-language.dto'
import { axios } from '@vitro/anapath-frontend-library-api'

class CommonLanguageService {
    public async getReportingLanguages(): Promise<CommonLanguageResponse[]> {
        const response = await axios.get<CommonLanguageResponse[]>(`/common/languages/reporting/index`)
        return response.data
    }

    public async getUILanguages(): Promise<CommonLanguageResponse[]> {
        const response = await axios.get<CommonLanguageResponse[]>(`/common/languages/ui/index`)
        return response.data
    }
}
export const commonLanguageService = new CommonLanguageService()
