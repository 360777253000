import { VuePlugin } from '@/app'
import { configureAxiosLibrary } from '@/app/plugins/axios-library/axios-library-config'
import { App } from 'vue'

class AxiosPlugin implements VuePlugin {
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    public register(app: App<Element>): void {
        configureAxiosLibrary()
        // app.provide('axios', app.config.globalProperties.axios)
    }
}
export const axiosPlugin = new AxiosPlugin()
