import { EntitySelectorType } from '@/app/shared/components/selector/entity/entity-selector.model'
import { Optional, StrictNullable } from '@/app/core/types/app-types'
import {
    ArchiveModuleSectionType
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'
import { ArchiveModuleParams } from '@/app/views/modules/components/archive/archive-module.model'
import {
    archiveDatasourceFactory,
    ArchiveEntityDatasource
} from '@/app/views/modules/components/archive/datasources/archive-datasource-factory'
import { SearchInformation } from '@/app/shared/components/searcher/searcher-model'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { archiveSampleService } from '@api/services/archive/sample/archive-sample.service'
import { StoreUtil } from '@/app/core/utils/lib/store-util'
import { archivePortionService } from '@api/services/archive/portion/archive-portion.service'
import { archiveSubportionService } from '@api/services/archive/subportion/archive-subportion.service'
import { PortionType } from '@api/services/common/portion-type/common-portion-type.dto'
import { SubportionType } from '@api/services/common/subportion-type/common-subportion-type.dto'
import { ToastUtil } from '@/app/core/utils/lib/overlay/toast-util'
import { i18n } from '@api/lib'
import { SampleOperation } from '@api/operations/sample-operation'
import { SampleOperationGuard } from '@api/guards/sample-operation-guard'
import { PortionOperation } from '@api/operations/portion-operation'
import { PortionOperationGuard } from '@api/guards/portion-operation-guard'
import { SubportionOperation } from '@api/operations/subportion-operation'
import { SubportionOperationGuard } from '@api/guards/subportion-operation-guard'
import { ModalUtil } from '@/app/core/utils/lib/overlay/modal/modal-util'

export class ArchiveModuleClass {
    private _entityType: EntitySelectorType
    private _sectionType: ArchiveModuleSectionType
    private _datasource: StrictNullable<ArchiveEntityDatasource>

    public get entityType(): EntitySelectorType {
        return this._entityType
    }
    public get sectionType(): ArchiveModuleSectionType {
        return this._sectionType
    }
    public get datasource(): StrictNullable<ArchiveEntityDatasource> {
        return this._datasource
    }

    public get isEntityTypeSample(): boolean {
        return this._entityType === EntitySelectorType.SAMPLES
    }
    public get isEntityTypePortion(): boolean {
        return [EntitySelectorType.PARAFFIN_BLOCKS, EntitySelectorType.FROZEN_BLOCKS].includes(this._entityType)
    }
    public get isEntityTypeSubportion(): boolean {
        return [EntitySelectorType.SLIDES, EntitySelectorType.MICRO_CENTRIFUGE_TUBES].includes(this._entityType)
    }

    public get isPendingToBeArchived(): boolean {
        return this._sectionType === ArchiveModuleSectionType.PENDING_TO_BE_ARCHIVED
    }
    public get isPendingToBeUnArchived(): boolean {
        return this._sectionType === ArchiveModuleSectionType.PENDING_TO_BE_UN_ARCHIVED
    }

    private get _canArchive(): boolean {
        if (this.isEntityTypeSample) {
            return SampleOperationGuard.canArchive()
        } else if (this.isEntityTypePortion) {
            return PortionOperationGuard.canArchive()
        } else if (this.isEntityTypeSubportion) {
            return SubportionOperationGuard.canArchive()
        }
        return false
    }
    private get _canUnArchive(): boolean {
        if (this.isEntityTypeSample) {
            return SampleOperationGuard.canUnArchive()
        } else if (this.isEntityTypePortion) {
            return PortionOperationGuard.canUnArchive()
        } else if (this.isEntityTypeSubportion) {
            return SubportionOperationGuard.canUnArchive()
        }
        return false
    }

    public get canSelectCards(): boolean {
        if (this.isPendingToBeArchived) {
            return this._canArchive
        } else if (this.isPendingToBeUnArchived) {
            return this._canUnArchive
        }
        return false
    }
    public get canTakeIn(): boolean {
        return this.isPendingToBeArchived &&
            this._canArchive
    }
    public get canTakeOut(): boolean {
        if (this.isPendingToBeUnArchived) {
            if (this.isEntityTypeSample) {
                return SampleOperationGuard.canTakeOutFromLaboratory()
            } else if (this.isEntityTypePortion) {
                return PortionOperationGuard.canTakeOutFromLaboratory()
            } else if (this.isEntityTypeSubportion) {
                return SubportionOperationGuard.canTakeOutFromLaboratory()
            }
        }
        return false
    }

    public get listHeader(): string {
        const plural = this.datasource?.cards.length ?? 1
        if (this.isPendingToBeArchived) {
            return i18n.t('anapathapp.label.archivePending', plural)
        } else if (this.isPendingToBeUnArchived) {
            return i18n.t('anapathapp.label.takeOutPending', plural)
        }
        return ''
    }

    constructor(params: ArchiveModuleParams) {
        this._entityType = params.entityType
        this._sectionType = params.sectionType
        this._datasource = archiveDatasourceFactory.getEntityDatasource(this._sectionType, this._entityType)
    }

    public async changeEntity(entityType: EntitySelectorType): Promise<void> {
        this._entityType = entityType
        this._datasource = archiveDatasourceFactory.getEntityDatasource(this._sectionType, this._entityType)
        await this.datasource?.loadData()
    }

    public async changeSection(sectionType: ArchiveModuleSectionType): Promise<void> {
        this._sectionType = sectionType
        await this.datasource?.changeSectionType(sectionType)
    }

    private _getSectionByOperation(
        entityOperations: string[],
        archiveOperations: string[],
        unArchiveOperations: string[]
    ): StrictNullable<ArchiveModuleSectionType> {
        if (entityOperations.some(entityOperation => archiveOperations.includes(entityOperation))) {
            return ArchiveModuleSectionType.PENDING_TO_BE_ARCHIVED
        } else if (entityOperations.some(entityOperation => unArchiveOperations.includes(entityOperation))) {
            return ArchiveModuleSectionType.PENDING_TO_BE_UN_ARCHIVED
        }
        return null
    }

    public async onSampleSearch(searchInformation: SearchInformation): Promise<void> {
        const response =
            await PromiseUtil.toCallUnique(
                archiveSampleService.searchByLabel(StoreUtil.labId, searchInformation.search)
            )

        if (!response?.length) {
            await ToastUtil.showError(i18n.t('anapathapp.message.elementNotFound'))
            return
        }

        const entitySelected = response[0] // TODO: multi entity selection modal

        const entitySection =
            this._getSectionByOperation(
                entitySelected.executableOperations,
                [SampleOperation.ARCHIVE],
                [SampleOperation.UNARCHIVE]
            )
        const sectionChanged = entitySection !== this._sectionType
        const entityChanged = !this.isEntityTypeSample
        if (sectionChanged || entityChanged) {
            await ModalUtil.showConfirmation({
                message: i18n.t('anapathapp.archive.message.optionChangeConfirmation'),
                accept: {
                    handler: async () => {
                        if (sectionChanged && entitySection) {
                            await this.changeSection(entitySection)
                        }
                        if (entityChanged) {
                            await this.changeEntity(EntitySelectorType.SAMPLES)
                        }
                        await this.datasource?.onSearched(entitySelected, searchInformation.search)
                    }
                }
            })
        } else {
            await this.datasource?.onSearched(entitySelected, searchInformation.search)
        }
    }

    public async onPortionSearch(searchInformation: SearchInformation): Promise<void> {
        const response =
            await PromiseUtil.toCallUnique(
                archivePortionService.searchByLabel(StoreUtil.labId, searchInformation.search)
            )

        if (!response?.length) {
            await ToastUtil.showError(i18n.t('anapathapp.message.elementNotFound'))
            return
        }

        const entitySelected = response[0] // TODO: multi entity selection modal

        const entitySection =
            this._getSectionByOperation(
                entitySelected.executableOperations,
                [PortionOperation.ARCHIVE],
                [PortionOperation.UNARCHIVE_TO_CUT, PortionOperation.UNARCHIVE_TO_REMAKE]
            )
        const sectionChanged = entitySection !== this._sectionType
        const entityChanged = !this.isEntityTypePortion
        if (sectionChanged || entityChanged) {
            await ModalUtil.showConfirmation({
                message: i18n.t('anapathapp.archive.message.optionChangeConfirmation'),
                accept: {
                    handler: async () => {
                        if (sectionChanged && entitySection) {
                            await this.changeSection(entitySection)
                        }

                        let entityType: Optional<EntitySelectorType>
                        if (entitySelected.foundEntity.portionType === PortionType.PARAFFIN_BLOCK) {
                            entityType = EntitySelectorType.PARAFFIN_BLOCKS
                        } else if (entitySelected.foundEntity.portionType === PortionType.FROZEN_BLOCK) {
                            entityType = EntitySelectorType.FROZEN_BLOCKS
                        } else {
                            throw new Error('portion type not supported')
                        }
                        if (entityChanged && entityType) {
                            await this.changeEntity(entityType)
                        }

                        await this.datasource?.onSearched(entitySelected, searchInformation.search)
                    }
                }
            })
            return
        } else {
            await this.datasource?.onSearched(entitySelected, searchInformation.search)
        }
    }

    public async onSubportionSearch(searchInformation: SearchInformation): Promise<void> {
        const response =
            await PromiseUtil.toCallUnique(
                archiveSubportionService.searchByLabel(StoreUtil.labId, searchInformation.search)
            )

        if (!response?.length) {
            await ToastUtil.showError(i18n.t('anapathapp.message.elementNotFound'))
            return
        }

        const entitySelected = response[0] // TODO: multi entity selection modal

        const entitySection =
            this._getSectionByOperation(
                entitySelected.executableOperations,
                [SubportionOperation.ARCHIVE],
                [
                    SubportionOperation.UNARCHIVE_TO_SCAN,
                    SubportionOperation.UNARCHIVE_TO_STAIN,
                    SubportionOperation.UNARCHIVE_FOR_PATHOLOGY_REVIEW
                ]
            )
        const sectionChanged = entitySection !== this._sectionType
        const entityChanged = !this.isEntityTypeSubportion
        if (sectionChanged || entityChanged) {
            await ModalUtil.showConfirmation({
                message: i18n.t('anapathapp.archive.message.optionChangeConfirmation'),
                accept: {
                    handler: async () => {
                        if (sectionChanged && entitySection) {
                            await this.changeSection(entitySection)
                        }

                        let entityType: Optional<EntitySelectorType>
                        if (entitySelected.foundEntity.subportionType === SubportionType.SLIDE) {
                            entityType = EntitySelectorType.SLIDES
                        } else if (entitySelected.foundEntity.subportionType === SubportionType.STERILE_TUBE) {
                            entityType = EntitySelectorType.MICRO_CENTRIFUGE_TUBES
                        } else {
                            throw new Error('subportion type not supported')
                        }

                        if (entityChanged && entityType) {
                            await this.changeEntity(entityType)
                        }

                        await this.datasource?.onSearched(entitySelected, searchInformation.search)
                    }
                }
            })
        } else {
            await this.datasource?.onSearched(entitySelected, searchInformation.search)
        }
    }
}
