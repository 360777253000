import {
    IconNameVitro,
    IconPrefixVitro
} from '@/app/plugins/font-awesome/custom-icons-packs/vitro/vitro-icon-pack.model'
import { IconPackCustom } from '@/app/plugins/font-awesome/custom-icons-packs/custom-icons-packs.model'
import { faParaffinBlock } from '@/app/plugins/font-awesome/custom-icons-packs/vitro/icons/faParaffinBlock'
import { faFrozenBlock } from '@/app/plugins/font-awesome/custom-icons-packs/vitro/icons/faFrozenBlock'
import { faSlide } from '@/app/plugins/font-awesome/custom-icons-packs/vitro/icons/faSlide'
import { faMicrocentrifuge } from '@/app/plugins/font-awesome/custom-icons-packs/vitro/icons/faMicrocentrifuge'

export const prefix: IconPrefixVitro = 'vt'
export const vt: IconPackCustom<IconPrefixVitro, IconNameVitro> = {
    paraffinblock: faParaffinBlock,
    frozenblock: faFrozenBlock,
    slide: faSlide,
    microcentrifuge: faMicrocentrifuge
}
export { faParaffinBlock, faFrozenBlock, faSlide, faMicrocentrifuge }
