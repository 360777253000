import { auth } from '@api/auth'
import { i18n, store } from '@api/lib'
import { StrictNullable } from '@/app/core/types/app-types'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { commonParameterService } from '@api/services/common/parameter/common-parameter.service'
import { modalController } from '@api/lib/overlay'
import ProfileChangePassword from '@/app/views/modules/components/profile/components/change-password/profile-change-password.vue'
import { ModalUtil } from '@/app/core/utils/lib/overlay/modal/modal-util'
import { ToastUtil } from '@/app/core/utils/lib/overlay/toast-util'
import { sessionStore } from '@api/session-store'
import { LocaleState } from '@/app/plugins/vuex/store/root/modules/session/session-store-state.model'
import { commonInitializationService } from '@api/services/common/initialization/common-initialization.service'
import { UserappPatchRequest } from '@api/services/common/initialization/common-initialization.dto'
import { Parameter } from '@vitro/anapath-frontend-library-api'

export class ProfileModuleClass {
    public async canChangePassword(personnelUserDefaultLabId?: number): Promise<boolean> {
        const globalExternalAuthentication = await auth.isGlobalExternalAuthentication()
        if (globalExternalAuthentication?.globalExternalAuthentication) {
            return false
        }

        const userDefaultLabId: StrictNullable<number> = personnelUserDefaultLabId ?? store.getters.userDefaultLabId
        if (userDefaultLabId) {
            const useLdap =
                await PromiseUtil.toCallSecure(
                    commonParameterService.getParameter(userDefaultLabId, Parameter.laboratory.LDAP_USE)
                )
            if (useLdap) {
                return false
            }
        }
        return true
    }

    public async logoutAlert(): Promise<void> {
        await ModalUtil.showConfirmation({
            header: i18n.t('anapathapp.label.signOut'),
            message: i18n.t('anapathapp.message.logoutQuestion'),
            cancel: {
                title: i18n.t('anapathapp.action.cancel')
            },
            accept: {
                title: i18n.t('anapathapp.action.accept'),
                handler: () => {
                    auth.logOut()
                }
            }
        })
    }

    public async changePasswordModal(): Promise<void> {
        const modal =
            await modalController.create({
                mode: 'md',
                cssClass: 'profile-module__modal',
                component: ProfileChangePassword,
                componentProps: {
                    onBack: () => modalController.dismiss()
                }
            })
        await modal.present()
    }

    private static async _updateUser(): Promise<void> {
        const locale: StrictNullable<LocaleState> = store.getters['session/locale']
        if (locale) {
            const patchRequest: UserappPatchRequest = {
                uiLanguageId: locale.id
            }
            await PromiseUtil.toCallSecure(
                commonInitializationService.patchUserInformation(patchRequest))
        }
    }
    public async saveChanges() {
        if (await PromiseUtil.toCallSecure(ProfileModuleClass._updateUser()) !== null) {
            if (await PromiseUtil.toCallSecure(sessionStore.update()) !== null) {
                await ToastUtil.showSuccess()
            }
        }
    }
}
