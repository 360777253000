import { computed, defineComponent, PropType, reactive } from 'vue'
import ArchiveLocationSelector
    from '@/app/views/modules/components/archive/components/pending-to-be-archived/location-selector/archive-pending-to-be-archived-location-selector.vue'
import { CardsListFilterClass } from '@/app/shared/components/cards/cards-list-filter/cards-list-filter.class'
import { CardsListFilterEmitEvent } from '@/app/shared/components/cards/cards-list-filter/cards-list-filter.model'

export default defineComponent({
    name: 'CardsListFilter',
    components: {
        ArchiveLocationSelector
    },
    props: {
        icon: { type: Array as PropType<string[]>, required: true },
        title: { type: String, required: true }
    },
    emits: [ ...Object.values(CardsListFilterEmitEvent) ],
    setup(props, { emit }) {
        const _class = reactive(new CardsListFilterClass(emit))
        return {
            /// COMPUTED
            visibleFilter: computed(() => _class.visibleFilter),
            /// METHODS
            showFilter: () => _class.showFilter(),
            hideFilter: () => _class.hideFilter(),
            clearFilters: () => _class.clearFilters(),
            filter: () => _class.filter()
        }
    }
})
