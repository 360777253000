import { Card } from '@/app/shared/components/cards/card/card.model'
import { CommonSortModelResource, SortType } from '@/app/shared/models/common.model'
import { orderBy } from 'lodash'
import { Optional } from '@/app/core/types/app-types'

export class CardUtil {
    public static async keepSelection<T>(
        previousCards: Card<T>[],
        newCards: Card<T>[],
        validator: (label: string) => Promise<boolean> = async () => false,
        prop: keyof Card<T> = 'title'
    ): Promise<void> {
        const selectedCards = previousCards.filter(previousCard => previousCard.selected)
        for (const selectedCard of selectedCards) {
            const card = newCards.find(newCard => newCard[prop] === selectedCard[prop])
            if (card) {
                card.selected = true
            } else if (await validator(selectedCard.title)) {
                newCards.push(selectedCard)
            }
        }
    }

    public static sort<T>(cards: Card<T>[], sortModel: CommonSortModelResource<T>): Card<T>[] {
        const orders: Optional<SortType[]> = sortModel.sort ? [sortModel.sort] : undefined
        return orderBy(cards, card => card.data[sortModel.colDef.field], orders)
    }
}
