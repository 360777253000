import { CodeResult } from '@api/lib/code-reader'
import { BarcodeFormat, BrowserMultiFormatReader, DecodeHintType, Exception, Result } from '@zxing/library'
import { StrictNullable } from '@/app/core/types/app-types'

export class MultiFormatCodeReader {
    /**
     * @see https://zxing.github.io/zxing/apidocs/ API docs
     * @private
     */
    private _reader: BrowserMultiFormatReader

    /**
     * If mediaDevices under navigator is supported.
     */
    public get isMediaDevicesSupported(): boolean {
        return this._reader.isMediaDevicesSuported
    }

    public get timeBetweenScansMillis(): number {
        return 500 // default: 500
    }

    constructor() {
        const hints = new Map()
        const formats = [BarcodeFormat.DATA_MATRIX]
        hints.set(DecodeHintType.POSSIBLE_FORMATS, formats)

        this._reader = new BrowserMultiFormatReader(hints, this.timeBetweenScansMillis)
    }

    /**
     * Continuously tries to decode the code from the device specified by device while showing
     * the video in the specified video element.
     *
     * @param deviceId The id of one of the devices obtained after calling getVideoInputDevices.
     *  Can be undefined, in this case it will decode from one of the available devices, preferring the main camera
     *  (environment facing) if available.
     * @param previewElem The video element in page where to show the video while decoding.
     *  Can be either an element id or directly an HTMLVideoElement.
     *  Can be undefined, in which case no video will be shown.
     * @param successCallback Called after every successful scan attempt.
     * @param errorCallback Called after every errored scan attempt.
     */
    public decodeFromVideoDevice(
        deviceId: StrictNullable<string>,
        previewElem: StrictNullable<string | HTMLVideoElement>,
        successCallback: (result: CodeResult) => void,
        errorCallback?: (error: Error) => void
    ): Promise<void> {
        return this._reader.decodeFromVideoDevice(
            deviceId,
            previewElem,
            (result: StrictNullable<Result>, error?: Exception) => {
                if (result) {
                    const barcodeResult =
                        new CodeResult(
                            result.getText(),
                            result.getRawBytes(),
                            result.getTimestamp())
                    successCallback(barcodeResult)
                }

                if (errorCallback && error) {
                    errorCallback(error)
                }
            })
    }

    /**
     * In one attempt, tries to decode the barcode from the device specified by deviceId while showing the video
     * in the specified video element.
     *
     * @param deviceId The id of one of the devices obtained after calling getVideoInputDevices.
     *  Can be undefined, in this case it will decode from one of the available devices, preferring the main camera
     *  (environment facing) if available.
     * @param previewElem The video element in page where to show the video while decoding.
     *  Can be either an element id or directly an HTMLVideoElement.
     *  Can be undefined, in which case no video will be shown.
     * @return The decoding result.
     */
    public async decodeOnceFromVideoDevice(
        deviceId?: string,
        previewElem?: string | HTMLVideoElement
    ): Promise<CodeResult> {
        const result = await this._reader.decodeOnceFromVideoDevice(deviceId, previewElem)
        return new CodeResult(
            result.getText(),
            result.getRawBytes(),
            result.getTimestamp())
    }

    /**
     * Resets the code reader to the initial state.
     * Cancels any ongoing code scanning from video or camera.
     */
    public reset(): void {
        this._reader.reset()
    }
}
