import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_button = _resolveComponent("loading-button")!
  const _component_cards_selection_toolbar = _resolveComponent("cards-selection-toolbar")!
  const _component_archive_pending_to_be_archived_location_selector = _resolveComponent("archive-pending-to-be-archived-location-selector")!
  const _component_ion_popover = _resolveComponent("ion-popover")!
  const _component_confirmation_summary = _resolveComponent("confirmation-summary")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_cards_selection_toolbar, { cards: _ctx.cards }, {
      default: _withCtx(() => [
        _createVNode(_component_loading_button, {
          fill: "solid",
          class: "ion-margin-horizontal",
          color: "primary",
          promise: _ctx.takeIn
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.archive')), 1)
          ]),
          _: 1
        }, 8, ["promise"])
      ]),
      _: 1
    }, 8, ["cards"]),
    (_ctx.visibleLocationSelector)
      ? (_openBlock(), _createBlock(_component_ion_popover, {
          key: 0,
          "css-class": "popover",
          "is-open": _ctx.visiblePopover,
          animated: false,
          component: "",
          onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hidePopover()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_archive_pending_to_be_archived_location_selector, {
              onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.archive($event)))
            })
          ]),
          _: 1
        }, 8, ["is-open"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_modal, {
      mode: "md",
      "is-open": _ctx.visibleConfirmationSummary,
      component: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_confirmation_summary, {
          icon: "inbox-in",
          title: _ctx.$t('anapathapp.archive.label.archived'),
          counter: _ctx.elementsWithoutError,
          onConfirm: _cache[2] || (_cache[2] = ($event: any) => (_ctx.confirm()))
        }, null, 8, ["title", "counter"])
      ]),
      _: 1
    }, 8, ["is-open"])
  ], 64))
}