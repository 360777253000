export enum ArchiveSectionSelectorEmitEvent {
    CHANGE = 'change'
}

export enum ArchiveModuleSectionType {
    PENDING_TO_BE_ARCHIVED = 'PENDING_TO_BE_ARCHIVED',
    PENDING_TO_BE_UN_ARCHIVED = 'PENDING_TO_BE_UN_ARCHIVED'
}

export interface ArchiveSectionSelectorTypeModel {
    code: ArchiveModuleSectionType
    label: string
    icon?: string[]
}
export const getArchiveSectionTypes =
    (): ArchiveSectionSelectorTypeModel[] => [
        {
            code: ArchiveModuleSectionType.PENDING_TO_BE_ARCHIVED,
            label: 'anapathapp.action.archive'
        },
        {
            code: ArchiveModuleSectionType.PENDING_TO_BE_UN_ARCHIVED,
            label: 'anapathapp.action.takeOut'
        }
    ]
