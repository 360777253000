import { EmitFn } from '@api/lib'
import { i18n } from '@api/lib'

export enum EntitySelectorEmitEvent {
    CHANGE = 'change'
}

export enum EntitySelectorType {
    SAMPLES = 'SAMPLES',
    PARAFFIN_BLOCKS = 'PARAFFIN_BLOCKS',
    FROZEN_BLOCKS = 'FROZEN_BLOCKS',
    SLIDES = 'SLIDES',
    MICRO_CENTRIFUGE_TUBES = 'MICRO_CENTRIFUGE_TUBES'
}

export interface EntitySelectorParams {
    emit: EmitFn<EntitySelectorEmitEvent>

    entityType: EntitySelectorType
}

export interface EntitySelectorTypeModel {
    code: EntitySelectorType
    label: string
    icon?: string[]
}
export const getEntitySelectorTypes =
    (): EntitySelectorTypeModel[] => [
        {
            code: EntitySelectorType.SAMPLES,
            label: i18n.t('anapathapp.label.samples', 2),
            icon: ['far', 'prescription-bottle'] },
        {
            code: EntitySelectorType.PARAFFIN_BLOCKS,
            label: 'anapathapp.label.paraffinBlocks',
            icon: ['vt', 'paraffinblock'],
        },
        {
            code: EntitySelectorType.FROZEN_BLOCKS,
            label: 'anapathapp.label.frozenBlocks',
            icon: ['vt', 'frozenblock'],
        },
        {
            code: EntitySelectorType.SLIDES,
            label: i18n.t('anapathapp.label.slides', 2),
            icon: ['vt', 'slide'],
        },
        {
            code: EntitySelectorType.MICRO_CENTRIFUGE_TUBES,
            label: 'anapathapp.label.microcentrifugeTubes',
            icon: ['vt', 'microcentrifuge']
        }
    ]
