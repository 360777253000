import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "slides__container" }
const _hoisted_2 = { class: "slides_img" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "slides__title" }
const _hoisted_5 = {
  key: 0,
  class: "slides__subtitle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_button = _resolveComponent("loading-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_slide = _resolveComponent("ion-slide")!
  const _component_ion_slides = _resolveComponent("ion-slides")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        "scroll-y": false,
        class: "slides"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, { mode: "md" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_loading_button, {
                    slot: "end",
                    class: "ion-padding-horizontal",
                    fill: "clear",
                    promise: _ctx.action
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.modal.slides.action.skip')), 1)
                    ]),
                    _: 1
                  }, 8, ["promise"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_slides, { pager: "" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliderContent, (slide, index) => {
                return (_openBlock(), _createBlock(_component_ion_slide, { key: index }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("img", {
                          src: slide.imageURL,
                          alt: slide.title
                        }, null, 8, _hoisted_3)
                      ]),
                      _createElementVNode("span", _hoisted_4, _toDisplayString(slide.title), 1),
                      (slide.subtitle)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(slide.subtitle), 1))
                        : _createCommentVNode("", true),
                      (slide.button)
                        ? (_openBlock(), _createBlock(_component_loading_button, {
                            key: 1,
                            size: "large",
                            promise: _ctx.action
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(slide.button), 1)
                            ]),
                            _: 2
                          }, 1032, ["promise"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}