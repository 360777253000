import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e8801d0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cards-selection-toolbar" }
const _hoisted_2 = { class: "h-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_button, {
      slot: "start",
      fill: "clear",
      color: "primary",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deselectAll()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_fa_icon, { icon: ['far', 'arrow-left'] })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_title, { class: "font-size--xl primary-dark-color ion-no-padding" }, {
      default: _withCtx(() => [
        _createElementVNode("strong", null, _toDisplayString(_ctx.cards.length) + " " + _toDisplayString(_ctx.$t('anapathapp.label.selectedCounter', _ctx.cards.length)), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_ion_buttons, {
      slot: "end",
      class: "ion-margin-horizontal"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_button, {
          slot: "end",
          fill: "clear",
          color: "primary",
          class: "cards-selection-toolbar__list-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleList()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_fa_icon, { icon: ['far', 'list-ul'] })
          ]),
          _: 1
        }),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }),
    _createVNode(_component_ion_modal, {
      "css-class": "cards-selection-toolbar__modal",
      mode: "md",
      "show-backdrop": "",
      "is-open": _ctx.visibleList,
      component: "",
      onDidDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleList()))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, { class: "font-size--xl primary-dark-color" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.label.elementsSelected')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list, { lines: "full" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      key: card.title
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { class: "cards-selection-toolbar__label" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(card.title), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_button, {
                          slot: "end",
                          fill: "clear",
                          color: "danger",
                          onClick: ($event: any) => (_ctx.deselectCard(card))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_fa_icon, { icon: ['far', 'minus-circle'] })
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["is-open"])
  ]))
}