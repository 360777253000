import { MutationPayload, Plugin, Store } from 'vuex'
import { RootStoreState } from '@/app/plugins/vuex/store/root/root-store-state.model'
import { LaboratoryConfigurationResponse } from '@api/services/common/initialization/common-initialization.dto'
import { getLaboratoryModules, Module } from '@api/module'

async function _getModulesFromFirstLaboratoryWithModules(
    store: Store<RootStoreState>,
    laboratoriesConfiguration: LaboratoryConfigurationResponse[],
    laboratoryId: number
): Promise<Module[]> {
    const modules = getLaboratoryModules(laboratoriesConfiguration, laboratoryId)
    if (!modules.length) {
        const labsConfiguration =
            laboratoriesConfiguration.filter(labConfiguration => labConfiguration.laboratory.labId !== laboratoryId)
        if (labsConfiguration.length) {
            return _getModulesFromFirstLaboratoryWithModules(
                store,
                labsConfiguration,
                labsConfiguration[0].laboratory.labId)
        }
    } else if (laboratoryId !== store.getters['session/labId']) {
        await store.dispatch('session/changeLabId', laboratoryId)
    }
    return modules
}
async function loadModules(store: Store<RootStoreState>, laboratoryId: number): Promise<void> {
    const modules =
        await _getModulesFromFirstLaboratoryWithModules(
            store,
            store.state.laboratoriesConfiguration,
            laboratoryId)
    await store.dispatch('changeModules', modules)
}

export const laboratoryChangedPlugin: Plugin<RootStoreState> =
    (store: Store<RootStoreState>) => {
        store.subscribe(async (mutation: MutationPayload) => {
            if (mutation.type === 'session/updateLabId') {
                const laboratoryId: number = mutation.payload
                if (laboratoryId) {
                    await loadModules(store, laboratoryId)
                }
            }
        })
    }
