import { Card } from '@/app/shared/components/cards/card/card.model'

export class CardClass<T> {
    private readonly _selectable: boolean

    constructor(selectable: boolean) {
        this._selectable = selectable
    }

    public select(card: Card<T>): void {
        if (this._selectable) {
            card.selected = !card.selected
        }
    }
}
