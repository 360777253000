import { StrictNullable } from '@/app/core/types/app-types'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { i18n, store } from '@api/lib'
import { AuthenticationExceptionResponse } from '@vitro/anapath-frontend-library-api'
import { axios } from '@vitro/anapath-frontend-library-api'
import { AxiosError } from 'axios'

export class PasswordClass {
    protected _loading: boolean
    protected _invalid: boolean
    protected _errorMessage: StrictNullable<string>

    public get loading(): boolean {
        return this._loading
    }
    public get invalid(): boolean {
        return this._invalid
    }
    public get errorMessage(): StrictNullable<string> {
        return this._errorMessage
    }
    public get hasError(): boolean {
        return !!this._errorMessage
    }

    public get passwordMinimumLength(): number {
        return store.state.parameters.global?.passwordMinimumLength ?? 0
    }
    public get passwordMaximumLength(): number {
        return store.state.parameters.global?.passwordMaximumLength ?? 0
    }

    constructor() {
        this._loading = false
        this._invalid = false
        this._errorMessage = null
    }

    protected async errorCallback(e: unknown): Promise<void> {
        this._invalid = true

        if (axios.isAxiosError(e)) {
            const error = e as AxiosError<AuthenticationExceptionResponse>
            const data = error.response?.data
            if (data?.messageTranslated) {
                this._errorMessage = data.message
            }
        }
        await PromiseUtil.handleAxiosError(e)
    }

    protected validatePassword(password1: StrictNullable<string>, password2: StrictNullable<string>): boolean {
        if (!password1 || !password2) {
            this._errorMessage = i18n.t('anapathapp.modal.changePassword.label.enterNewPassword')
        } else if (password1 !== password2) {
            this._errorMessage = i18n.t('anapathapp.modal.changePassword.label.newPasswordMismatch')
        } else {
            if (password1.length < this.passwordMinimumLength) {
                this._errorMessage = i18n.t('anapathapp.modal.changePassword.message.passwordMinimumLengthError', { minimumLength: this.passwordMinimumLength })
            } else if (password1.length > this.passwordMaximumLength) {
                this._errorMessage = i18n.t('anapathapp.modal.changePassword.message.passwordMaximumLengthError', { maximumLength: this.passwordMaximumLength })
            }
        }
        return !this._errorMessage
    }
}
