import { ColDef, CommonSortModelResource, SortType } from '@/app/shared/models/common.model'
import { EmitFn } from '@api/lib'
import {
    CardsListSortEmitEvent,
    CardsListSortParams,
    SortTypeModel
} from '@/app/shared/components/cards/cards-list-sort/cards-list-sort.model'

export class CardsListSortClass {
    private readonly _emit: EmitFn<CardsListSortEmitEvent>
    private readonly _colDefs: ColDef[]
    private readonly _sortModel: CommonSortModelResource

    public get sortableColDefs(): ColDef[] {
        return this._colDefs.filter(colDef => colDef.sortable !== false)
    }
    public get sortModel(): CommonSortModelResource {
        return this._sortModel
    }

    public get sortTypes(): SortTypeModel[] {
        return [
            { code: SortType.ASC, label: 'anapathapp.label.ascending' },
            { code: SortType.DESC, label: 'anapathapp.label.descending' }
        ]
    }

    constructor(params: CardsListSortParams) {
        this._emit = params.emit
        this._colDefs = params.colDefs
        this._sortModel = params.sortModel
    }

    private _onSort(): void {
        if (this._sortModel) {
            this._emit(CardsListSortEmitEvent.SORTED, this._sortModel)
        }
    }

    public onSortBy(field: string): void {
        const colDef = this.sortableColDefs.find(colDef => colDef.field === field)
        if (colDef) {
            this._sortModel.colDef = colDef
            this._onSort()
        }
    }
    public onSortType(sortType: SortType): void {
        this._sortModel.sort = sortType
        this._onSort()
    }
}
