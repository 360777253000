import { Module } from 'vuex'
import { ModulesStoreState } from '@/app/views/modules/store/modules-store-state.model'
import { RootStoreState } from '@/app/plugins/vuex/store/root/root-store-state.model'
import { modulesArchiveStoreModule } from '@/app/views/modules/store/modules/archive/modules-archive-store-module'

const initialModulesStoreState =
    (): ModulesStoreState => ({})

export const modulesStoreModule: Module<ModulesStoreState, RootStoreState> = {
    namespaced: true,
    state: initialModulesStoreState,
    mutations: {
        resetState:
            (state): void => {
                Object.assign(state, initialModulesStoreState())
            }
    },
    actions: {
        reset:
            ({ commit }): void => {
                commit('resetState')
            }
    },
    modules: {
        archive: modulesArchiveStoreModule
    }
}
