export enum LoginType {
    LOGIN = 'LOGIN',
    NEW_PASSWORD = 'NEW_PASSWORD',
    RECOVER_PASSWORD = 'RECOVER_PASSWORD',
    RECOVER_PASSWORD_EMAIL_SENT = 'RECOVER_PASSWORD_EMAIL_SENT'
}

export enum LoginChangePasswordMode {
    RECOVERY = 'RECOVERY',
    CHANGE_PASSWORD_AFTER_FIRST_LOGIN = 'CHANGE_PASSWORD_AFTER_FIRST_LOGIN'
}
