import { axios } from '@vitro/anapath-frontend-library-api'
import { EntityIdentifierWithErrorResponse } from '@/app/core/models/http.model'
import { CommonEntityIdentifiersListRequestCommonSampleFamilyTreeRequest } from '@api/services/common.dto'
import {
    ArchiveEntitySearchResponseArchiveSampleGridResponseSearchSampleResponse,
    ArchiveRequestCommonSampleFamilyTreeRequest,
    UnarchiveRequestCommonSampleFamilyTreeRequest
} from '@api/services/archive/sample/archive-sample.dto'
import { ArchiveSampleGridResponse } from '@api/services/archive/sample-filter/archive-sample-filter.dto'

class ArchiveSampleService {
    public async searchByLabel(
        laboratoryId: number,
        sampleLabel: string
    ): Promise<ArchiveEntitySearchResponseArchiveSampleGridResponseSearchSampleResponse[]> {
        const response =
            await axios.get<ArchiveEntitySearchResponseArchiveSampleGridResponseSearchSampleResponse[]>(
                `/archive/laboratories/${laboratoryId}/cases/samples/searchByLabel`,
                { params: { sampleLabel }})
        return response.data
    }

    public async updateLocation(
        laboratoryId: number,
        caseId: number,
        sampleId: number,
        archivingLocation: string
    ): Promise<ArchiveSampleGridResponse> {
        const response =
            await axios.patch<ArchiveSampleGridResponse>(
                `/archive/laboratories/${laboratoryId}/cases/${caseId}/samples/${sampleId}/updateLocation`,
                undefined,
                { params: { archivingLocation }})
        return response.data
    }

    public async unarchivePendingToBeUnarchived(
        laboratoryId: number,
        request: CommonEntityIdentifiersListRequestCommonSampleFamilyTreeRequest
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        const response =
            await axios.put<EntityIdentifierWithErrorResponse[]>(
                `/archive/laboratories/${laboratoryId}/cases/samples/unarchivePendingToBeUnarchived`,
                request)
        return response.data
    }

    public async takeOutFromLaboratory(
        laboratoryId: number,
        request: UnarchiveRequestCommonSampleFamilyTreeRequest[]
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        const response =
            await axios.put<EntityIdentifierWithErrorResponse[]>(
                `/archive/laboratories/${laboratoryId}/cases/samples/takeOutFromLaboratory`,
                request)
        return response.data
    }

    public async archive(
        laboratoryId: number,
        request: ArchiveRequestCommonSampleFamilyTreeRequest[]
    ): Promise<EntityIdentifierWithErrorResponse[]> {
        const response =
            await axios.put<EntityIdentifierWithErrorResponse[]>(
                `/archive/laboratories/${laboratoryId}/cases/samples/archive`,
                request)
        return response.data
    }
}
export const archiveSampleService = new ArchiveSampleService()
