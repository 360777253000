import { defineComponent, PropType, reactive, ref } from 'vue'
import { CardsSelectionToolbarClass } from '@/app/shared/components/cards/cards-selection-toolbar/cards-selection-toolbar.class'
import { Card } from '@/app/shared/components/cards/card/card.model'

export default defineComponent({
    name: 'CardsSelectionToolbar',
    props: {
        cards: { type: Array as PropType<Card[]>, required: true }
    },
    setup(props) {
        const _class = reactive(new CardsSelectionToolbarClass())

        /// DATA
        const visibleList = ref(false)

        return {
            /// DATA
            visibleList,
            /// METHODS
            deselectAll: () => _class.deselectAll(props.cards),
            deselectCard: (card: Card) => _class.deselectCard(props.cards, card),
            toggleList: () => (visibleList.value = !visibleList.value)
        }
    }
})
