import { i18n } from '@api/lib'
import moment from 'moment'

export type FilterDate = Date | string | number

export class Formatter {
    public static ago(value?: FilterDate): string {
        return value ? moment(value).fromNow() : ''
    }

    /**
     * Represents a given integer number in <em>base alphabet</em>.
     *
     * A number represented in <em>base alphabet</em> uses as digits the letters of the alphabet, from A to Z,
     * where 0 is represented as A, 25 as Z, 26 as AA, 27 as AB, and so on.
     */
    public static baseAlphabetFilter(value: number): string {
        let baseAlphabetNumber = ''
        if (value) {
            const base = 26
            const letterACharCode = 65

            let remainingBase10Number = value + 1
            do {
                remainingBase10Number--
                baseAlphabetNumber = String.fromCharCode((remainingBase10Number % base) + letterACharCode) + baseAlphabetNumber
                remainingBase10Number = Math.floor(remainingBase10Number / base)
            } while (remainingBase10Number > 0)
        }
        return baseAlphabetNumber
    }

    public static capitalize(value: string): string {
        if (value) {
            const result = value.toLowerCase()
            return result.charAt(0).toUpperCase() + result.slice(1)
        }
        return ''
    }

    public static date(value?: FilterDate): string {
        return value ? moment(value).format('L') : ''
    }

    public static datetime(value?: FilterDate): string {
        return value ? moment(value).format('L HH:mm') : ''
    }

    public static lowercase(value?: string): string {
        return value ? value.toLowerCase() : ''
    }

    public static number(value?: number): string {
        return value ? i18n.n(value) : '-'
    }

    public static uppercase(value?: string): string {
        return value ? value.toUpperCase() : ''
    }
}
