import { SessionStoreState } from '@/app/plugins/vuex/store/root/modules/session/session-store-state.model'
import { store } from '@api/lib'
import { OperationUtil } from '@/app/core/utils/operation-util'
import { PortionOperation } from '@api/operations/portion-operation'

export class PortionOperationGuard {
    public static canArchive(
        laboratoryId: SessionStoreState['labId'] = store.getters['session/labId']
    ): boolean {
        return OperationUtil.hasLabOperation(PortionOperation.ARCHIVE, laboratoryId)
    }
    public static canUnArchive(
        laboratoryId: SessionStoreState['labId'] = store.getters['session/labId']
    ): boolean {
        return OperationUtil.hasLabOperation(PortionOperation.UNARCHIVE_TO_CUT, laboratoryId) ||
            OperationUtil.hasLabOperation(PortionOperation.UNARCHIVE_TO_REMAKE, laboratoryId)
    }
    public static canTakeOutFromLaboratory(
        laboratoryId: SessionStoreState['labId'] = store.getters['session/labId']
    ): boolean {
        return OperationUtil.hasLabOperation(PortionOperation.TAKE_OUT_FROM_LABORATORY, laboratoryId)
    }
}
