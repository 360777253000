import { computed, defineComponent, PropType, reactive } from 'vue'
import CardsSelectionToolbar from '@/app/shared/components/cards/cards-selection-toolbar/cards-selection-toolbar.vue'
import ConfirmationSummary from '@/app/shared/components/confirmation-summary/confirmation-summary.vue'
import { Card } from '@/app/shared/components/cards/card/card.model'
import { ArchiveEntityResponse } from '@/app/views/modules/components/archive/datasources/archive-datasource-factory'
import { ArchiveEntityDatasource } from '@/app/views/modules/components/archive/datasources/entities/archive-entity.datasource'
import {
    ArchivePendingToBeUnArchivedSelectionToolbarEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/selection-toolbar/archive-pending-to-be-un-archived-selection-toolbar.model'
import {
    ArchivePendingToBeUnArchivedSelectionToolbarClass
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/selection-toolbar/archive-pending-to-be-un-archived-selection-toolbar.class'

export default defineComponent({
    name: 'ArchivePendingToBeUnArchivedSelectionToolbar',
    components: {
        CardsSelectionToolbar,
        ConfirmationSummary
    },
    props: {
        cards: { type: Array as PropType<Card<ArchiveEntityResponse>[]>, required: true },
        datasource: { type: Object as PropType<ArchiveEntityDatasource>, required: true }
    },
    emits: [...Object.values(ArchivePendingToBeUnArchivedSelectionToolbarEmitEvent)],
    setup(props, { emit }) {
        const _class = reactive(new ArchivePendingToBeUnArchivedSelectionToolbarClass(emit))
        return {
            /// COMPUTED
            elementsWithoutError: computed(() => _class.elementsWithoutError),
            visibleConfirmationSummary: computed(() => _class.visibleConfirmationSummary),
            /// METHODS
            takeOut: () => _class.takeOut(props.cards, props.datasource),
            confirm: () => _class.confirm()
        }
    }
})
