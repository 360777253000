import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6eca7d3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "empty-state ion-text-center ion-padding" }
const _hoisted_2 = { class: "empty-state__icon" }
const _hoisted_3 = {
  key: 0,
  class: "font-size--xxl primary-dark-color ion-margin"
}
const _hoisted_4 = {
  key: 1,
  class: "font-size--xxl primary-dark-color ion-margin"
}
const _hoisted_5 = {
  key: 2,
  class: "font-size--xl ion-padding-horizontal"
}
const _hoisted_6 = {
  key: 3,
  class: "font-size--xl ion-padding-horizontal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_fa_icon, { icon: ['far', 'bars'] })
    ]),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.title), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('anapathapp.label.noPendingElement')), 1)),
    (_ctx.subtitle)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.subtitle), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('anapathapp.label.selectAnotherElementToUpdate')), 1))
  ]))
}