import { EntityIdentifierWithErrorResponse } from '@/app/core/models/http.model'
import { CommonSampleFamilyTreeRequest } from '@api/services/common.dto'
import { axios } from '@vitro/anapath-frontend-library-api'

class WasteSampleService {
    public async discard(laboratoryId: number, request: CommonSampleFamilyTreeRequest[]): Promise<EntityIdentifierWithErrorResponse[]> {
        const response = await axios.put<EntityIdentifierWithErrorResponse[]>(`/waste/laboratories/${laboratoryId}/samples/discard`, request)
        return response.data
    }

    public async undoDiscard(laboratoryId: number, request: CommonSampleFamilyTreeRequest[]): Promise<EntityIdentifierWithErrorResponse[]> {
        const response = await axios.put<EntityIdentifierWithErrorResponse[]>(`/waste/laboratories/${laboratoryId}/samples/undiscard`, request)
        return response.data
    }
}
export const wasteSampleService = new WasteSampleService()
