import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42e4a3ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_chip = _resolveComponent("filter-chip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.pendingUnArchivingReason)
      ? (_openBlock(), _createBlock(_component_filter_chip, {
          key: 0,
          "filter-icon": "comment-dots",
          "filter-label": _ctx.pendingUnArchivingReason.name,
          onRemoved: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearPendingUnArchivingReasonFilter()))
        }, null, 8, ["filter-label"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locations, (location, index) => {
      return (_openBlock(), _createBlock(_component_filter_chip, {
        key: index,
        "filter-icon": "location",
        "filter-label": location,
        onRemoved: ($event: any) => (_ctx.removeLocationFilter(location))
      }, null, 8, ["filter-label", "onRemoved"]))
    }), 128))
  ]))
}