import {
    Overlay,
    OverlayEvent,
    OverlayLib,
    OverlayOptions
} from '@api/lib/overlay'
import { actionSheetController as actionSheetIonicController } from '@ionic/vue'
import { Optional } from '@/app/core/types/app-types'

export interface ActionSheetButton {
    text: string
    role?: 'cancel' | 'destructive' | 'selected' | string
    icon?: string
    cssClass?: string | string[]
    handler?: () => boolean | void | Promise<boolean | void>
}

export interface ActionSheetOptions extends OverlayOptions {
    header?: string
    subheader?: string
    buttons: (ActionSheetButton | string)[]
}

class ActionSheet implements Overlay<ActionSheetOptions> {
    private _element: HTMLIonActionSheetElement
    public readonly options: ActionSheetOptions

    constructor(options: ActionSheetOptions, element: HTMLIonActionSheetElement) {
        this.options = options
        this._element = element
    }

    dismiss<T>(data?: T, role?: string): Promise<boolean> {
        return this._element.dismiss(data, role)
    }

    async onDidDismiss<T>(): Promise<OverlayEvent<T>> {
        const event = await this._element.onDidDismiss()
        return {
            data: event.data,
            role: event.role
        }
    }

    async onWillDismiss<T>(): Promise<OverlayEvent<T>> {
        const event = await this._element.onWillDismiss()
        return {
            data: event.data,
            role: event.role
        }
    }

    present(): Promise<void> {
        return this._element.present()
    }
}

class ActionSheetController implements OverlayLib<ActionSheetOptions, Overlay<ActionSheetOptions>> {
    private _overlay: Optional<ActionSheet>

    async create(options: ActionSheetOptions): Promise<Overlay<ActionSheetOptions>> {
        const actionSheet = await actionSheetIonicController.create(options)
        this._overlay = new ActionSheet(options, actionSheet)
        return this._overlay
    }

    async dismiss(): Promise<boolean> {
        return await this._overlay?.dismiss() ?? false
    }
}
export const actionSheetController = new ActionSheetController()
