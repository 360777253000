import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6143de0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "waste-banner"
}
const _hoisted_2 = { class: "waste-banner__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_button = _resolveComponent("loading-button")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('anapathapp.message.pendingElementMustBeDiscarded')), 1),
        _createVNode(_component_ion_row, { class: "ion-text-right" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, null, {
              default: _withCtx(() => [
                (_ctx.hasArchiveAccess)
                  ? (_openBlock(), _createBlock(_component_loading_button, {
                      key: 0,
                      strong: "",
                      size: "small",
                      color: "light",
                      fill: "clear",
                      promise: _ctx.goToTakeOutByDisposal
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.goToArchive')), 1)
                      ]),
                      _: 1
                    }, 8, ["promise"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_ion_button, {
                  strong: "",
                  size: "small",
                  color: "light",
                  fill: "clear",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hide()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.understood')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}