import { BaseCardDatasource } from '@/app/shared/resources/datasources/base-card.datasource'
import { CommonParams, Page } from '@/app/shared/models/common.model'
import { Card } from '@/app/shared/components/cards/card/card.model'
import { GridUtil } from '@/app/core/utils/grid-util'
import { InfiniteScrollCustomEvent } from '@/app/plugins/ionic/ionic-plugin'

export abstract class InfiniteCardDatasource<T, S> extends BaseCardDatasource<T, S, Page<T>> {
    protected _totalElements: number
    protected _currentPage: number
    protected _isLastPage: boolean
    protected _infiniteScrollDisabled: boolean
    protected _silentLoad: boolean

    public get infiniteScrollDisabled(): boolean {
        return this._infiniteScrollDisabled
    }
    public get loading(): boolean {
        if (this._silentLoad) {
            return false
        }
        return this._loading
    }

    protected constructor() {
        super()
        this._totalElements = 0
        this._currentPage = 0
        this._isLastPage = false
        this._infiniteScrollDisabled = false
        this._silentLoad = false
    }

    protected _resetData(): void {
        super._resetData()
        this._totalElements = 0
        this._currentPage = 0
    }

    protected getFetchDataParams(): CommonParams {
        return {
            page: this._currentPage,
            sort: this._sortModel
                ? GridUtil.getSortValue([this._sortModel])
                : undefined
        }
    }

    protected abstract _createCard(element: T): Card<T>
    protected _onLoaded(response: Page<T>): void {
        this._totalElements = response.totalElements
        this._currentPage = response.number
        if (!response.last) {
            this._currentPage++
        } else {
            this._infiniteScrollDisabled = true
        }

        this._cards =
            this._cards.concat(
                response.content.map(element => this._createCard(element)))
    }

    protected async _silentLoadData(): Promise<void> {
        this._silentLoad = true
        await this.loadData()
        this._silentLoad = false
    }
    public async loadInfiniteScrollData(event: InfiniteScrollCustomEvent): Promise<void> {
        if (this._sortModel) {
            await super._sort(
                this._sortModel,
                () => this._silentLoadData())
        } else {
            await this._silentLoadData()
        }
        await event.target.complete()
    }
}
