import { CommonFilterModel, CommonParams, Page } from '@/app/shared/models/common.model'
import {
    WasteSampleFilterPaginatorType,
    WasteSampleGridResponse,
    WasteSampleSummaryResponse
} from '@api/services/waste/sample-filter/waste-sample-filter.dto'
import { axios } from '@vitro/anapath-frontend-library-api'

class WasteSampleFilterService {
    public async summary(laboratoryId: number): Promise<WasteSampleSummaryResponse> {
        const response = await axios.get<WasteSampleSummaryResponse>(`/waste/laboratories/${laboratoryId}/summary`)
        return response.data
    }

    public async paginator(
        laboratoryId: number,
        url: WasteSampleFilterPaginatorType,
        filterModel?: CommonFilterModel,
        params?: CommonParams
    ): Promise<Page<WasteSampleGridResponse>> {
        const response =
            await axios.put<Page<WasteSampleGridResponse>>(
                `/waste/laboratories/${laboratoryId}/cases/samples/${url}`,
                { ...filterModel },
                { params })
        return response.data
    }
}
export const wasteSampleFilterService = new WasteSampleFilterService()
