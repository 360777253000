import {
    Overlay,
    OverlayEvent,
    OverlayLib,
    OverlayOptions
} from '@api/lib/overlay'
import { loadingController as loadingIonicController } from '@ionic/vue'
import { Optional, StrictNullable } from '@/app/core/types/app-types'

export interface LoadingOptions extends OverlayOptions {
    showBackdrop?: boolean
    duration?: number
}

class Loading implements Overlay<LoadingOptions> {
    private _element: HTMLIonLoadingElement
    private _previousElement: StrictNullable<HTMLElement>
    public readonly options: LoadingOptions

    constructor(options: LoadingOptions, element: HTMLIonLoadingElement) {
        this.options = options
        this._element = element
        this._previousElement = null
    }

    present(): Promise<void> {
        this._previousElement = document.activeElement as StrictNullable<HTMLElement>
        return this._element.present()
    }

    async dismiss<T>(data?: T, role?: string): Promise<boolean> {
        const dismissed = await this._element.dismiss(data, role)
        this._previousElement?.focus()
        return dismissed
    }

    async onDidDismiss<T>(): Promise<OverlayEvent<T>> {
        const event = await this._element.onDidDismiss()
        return {
            data: event.data,
            role: event.role
        }
    }

    async onWillDismiss<T>(): Promise<OverlayEvent<T>> {
        const event = await this._element.onWillDismiss()
        return {
            data: event.data,
            role: event.role
        }
    }
}

class LoadingController implements OverlayLib<LoadingOptions, Overlay<LoadingOptions>> {
    private _overlay: Optional<Loading>

    async create(options: LoadingOptions): Promise<Overlay<LoadingOptions>> {
        const loading =
            await loadingIonicController.create({
                ...options,
                spinner: 'circles'
            })
        this._overlay = new Loading(options, loading)
        return this._overlay
    }

    async dismiss(): Promise<boolean> {
        return await this._overlay?.dismiss() ?? false
    }
}
export const loadingController = new LoadingController()
