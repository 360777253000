import { SessionStoreState } from '@/app/plugins/vuex/store/root/modules/session/session-store-state.model'
import { store } from '@api/lib'
import { StoreUtil } from '@/app/core/utils/lib/store-util'

export class OperationUtil {
    public static hasLabOperation(
        operationCode: string,
        laboratoryId: SessionStoreState['labId'] = store.getters['session/labId']
    ): boolean {
        const lab = StoreUtil.laboratories.find(laboratory => laboratory.labId === laboratoryId)
        return !!lab?.operationCodes.includes(operationCode)
    }
    public static hasLabOperationStartingWith(
        operationPrefix: string,
        laboratoryId: SessionStoreState['labId'] = store.getters['session/labId']
    ): boolean {
        const lab = StoreUtil.laboratories.find(laboratory => laboratory.labId === laboratoryId)
        return !!lab?.operationCodes.some(operationCode => operationCode.startsWith(operationPrefix))
    }
}
