import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5461b8e3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-recover-password-email-sent" }
const _hoisted_2 = { class: "login-module__title" }
const _hoisted_3 = { class: "font-size--lg" }
const _hoisted_4 = { class: "login-module__subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_loading_button = _resolveComponent("loading-button")!
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_row, { class: "ion-margin-vertical ion-justify-content-center" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_col, {
          class: "ion-text-center ion-margin-top",
          size: "12"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('anapathapp.label.requestMade')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_col, {
          class: "ion-text-center ion-margin-bottom",
          size: "8"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('anapathapp.label.checkEmail')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_col, {
          class: "ion-text-center ion-margin-bottom",
          size: "8"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('anapathapp.label.emailNotReceived')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_col, {
          class: "ion-padding-top",
          size: "10"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_loading_button, {
              class: "login-module__submit",
              expand: "block",
              shape: "round",
              mode: "md",
              strong: "",
              promise: _ctx.showRecoverPassword
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.sendAgain')), 1)
              ]),
              _: 1
            }, 8, ["promise"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_col, { size: "10" }, {
          default: _withCtx(() => [
            _createVNode(_component_loading_button, {
              mode: "md",
              fill: "clear",
              color: "light",
              promise: _ctx.showLogin
            }, {
              default: _withCtx(() => [
                _createVNode(_component_fa_icon, {
                  icon: ['fal', 'arrow-left'],
                  class: "ion-margin-end"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('anapathapp.action.back')), 1)
              ]),
              _: 1
            }, 8, ["promise"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}