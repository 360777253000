import { computed, defineComponent, onMounted, PropType, reactive } from 'vue'
import ArchiveSectionSelector
    from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.vue'
import ArchivePendingToBeArchivedActions
    from '@/app/views/modules/components/archive/components/pending-to-be-archived/actions/archive-pending-to-be-archived-actions.vue'
import ArchivePendingToBeArchivedLocationSelector
    from '@/app/views/modules/components/archive/components/pending-to-be-archived/location-selector/archive-pending-to-be-archived-location-selector.vue'
import ArchivePendingToBeArchivedSelectionToolbar
    from '@/app/views/modules/components/archive/components/pending-to-be-archived/selection-toolbar/archive-pending-to-be-archived-selection-toolbar.vue'
import ArchivePendingToBeUnArchivedActions
    from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/actions/archive-pending-to-be-un-archived-actions.vue'
import ArchivePendingToBeUnArchivedFilterAction
    from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/filter/action/archive-pending-to-be-un-archived-filter-action.vue'
import ArchivePendingToBeUnArchivedFilterInfo
    from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/filter/info/archive-pending-to-be-un-archived-filter-info.vue'
import ArchivePendingToBeUnArchivedSelectionToolbar
    from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/selection-toolbar/archive-pending-to-be-un-archived-selection-toolbar.vue'
import Card from '@/app/shared/components/cards/card/card.vue'
import CardsEmptyState from '@/app/shared/components/cards/cards-empty-state/cards-empty-state.vue'
import CardsListHeader from '@/app/shared/components/cards/cards-list-header/cards-list-header.vue'
import CardsListSort from '@/app/shared/components/cards/cards-list-sort/cards-list-sort.vue'
import CardsSkeleton from '@/app/shared/components/cards/cards-skeleton/cards-skeleton.vue'
import ConfirmationSummary from '@/app/shared/components/confirmation-summary/confirmation-summary.vue'
import EntitySelector from '@/app/shared/components/selector/entity/entity-selector.vue'
import Searcher from '@/app/shared/components/searcher/searcher.vue'
import { CommonFilterModel, CommonSortModelResource } from '@/app/shared/models/common.model'
import { SearchInformation } from '@/app/shared/components/searcher/searcher-model'
import { ArchiveModuleClass } from '@/app/views/modules/components/archive/archive-module.class'
import { EntitySelectorType } from '@/app/shared/components/selector/entity/entity-selector.model'
import {
    ArchiveModuleSectionType
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'
import { ArchiveModuleParams } from '@/app/views/modules/components/archive/archive-module.model'
import { InfiniteScrollCustomEvent, RefresherCustomEvent } from '@/app/plugins/ionic/ionic-plugin'

export default defineComponent({
    name: 'ArchiveModule',
    components: {
        ArchiveSectionSelector,
        ArchivePendingToBeArchivedActions,
        ArchivePendingToBeArchivedLocationSelector,
        ArchivePendingToBeArchivedSelectionToolbar,
        ArchivePendingToBeUnArchivedActions,
        ArchivePendingToBeUnArchivedFilterAction,
        ArchivePendingToBeUnArchivedFilterInfo,
        ArchivePendingToBeUnArchivedSelectionToolbar,
        Card,
        CardsEmptyState,
        CardsListHeader,
        CardsListSort,
        CardsSkeleton,
        ConfirmationSummary,
        EntitySelector,
        Searcher
    },
    props: {
        initialEntityType: { type: String as PropType<EntitySelectorType>, default: EntitySelectorType.SAMPLES },
        initialSectionType: { type: String as PropType<ArchiveModuleSectionType>, default: ArchiveModuleSectionType.PENDING_TO_BE_ARCHIVED }
    },
    setup(props) {
        const _getParams =
            (): ArchiveModuleParams => ({
                entityType: props.initialEntityType,
                sectionType: props.initialSectionType
            })
        const _class = reactive(new ArchiveModuleClass(_getParams()))

        /// HOOKS
        onMounted(() => _class.datasource?.loadData())

        return {
            /// COMPUTED
            datasource: computed(() => _class.datasource),
            entityType: computed(() => _class.entityType),
            sectionType: computed(() => _class.sectionType),
            isEntityTypeSample: computed(() => _class.isEntityTypeSample),
            isEntityTypePortion: computed(() => _class.isEntityTypePortion),
            isEntityTypeSubportion: computed(() => _class.isEntityTypeSubportion),
            isPendingToBeArchived: computed(() => _class.isPendingToBeArchived),
            isPendingToBeUnArchived: computed(() => _class.isPendingToBeUnArchived),
            canSelectCards: computed(() => _class.canSelectCards),
            canTakeIn: computed(() => _class.canTakeIn),
            canTakeOut: computed(() => _class.canTakeOut),
            colDefs: computed(() => _class.datasource?.colDefs),
            totalElements: computed(() => _class.datasource?.totalElements),
            cards: computed(() => _class.datasource?.cards),
            selectedCards: computed(() => _class.datasource?.selectedCards),
            sortModel: computed(() => _class.datasource?.sortModel),
            loading: computed(() => _class.datasource?.loading),
            infiniteScrollDisabled: computed(() => _class.datasource?.infiniteScrollDisabled),
            listHeader: computed(() => _class.listHeader),
            /// METHODS
            changeEntity: (entity: EntitySelectorType) => _class.changeEntity(entity),
            changeSection: (archiveSection: ArchiveModuleSectionType) => _class.changeSection(archiveSection),
            loadInfiniteScrollData: (event: InfiniteScrollCustomEvent) => _class.datasource?.loadInfiniteScrollData(event),
            refreshData: (event: RefresherCustomEvent) => _class.datasource?.refreshData(event),
            reloadData: () => _class.datasource?.reloadData(),
            onFiltered: (filterModel: CommonFilterModel) => _class.datasource?.onFiltered(filterModel),
            onSorted: (sortModel: CommonSortModelResource) => _class.datasource?.onSorted(sortModel),
            onSampleSearch: (searchInformation: SearchInformation) => _class.onSampleSearch(searchInformation),
            onPortionSearch: (searchInformation: SearchInformation) => _class.onPortionSearch(searchInformation),
            onSubportionSearch: (searchInformation: SearchInformation) => _class.onSubportionSearch(searchInformation)
        }
    }
})
