import { SessionStoreState } from '@/app/plugins/vuex/store/root/modules/session/session-store-state.model'
import { store } from '@api/lib'
import { OperationUtil } from '@/app/core/utils/operation-util'
import { ModuleOperationAccess } from '@api/operations/module-operation'

export class ModuleOperationAccessGuard {
    public static canAccessArchive(
        laboratoryId: SessionStoreState['labId'] = store.getters['session/labId']
    ): boolean {
        return OperationUtil.hasLabOperation(ModuleOperationAccess.ARCHIVE, laboratoryId)
    }
    public static canAccessWaste(
        laboratoryId: SessionStoreState['labId'] = store.getters['session/labId']
    ): boolean {
        return OperationUtil.hasLabOperation(ModuleOperationAccess.WASTE, laboratoryId)
    }
}
