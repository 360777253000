import { LocaleMessage } from '@intlify/core-base'
import { axios } from '@vitro/anapath-frontend-library-api'

class I18nService {
    public async getTranslations(languageId: number): Promise<LocaleMessage> {
        const response = await axios.get<LocaleMessage>(`/i18n/labels/${languageId}`)
        return response.data
    }
}
export const i18nService = new I18nService()
