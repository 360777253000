import { computed, defineComponent, PropType, reactive } from 'vue'
import {
    ArchiveModuleSectionType,
    ArchiveSectionSelectorEmitEvent
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'
import {
    ArchiveSectionSelectorClass
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.class'

export default defineComponent({
    name: 'ArchiveSectionSelector',
    props: {
        sectionType: {
            type: String as PropType<ArchiveModuleSectionType>,
            default: ArchiveModuleSectionType.PENDING_TO_BE_ARCHIVED
        }
    },
    emits: [...Object.values(ArchiveSectionSelectorEmitEvent)],
    setup(props, { emit }) {
        const _class = reactive(new ArchiveSectionSelectorClass(emit))
        return {
            /// COMPUTED
            sections: computed(() => _class.sectionTypes),
            selectedSection: computed(() => _class.getSelectedSection(props.sectionType)),
            /// METHODS
            changeSection: (archiveSectionType: ArchiveModuleSectionType) => _class.changeSection(archiveSectionType)
        }
    }
})
