import { computed, defineComponent, PropType, reactive } from 'vue'
import ArchiveAddOperation from '@/app/views/modules/components/archive/components/shared/add-operation/archive-add-operation.vue'
import ConfirmationSummary from '@/app/shared/components/confirmation-summary/confirmation-summary.vue'
import TakeOutConflicts
    from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/take-out-conflicts/take-out-conflicts.vue'
import TakeOutReasonSelector
    from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/take-out-reason-selector/take-out-reason-selector.vue'
import TakeOutReturnSelector
    from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/take-out-return-selector/take-out-return-selector.vue'
import { ArchiveEntityResponse } from '@/app/views/modules/components/archive/datasources/archive-datasource-factory'
import { ArchiveEntityDatasource } from '@/app/views/modules/components/archive/datasources/entities/archive-entity.datasource'
import {
    ArchivePendingToBeUnArchivedAddOperationEmitEvent
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/add-operation/archive-pending-to-be-un-archived-add-operation.model'
import {
    ArchivePendingToBeUnArchivedAddOperationClass
} from '@/app/views/modules/components/archive/components/pending-to-be-un-archived/add-operation/archive-pending-to-be-un-archived-add-operation.class'
import { Card } from '@/app/shared/components/cards/card/card.model'
import { CommonUnarchivingReasonResponse } from '@api/services/archive/common/archive-common.dto'
import { EntitySelectorType } from '@/app/shared/components/selector/entity/entity-selector.model'
import {
    ArchiveModuleSectionType
} from '@/app/views/modules/components/archive/components/shared/section-selector/archive-section-selector.model'

export default defineComponent({
    name: 'ArchivePendingToBeArchivedAddOperation',
    components: {
        ArchiveAddOperation,
        ConfirmationSummary,
        TakeOutConflicts,
        TakeOutReasonSelector,
        TakeOutReturnSelector
    },
    props: {
        title: { type: String, required: true },
        entityType: { type: String as PropType<EntitySelectorType>, required: true },
        sectionType: { type: String as PropType<ArchiveModuleSectionType>, required: true },
        datasource: { type: Object as PropType<ArchiveEntityDatasource>, required: true }
    },
    emits: [...Object.values(ArchivePendingToBeUnArchivedAddOperationEmitEvent)],
    setup(props, { emit }) {
        const _class = reactive(new ArchivePendingToBeUnArchivedAddOperationClass(emit))
        return {
            /// COMPUTED
            reason: computed(() => _class.reason),
            shouldBeReturned: computed(() => _class.shouldBeReturned),
            elementsWithoutError: computed(() => _class.elementsWithoutError),
            visibleConfirmationSummary: computed(() => _class.visibleConfirmationSummary),
            hasCards: computed(() => _class.hasCards),
            disabledTakeOut: computed(() => _class.disabledTakeOut),
            elementsPendingToBeUnarchived: computed(() => _class.elementsPendingToBeUnarchived),
            visibleConflicts: computed(() => _class.visibleConflicts),
            /// METHODS
            goBack: () => _class.goBack(),
            hideConflicts: () => _class.hideConflicts(),
            onChange: (cards: Card<ArchiveEntityResponse>[]) => _class.onChange(cards),
            changeReason: (reason: CommonUnarchivingReasonResponse) => _class.changeReason(reason),
            changeShouldBeReturned: (shouldBeReturned: boolean) => _class.changeShouldBeReturned(shouldBeReturned),
            takeOutNow: () => _class.takeOutNow(props.datasource),
            takeOutFromLaboratory: () => _class.takeOutFromLaboratory(props.datasource),
            confirm: () => _class.confirm()
        }
    }
})
