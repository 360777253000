export class TextNormalization {
    public static removeDiacritics(text: string): string {
        return text.replace(/ñ/g, 'nz') // replace ñ with nz so that after removing diacritics when sorting it comes after n
            .normalize('NFD') // decompose characters combined with diacritics into base + diacritic characters
            .replace(/[\u0300-\u036f]/g, '') // remove diacritic characters
            .replace(/\s+/g, ' ') // replace multiple spaces with just 1 space character
            .toLowerCase()
    }
    public static normalizedContains(query: string, targetText: string): boolean {
        return query.split(' ').reduce((accumulator, subquery) => {
            const normalizedQuery = this.removeDiacritics(subquery)
            const normalizedTargetText = this.removeDiacritics(targetText)
            return accumulator && normalizedTargetText.indexOf(normalizedQuery) >= 0
        }, true as boolean)
    }

    public static commonSetFilterTextFormatter(value: unknown, toString: (value: unknown) => string): string {
        const text = typeof value === 'string' ? value : toString(value)
        return TextNormalization.removeDiacritics(text).toUpperCase()
    }
}
