import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, { mode: "md" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet, { key: _ctx.routePath }),
          _createVNode(_component_ion_tab_bar, {
            slot: "bottom",
            class: "tabs"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moduleTabs, (moduleTab) => {
                return (_openBlock(), _createBlock(_component_ion_tab_button, {
                  key: moduleTab.moduleCode,
                  tab: moduleTab.route.path,
                  href: `/modules/${moduleTab.route.path}`,
                  class: "tabs__button"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_fa_icon, {
                      icon: moduleTab.icon,
                      class: "tabs__icon"
                    }, null, 8, ["icon"]),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(moduleTab.getLabel()), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["tab", "href"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}