import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c12a2f22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-module__label" }
const _hoisted_2 = { class: "danger-color" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_profile_user_info = _resolveComponent("profile-user-info")!
  const _component_loading_button = _resolveComponent("loading-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_language_selector = _resolveComponent("language-selector")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_module_selector = _resolveComponent("module-selector")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { class: "profile-module" }, {
        default: _withCtx(() => [
          _createVNode(_component_profile_user_info),
          (_ctx.canChangePassword)
            ? (_openBlock(), _createBlock(_component_ion_grid, {
                key: 0,
                class: "ion-margin-bottom"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, { class: "flex-all-center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_loading_button, {
                            fill: "outline",
                            mode: "md",
                            promise: _ctx.showChangePasswordModal
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.changePassword')), 1)
                            ]),
                            _: 1
                          }, 8, ["promise"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_list, { lines: "full" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "profile-module__label ion-padding" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.label.language')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_language_selector, {
                    class: "profile-module__language ion-padding",
                    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveChanges()))
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "profile-module__label ion-padding" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.label.initialModule')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_module_selector, { key: _ctx.locale })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                mode: "md",
                button: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logoutAlert()))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('anapathapp.action.logout')), 1)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}